<template>
    <div v-for="(alert, index) in visibleAlerts"
        :key="index"
        :class="`alert alert-${alert.variant} d-flex align-items-center`" role="alert">
        <FontAwesomeIcon v-if="alert.icon" :icon="alert.icon" class="me-2 font-lg" />
        <span v-html="alert.message"></span>
    </div>
</template>

<script setup>
    import { computed } from "vue";

    const props = defineProps({
        alerts: { type: Array, default: () => [] }
    });

    const visibleAlerts = computed(() => _.filter(props.alerts, a => !_.isEmpty(a.message)));
</script>