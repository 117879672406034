<template>
    <li class="nav-item rq-nav-action">
        <b-tooltip
            v-if="tooltipText"
            :target="componentId"
            placement="top"
            triggers="hover"
            container="body"
            boundary="window"
            v-model:show="tooltipVisible">
            {{tooltipText}}
        </b-tooltip>
        <span :id="componentId">
            <div v-if="isDropdown"
                class="btn-group">
                <button
                    :id="action.id"
                    :class="{
                        'btn dropdown-toggle': true,
                        'btn-link': !iconOnly,
                        'btn-icon dropdown-toggle-no-caret': iconOnly
                    }"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    :disabled="isDisabled">
                    <FontAwesomeIcon
                        v-if="action.icon"
                        :icon="action.icon"
                    />
                    <span v-if="!iconOnly && action.text"
                        :class="{
                            'action-text':true,
                            'sr-only':iconOnly
                        }">{{action.text}}</span>
                </button>
                <ul :class="{
                    'dropdown-menu': true,
                    'dropdown-menu-end': right
                }">
                    <li
                        v-for="childAction in childActions"
                        :key="childAction.key">
                        <button
                            :id="childAction.id"
                            :automation_id="childAction.automation_id"
                            type="button"
                            class="dropdown-item"
                            :disabled="getActionDisabled(childAction)"
                            @click="onActionClick(childAction)" >
                            <span>{{childAction.text}}</span>
                            <span v-if="!canExecuteAction(childAction)" class="text-muted font-italic">(Access Restricted)</span>
                        </button>
                    </li>
                </ul>
            </div>

            <rq-icon-button v-else-if="iconOnly"
                :id="action.id"
                :automation_id="automationId"
                :icon="action.icon"
                :disabled="isDisabled"
                @click="onActionClick()"
            />

            <b-btn v-else
                :id="action.id"
                :automation_id="automationId"
                variant="link"
                :disabled="isDisabled"
                @click="onActionClick()">
                <span class="action-text">{{action.text}}</span>
            </b-btn>
        </span>
    </li>
</template>

<script>
    export default {
        props: {
            automation_id: { type: String , default: null },
            action: { type: Object, default: () => ({}) },
            iconOnly: { type: Boolean, default: false },
            selectedItems: { type: Array, default: () => [] },
            readOnly: { type: Boolean, default: false },
            right: { type: Boolean, default: false },
            checkActionPermission: { type: Function, default: () => () => true }
        },

        data() {
            return {
                tooltipVisible: false
            };
        },

        computed: {
            componentId() { return _.uniqueId("rq-grid-nav-action-"); },
            automationId() { return _.isNil(this.automation_id) ? _.get(this, "action.automation_id", this.componentId) : this.automation_id; },
            isDropdown() { return !_.isEmpty(this.action.children); },
            canExecute() { return this.canExecuteAction(); },
            numSelected() { return _.parseNumber(this.selectedItems.length, 0); },
            childActions() { return _.isEmpty(this.action.children) ? [] : _.filter(this.action.children, a => a.isVisible(this.selectedItems)); },
            isDisabled() {
                if(this.isDropdown && _.every(this.childActions, this.getActionDisabled)) return true;
                return this.getActionDisabled();
            },
            tooltipText() { return this.isDisabled ? this.getDisabledTooltip() : this.getActionTooltip(); }
        },

        created() {
            this.bindMousetrap();
        },

        beforeUnmount() {
            this.unbindMousetrap();
        },

        methods: {
            onActionClick(action) {
                let targetAction = action || this.action;
                this.tooltipVisible = false;
                this.$emit("action-click", targetAction);
            },
            canExecuteAction(action) {
                let targetAction = action || this.action;
                let canExecuteReadOnly = !this.readOnly || !_.parseBool(targetAction.disableWhenReadOnly);
                return this.checkActionPermission(targetAction) && canExecuteReadOnly;
            },
            getActionDisabled(action=null) {
                let targetAction = action || this.action;
                return !this.canExecuteAction(targetAction)
                    || (targetAction.requireMultiSelection
                        && this.numSelected <= 1)
                    || (targetAction.requireSelection
                        && (this.numSelected === 0
                            || (this.numSelected > 1
                                && !targetAction.allowMultiSelection)))
                    || targetAction.isDisabled(this.selectedItems);
            },
            bindMousetrap() {
                const self = this;
                let validActions = _.isEmpty(self.action.shortcutKeys) ? [] : [self.action];
                if(!_.isEmpty(self.action.children)) {
                    validActions.push(..._.filter(self.action.children, a => !_.isEmpty(a.shortcutKeys)));
                }
                if(_.isEmpty(validActions)) return;
                _.forEach(validActions, a => Mousetrap.bind(a.shortcutKeys, function(e) {
                    if(self.getActionDisabled(a)) return;
                    self.onActionClick(a);
                    e.preventDefault();
                }));
                self.boundShortcuts = _.map(validActions, a => a.shortcutKeys);
            },
            unbindMousetrap() {
                if(_.isEmpty(this.boundShortcuts)) return;
                _.forEach(this.boundShortcuts, keys => Mousetrap.unbind(keys));
            },
            getDisabledTooltip(action) {
                let targetAction = action || this.action;
                if(!this.canExecuteAction(targetAction))
                    return "Access Restricted";
                if(targetAction.requireMultiSelection && this.numSelected <= 1)
                    return "Multiple Selection Required";
                if(targetAction.requireSelection && (this.numSelected === 0 || (this.numSelected > 1 && !targetAction.allowMultiSelection)))
                    return "Single Selection Required";
                if(targetAction.disabledTooltip)
                    return targetAction.disabledTooltip;
                return "Access Restricted";
            },
            getActionTooltip(action) {
                let targetAction = action || this.action;
                return targetAction.getTooltip(this.selectedItems);
            }
        }
    };
</script>
