<template>
    <rq-input-number
        :default-value="defaultValue"
        format-type="money"
        :prepend-icon="prependIcon"
        :decimals="decimals"
        v-model="boundValue"
        input-group
        no-prefix
    />
</template>

<script>
    export default {
        props: {
            modelValue: { default: null },
            defaultValue: { default: 0 },
            decimals: { type: Number, default: 2 },
            prependIcon: { type: String, default: "fas fa-dollar-sign" }
        },
        computed: {
            boundValue: {
                get() { return this.modelValue; },
                set(val) { this.$emit("update:modelValue", val); }
            }
        }
    }
</script>