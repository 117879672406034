import { ref, unref, computed, watchEffect } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

export function usePromptInputProps(defaultId) {
    return {
        inputId: { type: String, default: defaultId },
        value: [Number,String,Object],
        label: String,
        isRequired: Boolean,
        requiredMessage: String,
        validators: Object,
        alerts: { type: Array, default: () => [] },
        disabled: { type: Boolean, default: false }
    };
}

export function usePromptInput({ inputId, isRequired, requiredMessage, validators, value }) {
    const inputValue = ref(value);
    const showBanner = ref(false);
    const _isRequired = ref(false);
    const _requiredMessage = ref("");
    const _validators = ref([]);

    watchEffect(() => {
        _isRequired.value = unref(isRequired);
        _requiredMessage.value = unref(requiredMessage);
        _validators.value = unref(validators);
    })

    const rules = computed(() => {
        let result = {};
        result.required = requiredIf(() => _isRequired.value);
        _.assign(result, _.mapValues(_validators.value, "validator"));
        return { inputValue: result };
    });

    const v$ = useVuelidate(rules, { inputValue });

    const validationMessages = computed(() => {
        let messages = {};
        let v = unref(v$);
        if(_isRequired.value) {
            let req = _.isEmpty(_requiredMessage.value) ? "This field is required" : _requiredMessage.value;
            messages[req] = v?.inputValue?.required.$invalid;
        }
        if(!_.isEmpty(_validators.value)) {
            _.each(_validators.value, (obj, key) => {
                messages[obj.message] = _.getBool(v?.inputValue, `${key}.$invalid`) && (!_isRequired.value || !v?.inputValue?.required?.$invalid);
            });
        }
        return messages;
    });

    const hasError = computed(() => v$.value.$error);

    function isValid() {
        v$.value.$touch();
        showBanner.value = true;
        return !v$.value.$error;
    }

    return {
        idAttrs: {
            id: inputId,
            automation_id: inputId
        },
        inputValue,
        hasError,
        showBanner,
        validationMessages,
        isValid
    };
}

// export const mixin = {
//     props: {
//         value: [Number,String,Object],
//         label: String,
//         isRequired: Boolean,
//         requiredMessage: String,
//         validators: Object
//     },
//     setup() {

//     },
//     data() {
//         return {
//             inputValue: null,
//             showBanner: false
//         };
//     },
//     computed: {
//         validationMessages() {
//         }
//     },
//     validations() {
//         const self = this;
//         let inputValue = {};
//         if(self.isRequired) inputValue.required = required;
//         _.assign(inputValue, _.mapValues(self.validators, "validator"));
//         return { inputValue };
//     },
//     mounted() {
//         this.inputValue = this.value;
//     },
//     methods: {
//         isValid() {
//             this.$v.$touch();
//             this.showBanner = true;
//             return !this.$v.$error;
//         }
//     }
// };
