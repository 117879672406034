<template>
    <div :class="{
        'rq-resize-handle':true,
        'rq-resize-handle-y':resizeY,
        'rq-resize-handle-x':resizeX
        }"
        @dragstart="onDragStart"
        @dragend="onDragEnd">
        <div class="rq-resize-handle-grip">
            <FontAwesomeIcon :icon="gripIcon" />
        </div>
    </div>
</template>

<script setup>
    import { computed } from "vue";
    const props = defineProps({
        modelValue: { type: Number, default: 0 },
        resizeX: { type: Boolean, default: false },
        resizeY: { type: Boolean, default: false }
    });
    const emit = defineEmits(["update:modelValue"]);
    const boundValue = computed({
        get() { return props.modelValue; },
        set(val) { emit("update:modelValue", val); }
    });
    const gripIcon = computed(() => _.evalCssObject({ "fas":true, "fa-grip-lines":props.resizeY, "fa-grip-lines-vertical":props.resizeX }));

    function onDragStart() { }
    function onDragEnd() { }
</script>
