<template>
    <div :class="classAttr">
        <span v-if="htmlContent" :class="messageClassAttr" v-html="htmlContent"></span>
        <span v-else :class="messageClassAttr">
            <slot name="message-content">
                <FontAwesomeIcon v-if="hasIcon" :icon="icon" />
                {{text}}
            </slot>
        </span>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props:{
            text: { type: String, default: "No Data" },
            htmlContent: { type: String, default: null },
            size: { type: String, default: null },
            icon: { type: String, default: null },
            variant: { type: String, default: null },
        },
        data() {
            return {
                acceptedSizes: ["sm","md","lg"]
            };
        },
        computed:{
            hasIcon() { return (/(fa)(s|l|r|d)\s(fa-)/).test(this.icon); },
            classAttr() {
                let routeTheme = _.get(this, "$route.meta.theme", "default") || "default";
                let classes = ["no-data-box", `theme-${routeTheme}`];
                return classes;
            },
            messageClassAttr() {
                let lowerSize = _.toLower(this.size);
                return {
                    "message": true,
                    [`message-${lowerSize}`]: _.isString(this.size) && _.includes(this.acceptedSizes, lowerSize),
                    "text-danger": this.variant === "danger" || this.variant === "error",
                    "text-success": this.variant === "success"
                };
            }
        }
    }
</script>
