<template>
    <div class="rq-input-with-default form-group">
        <label v-if="labelText" :for="automationId">{{labelText}}</label>
        <button v-if="restoreEnabled" :automation_id="`btn_restore_default_${automationName}`" type="button" class="btn btn-link btn-restore" @click="onRestoreDefault" v-show="!isDefaultValue">{{restoreButtonText}}</button>
        <template v-if="readonly">
            <input :id="automationId" :automation_id="automationId" type="text" class="form-control form-control-sm" :value="localValue" readonly>
        </template>
        <template v-else>
            <rqdx-date-box v-if="isDateType"
                :automation_id="automationId"
                :id="automationId"
                v-model="localValue"
            />
            <rqInputNumber v-else-if="isNumberType" :id="automationId" :automation_id="automationId" :prefix="prefix" defaultValue="0" decimals="2" cssClass="form-control form-control-sm" v-model="localValue" />
            <input v-else :id="automationId" :automation_id="automationId" type="text" :maxlength="maxlength" :placeholder="placeholder" v-model="localValue" class="form-control form-control-sm">
        </template>
    </div>
</template>
<script>
    export default {
        props: {
            id: { default:null },
            automationName: { default: "NO_NAME" },
            componentKey: { default: _.uniqueId() },
            labelText: { default: "" },
            modelValue: { default: null },
            inputType: { default: "text" },
            placeholder: { default: "" },
            maxlength: { default: null },
            decimals: { default: 2 },
            prefix: { default: "$" },
            defaultValue: { default: null },
            restoreButtonText: { default: "Restore Default" },
            restoreEnabled: { default: true },
            overriddenFlag: { default: false },
            showClearValueIcon: { default: false },
            disabled: { default: false },
            readonly: { default: false }
        },

        data: function () {
            const self = this;
            return {
                localValue: null,
                originalValue: null
            }
        },

        computed: {
            automationId() { return this.isValidType ? `${ this.typeInfo.automationIdPrefix }${ this.automationName }` : this.automationId; },
            typeInfo() {
                if(this.isValidType)
                    return _.find(this.INPUT_TYPES, t => t.type === this.inputType);
                return { automationIdPrefix:"INVALID_", type:"INVALID" }
            },
            hasValue() { return !_.isNil(this.originalValue) || !_.isNil(this.modelValue); },
            isDefaultValue() { return this.isEqual(this.modelValue, this.defaultValue); },
            isDirty() { return this.hasValue && !this.isEqual(this.originalValue, this.modelValue); },
            isValidType() { return _.some(this.INPUT_TYPES, t => t.type === this.inputType.toLowerCase()); },
            isDateType() { return this.inputType.toLowerCase() === "date"; },
            isNumberType() { return this.inputType.toLowerCase() === "number"; },
            isTextType() { return this.inputType.toLowerCase() === "text"; },
            clearValueIconVisible() { return this.showClearValueIcon && !_.isEmpty(this.localValue); }
        },

        created: function () {
            this.INPUT_TYPES = [
                { automationIdPrefix:"txt_", type:"text" },
                { automationIdPrefix:"num_", type:"number" },
                { automationIdPrefix:"dtp_", type:"date" },
            ];
            this.init();
        },

        watch: {
            localValue(newVal, oldVal){ if(newVal !== oldVal) this.updateValue(newVal); },
            modelValue(newVal, oldVal) { if(newVal !== oldVal) this.updateValue(newVal); }
        },

        methods: {

            init() {
                this.originalValue = this.modelValue;
                this.localValue = this.modelValue;
                this.validateProps();
            },

            onRestoreDefault() {
                this.revertToDefault();
            },

            onClearValue() {
                this.$emit("update:modelValue", null);
            },

            updateValue(val) {
                if(!this.isEqual(this.localValue, val)) this.localValue = val;
                if(!this.isEqual(this.modelValue, val)) {
                    this.$emit("update:modelValue", val);
                    if(this.restoreEnabled)
                        this.$emit("update:overriddenFlag", !this.isDefaultValue);
                    else
                        this.$emit("update:defaultValue", val);

                }
            },

            updateOriginalValue(){
                this.originalValue = this.modelValue;
            },

            revertToOriginal() {
                this.$emit("update:modelValue", this.originalValue);
            },

            revertToDefault() {
                this.$emit("defaultRestored", { previousValue: this.localValue, defaultValue: this.defaultValue });
                this.$emit("update:modelValue", this.defaultValue);
            },

            validateProps(){
                if(!this.isValidType){
                    console.error(`rqInputWithDefault[${this.automationId}] - Invalid Type: Input type, "${this.inputType}", is not valid. Valid types are "text", "number", and "date".`);
                    return false;
                }
                return true;
            },

            isEqual(a, b) {
                return (this.isDateType && moment(new Date(a)).isSame(new Date(b))) || a === b
            }
        }
    }
</script>