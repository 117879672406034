<template>
    <label :class="classList">
        <input type="checkbox"
            class="switch-input"
            ref="input"
            :value="modelValue"
            :checked="isChecked"
            :disabled="disabled"
            :readonly="readonly"
            :automation_id="automation_id"
            :tabindex="tabindex"
            @change="handleChange"
        >
        <span v-if="isOn" class="switch-label" :data-on="on" :data-off="off"></span>
        <span v-else class="switch-label"></span>
        <span class="switch-handle"></span>
    </label>
</template>

<script>
    export default {
        name: "RqSwitch",
        emits: ["update:modelValue", "change"],
        props: {
            modelValue: { default: false },
            checkedValue: { default: true },
            uncheckedValue: { default: false },
            type: { type: String, default: "default" },
            variant: { type: String, default: "" },
            pill: { type: Boolean, default: false },
            on: { type: String, default: "On" },
            off: { type: String, default: "Off" },
            size: { type: String, default: "lg" },
            disabled: { type: Boolean, default: null },
            readonly: { type: String, default: null },
            automation_id: { type: String, default: null },
            tabindex: { type: Number, default: 0 }
        },
        computed: {
            classList () {
                return [
                    "switch",
                    this.switchType,
                    this.switchVariant,
                    this.switchPill,
                    this.switchSize
                ];
            },
            switchType () { return this.type ? `switch-${this.type}` : `switch-default`; },
            switchVariant () { return this.variant ? `switch-${this.variant}` : null; },
            switchPill () { return !this.pill ? null : `switch-pill`; },
            switchSize () { return this.size ? `switch-${this.size}` : ""; },
            isChecked () { return this.checkedValue === this.modelValue; },
            isOn () { return !this.on ? null : true; }
        },
        methods: {
            handleChange ({ target: { checked } }) {
                let newValue = checked ? this.checkedValue : this.uncheckedValue;
                this.$emit("update:modelValue", newValue);
                this.$emit("change", newValue);
            },

            focus () {
                this.$refs.input.focus();
            }
        }
    };
</script>
