<template>
    <div :id="elementIdAttr" :automation_id="automationId" :class="{ 'rq-tab-strip':true, 'tab-scrolling-left':scrollLeftEnabled, 'tab-scrolling-right':scrollRightEnabled }">

        <button type="button"
            class="scroll-btn scroll-left"
            v-show="scrollLeftEnabled"
            @click="onTabScrollClick('left')">
            <FontAwesomeIcon icon="fas fa-chevron-left" />
        </button>

        <button type="button"
            class="scroll-btn scroll-right"
            v-show="scrollRightEnabled"
            @click="onTabScrollClick('right')">
            <FontAwesomeIcon icon="fas fa-chevron-right" />
        </button>

        <draggable ref="tabs" v-model="items" element="ul" :style="tabScrollStyle" class="nav nav-tabs" @change="onTabOrderChange">
            <li v-for="(tab, index) in items" ref="tabElements" :class="{'nav-item':true, 'active': tab.key === activeTabKey, 'failed':tab.status === status.failure }" :key="tab.key">
                <a :class="{'nav-link':true, 'active': tab.key === activeTabKey }" href="#" @click.prevent="onTabClick(index)">
                    {{tab.label}}
                    <span v-if="tab.status === status.failure" class="tab-status tab-failed fas fa-exclamation-circle" title="Issues while loading"></span>
                    <span v-if="tabItems.length > 1" class="tab-status tab-close fas fa-times" title="Close Document" @click="onTabCloseClick(index)"></span>
                    <span v-else class="tab-close-spacer"></span>
                </a>
            </li>
        </draggable>



    </div>
</template>
<script>
    import draggable from 'vuedraggable';

    class ProgressItem {
        constructor(options) {
            options = options || {};
            this.key = options.key || _.uniqueId();
            this.label = options.label || options.description || `(NO LABEL) - ${this.key}`;
            this.description = options.description || options.label || `(No Description) - ${this.key}`;
            this.status = options.status || ProgressItem.status.loading;
            this.loadResult = options.loadResult || "";
        }

        static get status() {
            return {
                loading: 0,
                success: 1,
                failure: 2
            }
        }
    }

    class TabItem extends ProgressItem {
        constructor(options) {
            super(options);
            options = options || {};
            this.key = options.key || _.uniqueId();
            this.value = options.value || this.label;
            this.disabled = _.parseBool(options.disabled);
            this.closeable = _.parseBool(options.disabled, true);
        }

        get progressItem() {
            return new ProgressItem(this);
        }
    }

    export default {
        name:"rqTabStrip",

        components: { draggable },

        props: {
            id: { type: String, default:null },
            automation_id: { type: String, default: "" },
            componentKey: { default: _.uniqueId() },
            modelValue: { default:0 },
            tabItems: { default: function () { return []; } },
            disabled: { default: false }
        },

        data() {
            const self = this;
            return {
                elementIdAttr: _.isNil(self.id) ? `tabs-rq-tabstrip-${self.componentKey}` : self.id, //id prop transferred to the actual select element for css selector access
                items: [],
                activeTabKey: 0,
                elementWidth: 0,
                allTabsWidth: 0,
                scrollIndex: 0,
                scrollMargin: 0,
                tabScrollStyle: {},
                status: ProgressItem.status
            }
        },

        computed: {
            automationId() { return this.automation_id || this.elementIdAttr; },
            scrollEnabled() {
                return (this.elementWidth)
                    ? this.allTabsWidth > this.elementWidth
                    : false;
            },
            scrollLeftEnabled() { return this.scrollEnabled && this.scrollIndex > 0; },
            scrollRightEnabled() { return this.scrollEnabled && (this.allTabsWidth - this.scrollMargin > this.elementWidth); }
        },

        watch: {
            modelValue(newVal, oldVal) {
                this.activeTabKey = this.tabItems[newVal].key;
            },
            tabItems(newValue, oldValue){
                if (newValue && newValue.length > 0) {
                    this.items = newValue;
                    this.init();
                }
            }
        },

        methods: {

            init(){
                const self = this;
                if (self.items.length > 0) {
                    self.activeTabKey = self.tabItems[self.modelValue].key;
                    self.updateDimensions();
                    window.addEventListener("resize", _.debounce(function () { self.updateDimensions(false); }, 200));
                }
            },

            updateDimensions(calcTabWidth=true) {
                const self = this;
                if (self.$el) self.elementWidth = self.$el.clientWidth;
                if (calcTabWidth) {
                    let tabsWidth = self.elementWidth;
                    if (self.$refs.tabElements && self.$refs.tabElements.length > 0) {
                        tabsWidth = _.reduce(self.$refs.tabElements, (w, el, i) => { return isNaN(w) ? el.clientWidth : w + el.clientWidth; });
                        tabsWidth += 125;
                        if (tabsWidth > self.elementWidth)
                            self.allTabsWidth = tabsWidth;
                        else {
                            self.allTabsWidth = self.elementWidth;
                        }
                    }
                }

                if (self.allTabsWidth <= self.elementWidth) {
                    self.scrollIndex = 0;
                    self.scrollMargin = 0;
                }
                let marginStyle = self.scrollMargin > 0 ? `-${self.scrollMargin}px` : "0";
                //self.tabScrollStyle = { "width": `${self.allTabsWidth}px`, "margin-left": `${marginStyle}` };
                self.tabScrollStyle = { "margin-left": `${marginStyle}` };
            },

            updateScrollStyle() {
                const self = this;
                if (self.scrollIndex === 0)
                    self.tabScrollStyle = { "width": `${self.allTabsWidth}px`, "margin-left": "0" };
                else {
                    let scrollMargin = 0;
                    for (let i = 0; i < self.scrollIndex; i++) {
                        scrollMargin += self.$refs.tabElements[i].clientWidth;
                    }
                    self.scrollMargin = scrollMargin;
                    let marginStyle = self.scrollMargin > 0 ? `-${self.scrollMargin}px` : "0";
                    self.tabScrollStyle = { "width": `${self.allTabsWidth}px`, "margin-left": `${marginStyle}` };
                }
            },

            onTabClick(tabIndex) {
                const self = this;
                let cancelTabChange = false;
                const eventArgs = {
                    index: tabIndex,
                    previousIndex: self.modelValue,
                    currentTab: _.clone(self.tabItems[tabIndex]),
                    previousTab: _.clone(self.tabItems[self.modelValue]),
                    cancel: function () { cancelTabChange = true; }
                };
                self.$emit("tabChange", eventArgs);

                if (cancelTabChange) return;

                self.$emit("update:modelValue", tabIndex);
            },

            onTabScrollClick(direction) {
                const self = this;
                const maxScrollIndex = self.tabItems.length - 1;
                let newIndex = self.scrollIndex;

                if (direction === "left") newIndex--;
                if (direction === "right") newIndex++;

                newIndex = newIndex < 0 ? 0 : newIndex;
                newIndex = newIndex > self.maxScrollIndex ? self.maxScrollIndex : newIndex;

                self.scrollIndex = newIndex;
                self.updateScrollStyle();
            },

            onTabCloseClick(tabIndex) {
                const self = this;
                let cancelClose = false;
                let closingTab = _.clone(self.tabItems[tabIndex]);
                let nextTabIndex = tabIndex === self.modelValue ? 0 : self.modelValue;
                let nextActiveTab = _.clone(self.tabItems[nextTabIndex]);
                let tabs = _.cloneDeep(self.items);
                _.pullAt(tabs, [tabIndex]);

                self.$emit("tabClose", {
                    index: tabIndex,
                    currentTab: _.clone(self.items[tabIndex]),
                    tabItems: tabs,
                    nextActiveTab: nextActiveTab,
                    cancel: function () { cancelClose = true; }
                });

                if (cancelClose) return;

                self.$emit("update:modelValue", nextTabIndex);
                self.activeTabKey = nextActiveTab.key;

                self.items = tabs;
                self.$nextTick(function () {
                    self.updateDimensions();
                });
            },

            onTabOrderChange(e) {
                this.$emit("tabOrderChange", { tabItems:_.cloneDeep(this.items) });

                let newActiveIndex = _.findIndex(this.items, item=>{ return item.key === this.activeTabKey; });
                this.$emit("update:modelValue", newActiveIndex);
            }
        }
    };

</script>