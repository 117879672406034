﻿<template>
    <b-alert :show="validationErrors.length > 0" variant="danger" class="validation-summary" dismissable @dismissed="validationErrors = []">
        <h6>{{titleMessage}}</h6>
        <div v-for="(e,index) in validationErrors" :key="index" class="validation-summary-error">{{e}}</div>
    </b-alert>
</template>

<script>
    export default {
        props: { 
            titleMessage:{  
                default:"Please correct the following errors:" 
            }, 
            dataSource: { default: () => [] },
            highlightNoChange: { type: Boolean, default: false }
        },
        data() { 
            return { 
                validationErrors: this.dataSource || []
            } 
        },
        watch: {
            dataSource(newVal, oldVal) {
                if(_.isEqual(newVal, oldVal)) {
                    this.toggleShadowHighlight();
                    return;
                }
                this.validationErrors = _.clone(newVal);
            }
        },
        methods: {
            toggleShadowHighlight() {
                if(!this.highlightNoChange || _.isEmpty(this.validationErrors)) return;                
                this.$nextTick().then(()=>{
                    this.$el.classList.add("shadow-highlight");
                    _.delay(() => {
                        this.$el.classList.remove("shadow-highlight");
                    }, 2100);
                });
            }
        }
    }
</script>