<template>
    <PromptInputFormGroup
        :label="label"
        :label-for="idAttrs.id"
        :is-required="isRequired"
        :show-banner="showBanner"
        :has-error="hasError"
        :validation-messages="validationMessages"
        :alerts="alerts">
        <dx-select-box
            :input-attr="idAttrs"
            class="form-control"
            :items="items"
            :value-expr="valueExpr"
            :display-expr="displayExpr"
            :search-enabled="true"
            :defer-rendering="false"
            v-model="inputValue"
            @enterKey="$emit('ok')"
            v-focus.input="{ delay: 100 }"
        />
    </PromptInputFormGroup>
</template>
<script setup>
    import { computed } from "vue";
    import { usePromptInputProps, usePromptInput } from "./composables";
    import PromptInputFormGroup from "./PromptInputFormGroup.vue";

    const props = defineProps({
        ...usePromptInputProps("drp_prompt_select"),
        valueExpr: String,
        displayExpr: String,
        items: Array
    });

    const {
        idAttrs,
        inputValue,
        showBanner,
        hasError,
        validationMessages,
        isValid
    } = usePromptInput(props);

    const selectedValue = computed(() => inputValue.value);
    const selectedItem = computed(() => _.find(props.items, [props.valueExpr, selectedValue]));

    defineExpose({ isValid, selectedValue, selectedItem });
</script>
