<template>
    <span :class="{ 'rq-expand-collapse-all':true, 'rq-expand-collapse-all-inline':inline }">
        <span v-rq-tooltip="{ contaner: tooltipContainer, boundary: tooltipBoundary, triggers: 'hover', placement: 'top', title: expandAllTooltip }">
            <b-btn
                variant="link"
                class="btn-theme"
                :disabled="allExpanded"
                automation_id="btn_expand_all"
                @click="onExpandAll">Expand All
            </b-btn>
        </span>
        <span class="link-divider">|</span>
        <span v-rq-tooltip="{ contaner: tooltipContainer, boundary: tooltipBoundary, triggers: 'hover', placement: 'top', title: collapseAllTooltip }">
            <b-btn
                variant="link"
                class="btn-theme"
                :disabled="allCollapsed"
                automation_id="btn_collapse_all"
                @click="onCollapseAll">Collapse All
            </b-btn>
        </span>
    </span>
</template>

<script>
    export default {
        name: "RqExpandCollapseAll",
        props:{
            tooltipContainer: { type: [String, HTMLElement, Object], default: null },
            tooltipBoundary: { type: [String, HTMLElement, Object], default: null },
            expandAllTooltip: { type:String, default: "Expand All" },
            collapseAllTooltip: { type:String, default: "Collapse All" },
            allExpanded:  { type: Boolean, default: false },
            allCollapsed:  { type: Boolean, default: false },
            inline:  { type: Boolean, default: false }
        },
        methods: {
            onExpandAll() { this.$emit("expand-all"); },
            onCollapseAll() { this.$emit("collapse-all"); }
        }
    }
</script>
