/* eslint-disable complexity */

import { markRaw } from "vue";

/* Dialog Plugin Events */
export const OPEN_DIALOG_EVENT = "rq-dialog--open";
export const CLOSE_DIALOG_EVENT = "rq-dialog--close";
export const CLOSE_ALL_DIALOGS_EVENT = "rq-dialog--close-all";
export const RELOAD_DIALOG_EVENT = "rq-dialog--reload";
export const PROMPT_CONFIRM_EVENT = "rq-dialog--prompt-confirm";
export const PROMPT_MESSAGE_EVENT = "rq-dialog--prompt-message";
export const PROMPT_SELECT_EVENT = "rq-dialog--prompt-select";
export const PROMPT_MULTISELECT_EVENT = "rq-dialog--prompt-multiselect";
export const PROMPT_INPUT_EVENT = "rq-dialog--prompt-input";
export const PROMPT_DATE_INPUT_EVENT = "rq-dialog--prompt-date-input";
export const RQ_DIALOG_EVENTS = {
    OPEN: OPEN_DIALOG_EVENT,
    CLOSE: CLOSE_DIALOG_EVENT,
    CLOSE_ALL: CLOSE_ALL_DIALOGS_EVENT,
    RELOAD: RELOAD_DIALOG_EVENT,
    PROMPT_CONFIRM: PROMPT_CONFIRM_EVENT,
    PROMPT_MESSAGE: PROMPT_MESSAGE_EVENT,
    PROMPT_SELECT: PROMPT_SELECT_EVENT,
    PROMPT_MULTISELECT: PROMPT_MULTISELECT_EVENT,
    PROMPT_INPUT: PROMPT_INPUT_EVENT,
    PROMPT_DATE_INPUT: PROMPT_DATE_INPUT_EVENT
};
/************************/

const parseSizeValue = function(value, defaultValue, acceptZeroValue=false) {
    if(value === 0 && acceptZeroValue) return value;
    let result = _.isString(value) && _.includes(value, "%") ? value : _.parseNumber(value, defaultValue);
    return result;
};

export class RQDialogButton {
    constructor(options) {
        options = options || {};
        this.clientKey = _.uniqueId("rq-dialog-button-");
        this.title = options.title || "";
        this.cssClass = options.cssClass || "";
        this.spanClass = options.spanClass || "";
        this.isDisabled = options.isDisabled || false;
        this.isVisible = _.parseBool(options.isVisible, true);
        this.iconClass = options.iconClass || null;
        this.tooltip = options.tooltip || null;
        this.closeOnComplete = _.parseBool(options.closeOnComplete, true);
        this.triggerOnEnter = _.parseBool(options.triggerOnEnter, false);
        this.onClick = options.onClick || null;
        this.automationId = options.automationId || this.clientKey;
    }
}

export class RQDialogDimensions {
    constructor(options){
        this.height = _.parseNumber(options.height, null);
        this.width = _.parseNumber(options.width, null);
        this.heightType = options.heightType || null;
        this.widthType = options.widthType || null;
        this.validateDimension("height");
        this.validateDimension("width");
        this.ready = true;
    }

    validateDimension(key) {
        if(!isNaN(_.parseNumber(this[key]))) {
            this.validateDimensionType(key);
            return;
        }
        this[key] = 75;
        this[`${key}Type`] = "%";
    }

    validateDimensionType(key) {
        if(!isNaN(_.parseNumber(this[key]))) return;
        this[`${key}Type`] = this[key] <= 100 ? "%" : "px";
    }

    dimensionValue(key) {
        let typeKey = `${key}Type`;
        return `${this[key]}${this[typeKey]}`;
    }

    valueMatches(key, val) {
        let typeKey = `${key}Type`;
        let valueKey = `${key}Value`;
        let trueVal = val && val.replace ? val.replace("px", "") : val;
        return this[typeKey] === "%" ? this[valueKey] === val : this[key] === parseSizeValue(trueVal);
    }

    matches(height, width) { return this.valueMatches("height", height) && this.valueMatches("width", width); }

    get heightValue() { return this.dimensionValue("height"); }
    get widthValue() { return this.dimensionValue("width"); }
    get maximized() { return this.heightValue === "100%" && this.widthValue === "100%"; }
}

export class RQDialogOptions {
    constructor(options) {
        options = options || {};
        this.dialogId = options.dialogId || this.createDialogId();
        this.clientKey = options.clientKey || _.uniqueId();
        this.component = _.isEmpty(options.component) ? null : markRaw(options.component);

        /* Not currently in use */
        this.headerComponent = options.headerComponent || null;
        this.footerComponent = options.footerComponent || null;
        /************************/

        this.args = options.args || {};
        this.props = options.props || {};
        this.props.args = this.args;

        this.title = options.title || "";
        this.titleSize = options.titleSize || "lg";
        //let mWidth = options.size ? RQDialogOptions.widthFromSize(options.size) : 600;
        // this.minWidth = options.minWidth || mWidth === "80%" ? 500 : mWidth;
        // this.minHeight = options.minHeight || 300;
        let defaultWidth = options.size ? RQDialogOptions.widthFromSize(options.size) : 600;
        let defaultHeight = options.size ? RQDialogOptions.heightFromSize(options.size) : 300;

        this.adaptive = _.parseBool(options.adaptive);

        if(this.adaptive && !_.isNil(options.height) && options.height !== "auto")
            console.warn(`RqDialog -- When "adaptive: true" the "height" property is ignored and the value "auto" is used.  Remove the "adaptive" property to set an explicit height.`);
        if(this.adaptive && _.parseBool(options.resizable))
            console.warn(`RqDialog -- When "adaptive: true" the dialog cannot be resized.  Remove the "adaptive" property to allow resizing.`);

        this.height = this.adaptive ? "auto" : (options.height || defaultHeight);
        this.width = options.width || defaultWidth;
        this.minWidth = parseSizeValue(options.minWidth, 200);

        //TSG -- minHeight doesn't seem to work when height === "auto" and/or adaptive === true so this value is handled in dialog.vue
        this.minHeight = parseSizeValue(options.minHeight, 125);

        this.waitForLoadEvent = _.parseBool(options.waitForLoadEvent, false);
        this.showLoadIndicatorOnOk = _.parseBool(options.showLoadIndicatorOnOk, false);
        this.draggable = _.parseBool(options.draggable, true);
        this.scrollable = _.parseBool(options.scrollable, true);
        this.overflowVisible = !this.scrollable && _.parseBool(options.overflowVisible)
        this.resizable = _.parseBool(options.resizable, true);
        this.enableMinMax = _.parseBool(options.enableMinMax);
        this.dialogStyle = options.dialogStyle || "modal-default";
        this.dialogTheme = options.dialogTheme || null;
        this.ignoreRouteTheme = _.parseBool(options.ignoreRouteTheme);
        this.headerIcon = options.headerIcon || "";
        this._closeTitle = options.closeTitle || options.cancelTitle || "Cancel";
        this._cancelTitle = options.cancelTitle || options.closeTitle || "Cancel";
        this.okTitle = options.okTitle || "OK";
        this.okDisabled = _.parseBool(options.okDisabled);

        this.autoFocusOkButton = _.parseBool(options.autoFocusOkButton, true);
        this.autoFocusFirstInput = this.autoFocusOkButton && !this.okDisabled ? false : _.parseBool(options.autoFocusFirstInput, true);

        this.triggerOkOnEnter = _.parseBool(options.triggerOkOnEnter);
        this.closeOnBackdrop = _.parseBool(options.closeOnBackdrop);
        this.closeOnEsc = _.parseBool(options.closeOnEsc);
        this.hideHeader = _.parseBool(options.hideHeader);
        this.hideFooter = _.parseBool(options.hideFooter);
        this.customHeader = _.parseBool(options.customHeader);
        this.customFooter = _.parseBool(options.customFooter);
        this.okOnly = _.parseBool(options.okOnly);
        this._closeOnly = _.parseBool(options.closeOnly, _.parseBool(options.cancelOnly, false));
        this._cancelOnly = _.parseBool(options.cancelOnly, _.parseBool(options.closeOnly, false));
        this.showHeaderClose = _.parseBool(options.showHeaderClose);
        this.transition = options.transition || "";
        this.showRequiredFieldLegend = _.parseBool(options.showRequiredFieldLegend);

        //this.slots = options.slots || {};

        const defaultPos = { x:0.5, y:0.5 };
        this.position = Object.assign(defaultPos, options.position);

        this.index = options.index || 0;
        let btns = options.buttons || [];
        this.buttons = _.map(btns, b => new RQDialogButton(b));
        this.okTooltip = options.okTooltip || null;
        this.cancelTooltip = options.cancelTooltip || null;
        this.onOk = options.onOk || null;
        this.onCancel = options.onCancel || null;
        this.onClose = options.onClose || null;
        this.onDataUpdate = options.onDataUpdate || null;
        this.onComponentAction = options.onComponentAction || null;
        this.onResize = options.onResize || null;
        this.onBeforeOpen = options.onBeforeOpen || null;
        this.onOpened = options.onOpened || null;
        this.onBeforeClosed = options.onBeforeClosed || null;
        this.onClosed = options.onClosed || null;
    }

    get closeTitle() { return this._closeTitle || this._cancelTitle; }
    set closeTitle(value) { this._closeTitle = value; this._cancelTitle = value; }

    get cancelTitle() { return this._closeTitle || this._cancelTitle; }
    set cancelTitle(value) { this._closeTitle = value; this._cancelTitle = value; }

    get closeOnly() { return this._closeOnly; }
    set closeOnly(value) { this._closeOnly = value; this._cancelOnly = value; }

    get cancelOnly() { return this._cancelOnly; }
    set cancelOnly(value) { this._closeOnly = value; this._cancelOnly = value; }

    get size() {
        let w = isNaN(this.width)
            ? Number(this.width.replace("%","").replace("px",""))
            : Number(this.width);
        switch (w){
            case 200: return "sm" ;
            case 600: return "md";
            case 800: return "lg";
            case 80: return "xl";
            default: return "";
        }
    }
    set size(value){
        if (!value) return;
        let widthVal = RQDialogOptions.widthFromSize(value);
        let heightVal = RQDialogOptions.heightFromSize(value);
        //this.minWidth = widthVal === "80%" ? 500 : widthVal > 500 ? widthVal/2 : 200;
        this.width = widthVal;
        this.height = heightVal;
    }

    get noCloseOnBackdrop() { return !this.closeOnBackdrop; }
    set noCloseOnBackdrop(value) { this.closeOnBackdrop = !value; }

    static widthFromSize(sz){
        switch (sz){
            case "sm": return 300;
            case "lg": return 800;
            case "xl": return "80%";
            default: return 600;
        }
    }

    static heightFromSize(sz){
        switch (sz){
            case "sm": return 200;
            case "md": return 300;
            case "lg": return 500;
            case "xl": return "95%";
            default: return 200;
        }
    }

    static createDialogId() {
        return _.uniqueId("rq_dialog_");
    }
}