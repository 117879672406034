import { ref, computed } from "vue";
import { DateTime } from "luxon";

//ISO format w/o timezone offset and ms
export const LUXON_ISO_FORMAT = "yyyy-MM-dd'T'hh:mm:ss";

export const formatDate = (date, fmt=LUXON_ISO_FORMAT) => {
    let dt = _.parseLuxonDateTime(date);
    if(_.isNil(dt) || !dt.isValid) return null;
    return fmt === "iso"
        ? dt?.toISO()
        : dt?.toFormat(fmt);
};

export function useDateHelpers(props, emit, idPrefix="dtp_rqdx_date_range") {

    const originalStartDate = ref(props.startDate);
    const originalEndDate = ref(props.endDate);

    const typeProp = computed(() => props?.type || "date");
    const formatProp = computed(() => props?.format || LUXON_ISO_FORMAT);

    const getDateValueComputed = prop => computed({
        get() { return formatDate(props?.[prop], formatProp.value); },
        set(val) {
            let dateVal = formatDate(val, formatProp.value);
            emit(`update:${prop}`, dateVal);
        }
    });

    const startDateValue = getDateValueComputed("startDate");
    const endDateValue = getDateValueComputed("endDate");

    const elementIdAttr = computed(() => _.isNil(props?.id) ? _.uniqueId(idPrefix) : props?.id);
    const automationId = computed(() => props?.automation_id || elementIdAttr.value);
    const startDateCalendarId = computed(() => `${elementIdAttr.value}_calendar_start`);
    const startDateId = computed(() => `${elementIdAttr.value}_start`);
    const startDateAutomationId = computed(() => `${automationId.value}_start`);
    const endDateCalendarId = computed(() => `${elementIdAttr.value}_calendar_end`);
    const endDateId = computed(() => `${elementIdAttr.value}_end`);
    const endDateAutomationId = computed(() => `${automationId.value}_end`);
    const maxDate = computed(() => props?.allowBackDate ? null : endDateValue.value || null);
    const minDate = computed(() => props?.allowBackDate ? null : startDateValue.value || null);

    function compareDates(d1, d2, sameOr=null) {
        let dobj1 = _.parseDate(d1);
        let dobj2 = _.parseDate(d2);
        let dt1 = DateTime.fromJSDate(dobj1);
        let dt2 = DateTime.fromJSDate(dobj2);
        switch(sameOr) {
            case "before": return dt1 <= dt2;
            case "after": return dt1 >= dt2;
        }

        const dateUnits = ["year", "month", "day"];
        const timeUnits = ["hour", "minute", "second"];
        let units = typeProp.value === "date"
            ? dateUnits
            : [ ...dateUnits, ...timeUnits ];
        return _.every(units, u => dt1.hasSame(dt2, u));
    }

    const isSame = (date1, date2) => compareDates(date1, date2);
    const isSameOrBefore = (date1, date2) => compareDates(date1, date2, "before");
    const isSameOrAfter = (date1, date2) => compareDates(date1, date2, "after");

    const setOriginalValues = () => {
        originalStartDate.value = startDateValue.value;
        originalEndDate.value = endDateValue.value;
    };

    const revert = () => {
        startDateValue.value = originalStartDate.value;
        endDateValue.value = originalEndDate.value;
    };

    const clear = () => {
        startDateValue.value = "";
        endDateValue.value = "";
    };

    setOriginalValues();

    return {
        originalStartDate,
        originalEndDate,
        startDateValue,
        endDateValue,
        elementIdAttr,
        automationId,
        startDateCalendarId,
        startDateId,
        startDateAutomationId,
        endDateCalendarId,
        endDateId,
        endDateAutomationId,
        minDate,
        maxDate,
        formatDate,
        isSame,
        isSameOrBefore,
        isSameOrAfter,
        setOriginalValues,
        revert,
        clear
    };
}