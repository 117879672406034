<template>
    <div :class="{ 'rq-date-range-editor': true, 'rq-borderless': borderless }">
        <div class="rq-date-range-calendars">
            <div class="row">
                <div class="col">
                    <dx-calendar
                        :id="startDateCalendarId"
                        :max="maxDate"
                        :disabled="disabled"
                        v-model="startDateValue"
                        @value-changed="onStartDateValueChanged"
                    />
                </div>
                <div class="col">
                    <dx-calendar
                        :id="endDateCalendarId"
                        :min="minDate"
                        :disabled="disabled"
                        v-model="endDateValue"
                        @value-changed="onEndDateValueChanged"
                    />
                </div>
            </div>
        </div>
        <div class="rq-date-range-inputs">
            <div class="row">
                <div class="col-8">
                    <div class="row">
                        <div class="col form-group mb-0 pe-0">
                            <rqdx-date-box
                                ref="dxDateBoxStart"
                                :id="startDateId"
                                :automation_id="startDateAutomationId"
                                class="form-control"
                                width="100%"
                                :max="maxDate"
                                type="date"
                                :show-drop-down-button="false"
                                v-model="startDateValue"
                            />
                        </div>
                        <div class="col-1 px-0 pt-2 text-center">to</div>
                        <div class="col form-group mb-0 ps-0">
                            <rqdx-date-box
                                ref="dxDateBoxEnd"
                                :id="endDateId"
                                :automation_id="endDateAutomationId"
                                class="form-control"
                                width="100%"
                                :min="minDate"
                                type="date"
                                :show-drop-down-button="false"
                                v-model="endDateValue"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-auto p-0 mt-1">
                            <rq-icon-button :automation_id="`${automationId}_date_clear`" icon="fas fa-times-circle" @click="clear" />
                        </div>
                        <div class="col-auto ps-0 ms-auto">
                            <b-btn :automation_id="`${automationId}_date_range_cancel`" variant="secondary" size="sm" class="m-1" @click="onCancel">Cancel</b-btn>
                            <b-btn :automation_id="`${automationId}_date_range_apply`" variant="dark" size="sm" class="m-1" @click="onApply">Apply</b-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, nextTick } from "vue";
    import { DateTime } from "luxon";
    import { LUXON_ISO_FORMAT, useDateHelpers } from "../DateUtil";

    import DxCalendar from "devextreme-vue/calendar";

    const props = defineProps({
        id: { type: String, default:null },
        automation_id: { type: String, default: "" },
        startDate: { type: String, default: DateTime.now().toFormat("MM/dd/yyyy") },
        endDate: { type: String, default: DateTime.now().plus({ weeks: 1 }).toFormat("MM/dd/yyyy") },
        format: { type: String, default: LUXON_ISO_FORMAT },
        disabled: { type: Boolean, default: false },
        borderless: { type: Boolean, default: false }
    });
    const emit = defineEmits(["apply", "cancel"]);
    const {
        startDateValue,
        endDateValue,
        automationId,
        startDateCalendarId,
        startDateId,
        startDateAutomationId,
        endDateCalendarId,
        endDateId,
        endDateAutomationId,
        minDate,
        maxDate,
        setOriginalValues,
        revert,
        clear
    } = useDateHelpers(props, emit);

    const dxDateBoxStart = ref(null);
    const dxDateBoxEnd = ref(null);

    function focus() {
        if(props.disabled) return;
        dxDateBoxStart.value?.insance?.focus?.();
    }

    function onCancel() {
        revert();
        emit("cancel");
    }

    function onApply() {
        setOriginalValues();
        emit("apply", {
            values: {
                startDate: startDateValue.value,
                endDate: endDateValue.value
            }
        });
    }

    async function onStartDateValueChanged(e) {
        await nextTick();
        startDateValue.value = e.value;
    }

    async function onEndDateValueChanged(e) {
        await nextTick();
        endDateValue.value = e.value;
    }

    defineExpose({ focus });

</script>