<template>
    <ConditionalParent
        :use-wrapper="((!button && !noLabel) || hasTooltip)"
        :id="containerId"
        :classAttr="{
            'btn-group': button && hasTooltip,
            'form-check': !button,
            'form-switch': isSwitch,
            'form-check-reverse': reverse
        }">
        <input
            ref="checkInput"
            :id="inputId"
            :automation_id="automationId"
            type="checkbox"
            :class="{
                'btn-check': button,
                'form-check-input': !button
            }"
            :disabled="disabled"
            v-model="boundValue">
        <label
            v-if="!button && !noLabel"
            :class="{
                [`btn btn-${buttonVariant}`]: button,
                'btn-sm': button && size === 'sm',
                'btn-lg': button && size === 'lg',
                'form-check-label': !button
            }"
            :for="inputId">
            <slot>
                <span v-html="label" />
            </slot>
        </label>
        <b-tooltip
            v-if="hasTooltip"
            :target="containerId">
            <span v-html="tooltip" />
        </b-tooltip>
    </ConditionalParent>
</template>
<script setup>
    import { ref, computed, onMounted, nextTick } from "vue";
    import ConditionalParent from "./ConditionalParent.vue";

    const props = defineProps({
        id: { type: String, default: () => _.uniqueId("rq-checkbox-") },
        automation_id: { type: String, default: "" },
        label: { type: String, default: "" },
        modelValue: { type: Boolean, default: false },
        autoFocus: { type: Boolean, default: false },
        buttonVariant: { type: String, default: "secondary" },
        tooltip: { type: String, default: "" },
        size: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        button: { type: Boolean, default: false },
        reverse: { type: Boolean, default: false },
        switch: { type: Boolean, default: false },
        noLabel: { type: Boolean, default: false },
    });

    const emit = defineEmits([
        "input",
        "change",
        "update:modelValue"
    ]);

    const boundValue = computed({
        get() { return props.modelValue; },
        set(val) {
            emit("input", val);
            emit("change", val);
            emit("update:modelValue", val);
        }
    });

    const inputId = computed(() => props.id);
    const automationId = computed(() => props.automation_id || inputId.value);

    const containerId = computed(() => `${inputId.value}_container`);
    const hasTooltip = computed(() => !_.isEmpty(props.tooltip));

    const isSwitch = computed(() => props.switch);

    const checkInput = ref(null);

    if(props.autoFocus && !props.disabled) {
        onMounted(async () => {
            await nextTick();
            checkInput.value?.focus();
        });
    }
</script>