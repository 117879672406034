<template>
    <div
        :id="id"
        :automation_id="automationId"
        :class="classAttr"
        :style="styleAttr"
        @keypress.enter="onContentClick">

        <b-btn
            v-if="defaultValue"
            v-show="defaultOverridden"
            :automation_id="`btn_restore_${automationId}`"
            variant="link"
            class="btn-theme btn-restore"
            @click="restoreOriginalValue">Restore Default
        </b-btn>

        <rq-no-data
            v-if="placeholder"
            v-show="contentEmpty"
            :text="placeholder"
        />

        <rq-scroll-container
            v-if="!contentEmpty"
            ref="rqScrollContainer"
            hide-top-button
            perfect-scrollbar
            always-visible
            always-wide>
            <rq-shadow-dom-element
                ref="shadowDomElement"
                :html-content="contentHtml"
            />
        </rq-scroll-container>

        <div
            v-if="clickable"
            class="content-click-target"
            @click="onContentClick">
        </div>
        <!-- <rq-resize-handle v-if="resizable || yResizable" resize-y v-model="maxHeightValue" /> -->
        <!-- <rq-resize-handle v-if="resizable || xResizable" v-model="maxWidthValue" resize-x /> -->
    </div>
</template>

<script>
    export default {
        name: "RqRtfDisplay",

        props: {
            id: { type: String, required: true },
            automation_id: { type: String, default: null },
            height: { default: 50 },
            minHeight: { default: 50 },
            maxHeight: { default: null },
            adaptive: { type: Boolean, default: false },
            borderless: { type: Boolean, default: false },
            noHover: { type: Boolean, default: false },
            modelValue: { type: String, default: "" },
            placeholder: { type: String, default: "" },
            defaultValue: { type: String, default: "", required: false },
            rtf: { type: Boolean, default: false },
            clickable: { type: Boolean, default: false },
            // resizable: { type: Boolean, default: false },
            // xResizable: { type: Boolean, default: false },
            // yResizable: { type: Boolean, default: false }
        },

        data () {
            return {
                content: "",
                contentHtml: "",
                contentBlob: null,
                contentUrl: "",
                heightValue: "",
                maxHeightValue: "",
                minHeightValue: "",
                contentHeight: 0
            };
        },

        computed: {
            automationId() { return this.automation_id || this.id; },
            contentEmpty() { return _.isEmpty(this.contentHtml); },
            defaultOverridden () { return $(this.content).text().trim() !== $(this.defaultValue).text().trim(); },
            classAttr() {
                return {
                    "rq-rtf-display":true,
                    "rq-no-border": this.borderless,
                    "rq-no-hover": this.noHover
                };
            },
            styleAttr() {
                let result = { height: this.heightValue, minHeight: this.minHeightValue };
                if(!_.isEmpty(this.maxHeightValue)) result.maxHeight = this.maxHeightValue;
                return result;
            },
            allowScrolling() {
                let maxHeight = _.parseNumber(this.maxHeight);
                return !this.adaptive || (!isNaN(maxHeight) && this.contentHeight > maxHeight);
            }
        },

        watch: {
            content (newValue, oldValue) {
                if (newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            },

            height: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || this.adaptive) return;
                    this.setDisplayHeight();
                },
                immediate: true
            },

            maxHeight(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.setDisplayHeight();
            },

            minHeight(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.setDisplayHeight();
            },

            modelValue: {
                handler(newValue, oldValue) {
                    if (newValue === this.content) return;
                    this.setContentValue(newValue);
                },
                immediate: true
            }
        },

        methods: {
            setContentValue(val) {
                this.content = val;
                if(this.rtf)
                    this.fetchHtmlContent(val);
                else
                    this.setHtmlContent(val);
            },

            setHtmlContent(val) {
                this.contentHtml = val;
                this.$nextTick(() => {
                    this.setDisplayHeight();
                })
            },

            fetchHtmlContent(val) {
                const self = this;
                if(!self.rtf) return;
                let rtfValue = val || self.content;
                let apiPromise = self.$api.UtilitiesApi.rtfToHtml(rtfValue);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.setHtmlContent(result);
                    });
            },

            setDisplayHeight() {
                const self = this;

                self.contentHeight = self.height;

                if(self.adaptive && !self.contentEmpty) {
                    let shadowDomElementHeight = _.getNumber(self, "$refs.shadowDomElement.$el.clientHeight", 0);
                    self.contentHeight = shadowDomElementHeight > 0
                        ? shadowDomElementHeight + 10
                        : self.height;
                }

                const parseUnitValue = val => _.tryParseNumber(val) ? `${val}px`: val;

                self.heightValue = parseUnitValue(self.contentHeight);
                self.minHeightValue = parseUnitValue(self.minHeight);

                if(!_.isNil(self.maxHeight)) self.maxHeightValue = parseUnitValue(self.maxHeight);

                //have to account for possible css transition if height has changed, so need to delay before updating rqScrollContainer/perfect-scrollbar
                _.delay(() => {
                    _.invoke(self, "$refs.rqScrollContainer.update");
                }, 300);
            },

            restoreOriginalValue () {
                this.$emit("default-restored", { previousValue: this.content, defaultValue: this.defaultValue });
                this.setContentValue(this.defaultValue);
            },

            resetDefaultValue() {
                this.defaultValue = this.content;
            },

            onContentClick() {
                if(!this.clickable) return;
                this.$emit("content-click", { targetElement: this.$el, content: this.content, html: this.contentHtml });
            },
        }
    };
</script>