<template>
    <span class="rq-icon-button"
        v-rq-tooltip="tipOptions">
        <button
            :id="idAttr"
            :automation_id="automationIdAttr"
            type="button"
            :class="['btn btn-icon', props.btnClass]"
            :disabled="disabled"
            @click="emit('click', $event)">
            <FontAwesomeIcon
                :icon="icon"
                :rotation="rotate"
            />
        </button>
    </span>
</template>

<script setup>
    import { computed } from 'vue';
    const props = defineProps({
        id: { type: String, default: null },
        automation_id: { type: String, default: null },
        icon: { type: String, default: "fas fa-exclamation-circle" },
        btnClass: { type: String, default: "" },
        tooltipText: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        rotate: { type: Number, default: 0 },
        tooltipOptions: { type: Object, default: () => ({}) }
    });
    const emit = defineEmits(["click"]);
    const componentId = _.uniqueId("rq_icon_button");
    const idAttr = computed(() => props.id || componentId);
    const automationIdAttr = computed(() => props.automation_id || idAttr.value);
    const tipOptions = computed(() => ({
        title: props.tooltipText,
        trigger: "hover",
        ...props.tooltipOptions
    }));
</script>