<template>
    <div :automation_id="automationId" :class="classAttr">
        <div v-if="showTitleBar" class="rq-tv-title-bar">
            <div v-if="hasTitle" class="rq-title rq-tv-title">{{title}}</div>
            <slot name="title"></slot>
        </div>
        <rq-scroll-container
            ref="scrollContainer"
            class="rq-tv-scroll-container"
            ps-class-attr="rq-tv-scroll-area"
            perfect-scrollbar
            ps-default-y
            @ps-scroll-y="onPsScrollY">
            <ul class="rq-tv-list">
                <rq-tree-view-item
                    #default="{ childItem }"
                    v-for="listItem in treeItems"
                    :key="listItem.key"
                    :item="listItem"
                    :data-source="dataSource"
                    :root-key="componentKey"
                    :expand-selected="expandSelected"
                    :caret-icon="caretIcon"
                    :caret-rotate="caretRotate"
                    :caret-rotate-on="caretRotateOn"
                    :error-tooltip-placement="errorTooltipPlacement"
                    v-model:expanded-keys="expandedKeysValue">
                    <slot name="item-label" :item="childItem"></slot>
                </rq-tree-view-item>
            </ul>
        </rq-scroll-container>
    </div>
</template>

<script>
    import RqTreeViewMixin from "./RqTreeViewMixin";
    export default {
        name:"RqTreeView",
        mixins: [RqTreeViewMixin],
        props: {
            id: { default:null },
            automation_id: { type: String, default: "" },
            title: { type: String, default: null },
            items: { type: Array, default: () => [] },
            showBorder: { type: Boolean, default: false },
            showTitleBar: { type: Boolean, default: true },
            showTitleBorder: { type: Boolean, default: false },
            scrollPosition: { type: Number, default: 0 },
            modelValue: { type: String, default: null },
            disabled: { type: Boolean, default: false },
            wrapLabels: { type: Boolean, default: false },
            caretPlacement: { type: String, default: "left" },
            storageKey: { type: String, default: null },
            persistExpanded: { type: Boolean, default: false },
            defaultExpandedKeys: { type: Array, default: () => [] },
        },
        data() {
            const self = this;
            return {
                treeItems: [],
                boundValue: null,
                storageKeyValue: null,
                persistedStateReady: false,
                applyDefaultExpandedKeys: false
            };
        },
        computed: {
            componentKey() { return _.uniqueId(`${_.kebabCase(this.$options.name)}-`); },
            elementIdAttr() { return this.id || this.componentKey; },
            automationId() { return this.automation_id || this.elementIdAttr; },
            eventNames() {
                const self = this;
                return {
                    select:`${self.componentKey}::select`,
                    selected:`${self.componentKey}::selected`,
                    clearSelection:`${self.componentKey}::clear-selection`,
                };
            },
            hasTitle() { return !_.isNil(this.title); },
            classAttr() {
                return {
                    "rq-tv-container": true,
                    "rq-tv-container-bordered": this.showBorder,
                    "rq-tv-title-bordered": this.showTitleBorder,
                    "rq-tv-wrap-labels": this.wrapLabels,
                    "rq-disabled": this.disabled,
                    "rq-tv-caret-right": this.caretPlacement === "right"
                };
            }
        },
        watch: {
            items: {
                handler(newValue, oldValue) {
                    this.treeItems = newValue;
                },
                immediate: true
            },
            modelValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.boundValue) return;
                this.setSelectedItem(newValue);
            },
            boundValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            },
            defaultExpandedKeys: {
                handler(newValue) {
                    if(!_.isArray(newValue) || !this.applyDefaultExpandedKeys) return;
                    this.expandedKeysValue = newValue;
                },
                immediate: true
            }
        },
        created() {
            this.init();
        },
        beforeUnmount() {
            //this.cleanUpPersistedState();
            this.$events.off(this.eventNames.selected);
        },
        methods: {
            init() {
                const self = this;
                self.$events.on(self.eventNames.selected, self.onSelected);
                self.loadPersistedState();
                self.refresh();
            },

            refresh(force=false) {
                const self = this;

                if(!_.isEmpty(self.items) && !force) return;

                self.loadFromDataSource()
                    .then(result => {
                        self.treeItems = result;
                        if(_.isEmpty(self.modelValue)) return;
                        self.$nextTick().then(() => {
                            self.setSelectedItem(self.modelValue);
                            if(self.scrollPosition <= 0) return;
                            self.$refs.scrollContainer.scrollTo(self.scrollPosition, 0, true);
                        });
                    });
            },

            setSelectedItem(key) {
                this.boundValue = key;
                if(_.isNil(key))
                    this.$events.emit(this.eventNames.clearSelection);
                else
                    this.$events.emit(this.eventNames.select, key);
            },

            clearSelection() {
                this.boundValue = null;
                this.$events.emit(this.eventNames.clearSelection);
            },

            getItemByKey(key) {
                const findTreeItem = treeItems => {
                    let result = null;
                    _.forEach(treeItems, item => {
                        if(!_.startsWith(key, item.key)) return;
                        if(item.key === key) {
                            result = item;
                        }
                        else if(!_.isEmpty(item.children)) {
                            result = findTreeItem(item.children);
                        }
                        return !_.isNil(result);
                    });
                    return result;
                };
                return findTreeItem(this.treeItems);
            },

            loadPersistedState() {
                const self = this;
                self.refreshPersistanceKey();
                if(!self.persistExpanded || _.isNil(self.storageKeyValue)) return;
                if(_.isNil(self.expandedKeys)) {
                    let state = localStorage.getItem(self.storageKeyValue);
                    self.applyDefaultExpandedKeys = _.isNil(state);
                    self.expandedKeysValue = _.isNil(state)
                        ? self.defaultExpandedKeys
                        : _.isEmpty(state)
                            ? []
                            : JSON.parse(state);
                }
                else {
                    localStorage.setItem(self.storageKeyValue, JSON.stringify(self.expandedKeys));
                }
                self.$nextTick(() => { self.persistedStateReady = true });
            },

            updatePersistedState(val=[], refreshKey=true) {
                const self = this;
                if(!self.persistedStateReady) return;
                if(refreshKey) self.refreshPersistanceKey();
                if(!self.persistExpanded || _.isNil(self.storageKeyValue)) return;
                localStorage.setItem(self.storageKeyValue, JSON.stringify(val));
            },

            cleanUpPersistedState() {
                const self = this;
                if(_.isEmpty(self.expandedKeysValue)) {
                    self.updatePersistedState([], false);
                    return;
                }

                let stateValue = self.expandedKeysValue.slice();
                _.remove(stateValue, key => {
                    let item = self.getItemByKey(key);
                    console.log(key, item);
                    return _.isNil(item) || !item.hasChildren || _.isEmpty(item.children);
                });
                if(self.expandedKeysValue.length === stateValue.length) return;
                self.expandedKeysValue = stateValue;
                self.updatePersistedState(stateValue, false);
            },

            refreshPersistanceKey() {
                const self = this;
                if(!self.persistExpanded) return;

                let keyParts = [];
                if(!_.isNil(self.storageKey)){
                    keyParts.push(self.storageKey);
                }
                else if(!_.isEmpty(self.automation_id)) {
                    keyParts.push(self.automation_id);
                }

                let routeName = _.get(self, "$route.name", null);
                if (!_.isNil(routeName)) {
                    keyParts.push(routeName);
                }

                if(_.isEmpty(keyParts)) return;

                keyParts.unshift("rq-state-data");
                let key = _.join(keyParts, "_");

                //transfer persisted data to new key if new storageKey has been set
                if(!_.isNil(self.storageKeyValue) && key !== self.storageKeyValue) {
                    let persistedData = localStorage.getItem(self.storageKeyValue);
                    localStorage.removeItem(self.storageKeyValue);
                    localStorage.setItem(key, persistedData);
                }

                self.storageKeyValue = key;
            },

            onExpandedKeysValueUpdate(keys) {
                this.applyDefaultExpandedKeys = false;
                this.updatePersistedState((keys || []).slice());
            },

            onSelected(selectedItem) {
                this.boundValue = selectedItem.key;
                this.$emit("item-selected", { selectedItem });
            },

            onPsScrollY: _.debounce(function(e) {
                this.$emit("update:scrollPosition", e.target.scrollTop);
            }, 500)
        }
    };
</script>