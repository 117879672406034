export default {
    props: {
        id: { default:null },
        automation_id: { type: String, default: "" },
        componentKey: { default: null },
        size: { type: String, default: "" },
        cssClass: { type: String, default: "form-control" },
        disabled: { type: Boolean, default: false },
        autocomplete: { type: Boolean, default: false },
        inputGroup: { type: Boolean, default: false },
        prependText: { type: String, default: null },
        appendText: { type: String, default: null },
        prependIcon: { type: String, default: null },
        appendIcon: { type: String, default: null }
    },
    data() {
        const self = this;
        return {
            _componentKey: _.isNil(self.componentKey) ? _.uniqueId(`${_.kebabCase(this.$options.name)}-`) : self.componentKey,
            elementIdAttr: _.isNil(self.id) ? self._componentKey : self.id
        };
    },
    computed: {
        automationId() { return this.automation_id || this.elementIdAttr; }
    }
};