export class FilterItem {
    constructor(options) {
        options = options || {};
        this.id = options.id || 0;
        this.name = options.name || "";
        this.selected = _.parseBool(options.selected);
        this.disabled = _.parseBool(options.disabled);
        this.visible = _.parseBool(options.visible, true);
    }
}

export class FilterCollection {
    constructor(items, propName) {
        items = items || [];
        this.items = _.map(items, item => new FilterItem(item));
        this.propName = propName || "";
    }

    get selectedFilters() { return _.filter(this.items, item => item.selected); }

    get dxFilter() { return this.getDXFilter(); }

    get hasSelected() { return _.some(this.items, item => item.selected); }

    getDXFilter(name=null) {
        let fieldName = name || this.propName;
        return FilterCollection.buildInclusiveDXFilter(fieldName, this.selectedFilters);
    }

    getInverseDXFilter(name=null) {
        let fieldName = name || this.propName;
        //get filter expression for all items
        let filterExpr = FilterCollection.buildInclusiveDXFilter(fieldName, this.items);
        //then return with "NOT" operator
        return ["!", filterExpr];
    }

    clearSelected() { _.forEach(this.items, item => { item.selected = false; }); }

    static buildInclusiveDXFilter(fieldName, filterItems) {
        let filterExpr = [];
        _.forEach(filterItems, (f, i) => {
            filterExpr.push([fieldName, "=", f.id]);
            if(i < filterItems.length - 1) filterExpr.push("or");
        });
        return filterExpr;
    }
}

export const STEP_STATUS = {
    Incomplete: "incomplete",
    Complete: "complete",
    Error: "error",
    Alert: "alert"
};

export class RqStepModel {
    constructor(options, index=null) {
        options = options || {};
        this.index = _.isNil(index)
            ? _.parseNumber(options.index, -1)
            : _.parseNumber(index, -1);
        this.label = options.label || options.title || "(No Label Provided)";
        this.status = options.status || STEP_STATUS.Incomplete;
        this.component = options.component || null;
        this.errorMessage = options.errorMessage || "";
        this.visible = _.isNil(options.visible) ? true : options.visible;
        this.data = options;
    }

    get stepNumber() { return this.index + 1; }

    get isVisible() {
        if(_.isFunction(this.visible)) return this.visible();
        return _.parseBool(this.visible);
    }
}