import ErrorDialog from './ErrorDialog.vue';
import { RQUIExceptionSeverity, RQUIException } from './RQUIException';

const RqError = {
    install: function (app, options) {
        options = options || {};
        const capturedErrors = [];

        const nameFromPath = function(path){
            let pathVals = _.isString(path) ? path.split("/") : [];
            let pathName = "";
            while(pathName.length <= 1 && pathVals.length > 0){
                pathName = pathVals.pop();
            }
            return pathName;
        };

        // app.mixin({
        //     errorCaptured(err, vm, info) {
        //         this.$rqError.capture(err, "An issue occurred while processing your request.", info);
        //         return false;
        //     }
        // });

        Object.defineProperty(app.config.globalProperties, '$rqError', {
            get: function() {
                const self = this;
                const log = (err, msg, info, severity = 0) => {
                    let errText = `[${info}] - ${msg}`;
                    if(!self.$log) {
                        console.error(errText, err);
                    }
                    else if (severity === RQUIExceptionSeverity.fatal) {
                        self.$log.fatal(`[${info}] - ${msg}`, err);
                    }
                    else{
                        app.$log.error(`[${info}] - ${msg}`, err);
                    }
                };

                const throwError = (options) => {
                    let rqEx = options.rqException || new RQUIException();
                    const propsDefaults = {
                        title: "Application Error",
                        message: rqEx.message,
                        info: rqEx.info,
                        error: rqEx.error,
                        dismissable: rqEx.severity !== rqEx.fatal,
                        reloadable: true,
                        showDetails: true
                    };
                    const dialogArgs = options.dialogProps
                        ? _.merge({}, propsDefaults, options.dialogProps)
                        : propsDefaults;

                    self.$dialog.open({
                        title: dialogArgs.title,
                        dialogStyle: "modal-danger",
                        height: 400,
                        width: 600,
                        adaptive: true,
                        component: ErrorDialog,
                        args: dialogArgs,
                        hideHeaderClose: !dialogArgs.dismissable,
                        hideFooter: !dialogArgs.dismissable && !dialogArgs.reloadable
                    });

                    //route to error page
                    //self.$router.push({ name: "error", params: { error: rqException.error, message: rqException.message, info: rqException.info } });
                };

                const capture = (err, msg, info, severity = 0, forceThrow = false) => {
                    log(err, msg, info, severity);

                    let componentName = self.$options.name;
                    let currentRoute = self.$route;
                    if (!componentName && currentRoute){
                        if (currentRoute.name)
                            componentName = currentRoute.name;
                        else if (currentRoute.path)
                            componentName = nameFromPath(currentRoute.path);
                    }
                    let rqException = new RQUIException({
                        componentName: componentName,
                        route: currentRoute,
                        error: err,
                        info: info,
                        message: msg,
                        severity: severity
                    });

                    capturedErrors.push(rqException);

                    //let environment = _.get((process || {}), "env.NODE_ENV", "development");
                    // if (forceThrow || options.overrideDevMode || environment !== "development")
                    if (!forceThrow) return;
                    throwError({rqException});
                };

                const capturedErrors = (global=false) => {
                    let currentRoute = self.$route;
                    const currentComponentName = self.$options.name || currentRoute.name;
                    return global ? capturedErrors:_.filter(capturedErrors, ex => ex.componentName === currentComponentName);
                };

                const scopeHasErrors = (clearScopeErrorLog = false, outputToConsole = false) => {
                    const currentComponentName = self.$options.name || self.$route.name;
                    const hasCapturedErrors = _.some(capturedErrors, ex => ex.componentName === currentComponentName);

                    if (outputToConsole) self.$rqError.writeToConsole();
                    if (clearScopeErrorLog) self.$rqError.clear();

                    return hasCapturedErrors;
                };

                const writeToConsole = () => {
                    console.table(capturedErrors);
                };

                const clear = (global=false) => {
                    const currentComponentName = self.$options.name || self.$route.name;
                    let capturedCount = capturedErrors.length;
                    if (global) {
                        capturedErrors.length = 0;
                    } else {
                        capturedCount = _.sumBy(capturedErrors, ex => ex.componentName === currentComponentName);
                        _.remove(capturedErrors, ex => ex.componentName === currentComponentName);
                    }
                    return capturedCount;
                };

                return {
                    log,
                    capture,
                    throw: throwError,
                    capturedErrors,
                    scopeHasErrors,
                    writeToConsole,
                    clear,
                    clearAll: () => clear(true)
                };
            }
        });
    }
};

export default RqError;