<template>
    <div :id="elementIdAttr"
        :class="{ 'rq-range-input rq-number-range': true, 'rq-show-clear-button': clearButtonVisible }">
        <div class="row">
            <div class="col form-group mb-0">
                <rq-input-number
                    ref="rqInputNumber1"
                    :automation_id="`${automationId}_start`"
                    :size="size"
                    :format-type="formatType"
                    :prefix="prefix"
                    :suffix="suffix"
                    :decimals="decimals"
                    :commas="commas"
                    :no-prefix="noPrefix"
                    :validator="validator"
                    :allow-null="allowNulls"
                    :max-chars="maxChars"
                    :readonly="readonly"
                    :text-align="textAlign"
                    :tab-index="tabIndex"
                    :no-form-control-class="noFormControlClass"
                    :prepend-text="prependText"
                    :prepend-icon="prependIcon"
                    :input-group="inputGroups"
                    :disabled="disabled || startValueDisabled"
                    :default-value="defaultValue"
                    :show-clear-button="showClearButtons"
                    v-model="startNumberValue"
                />
            </div>
            <div class="col-auto rq-range-delimiter">
                <slot name="delimiter">
                    {{delimiterText}}
                </slot>
            </div>
            <div class="col form-group mb-0">
                <rq-input-number
                    ref="rqInputNumber2"
                    :automation_id="`${automationId}_end`"
                    :size="size"
                    :format-type="formatType"
                    :prefix="prefix"
                    :suffix="suffix"
                    :decimals="decimals"
                    :commas="commas"
                    :no-prefix="noPrefix"
                    :validator="validator"
                    :allow-null="allowNulls"
                    :max-chars="maxChars"
                    :readonly="readonly"
                    :text-align="textAlign"
                    :tab-index="tabIndex"
                    :no-form-control-class="noFormControlClass"
                    :prepend-text="prependText"
                    :prepend-icon="prependIcon"
                    :input-group="inputGroups"
                    :disabled="disabled || endValueDisabled"
                    :default-value="defaultValue"
                    :show-clear-button="showClearButtons"
                    v-model="endNumberValue"
                />
            </div>
        </div>
        <rq-icon-button
            v-if="showClearAllButton"
            v-show="clearButtonVisible"
            icon="fas fa-times-circle"
            class="rq-clear-value"
            title="Clear Values"
            v-rq-tooltip.hover.top
            @click="clear"
        />
    </div>
</template>

<script>
    //due to weird logic conflicts, things have been simplified so that anything other than "never" behaves as "always"
    const MATCH_VALUE = { never: "never", beforeClear: "before-clear", always: "always" };
    const MATCH_FIELD = { both: "both", start: "start", end: "end" };

    export default {
        name: "RqNumberRange",
        props: {
            id: { type: String, default: "" },
            automation_id: { type: String, default: "" },
            startValue: { type: Number, default: null },
            endValue: { type: Number, default: null },
            disabled: { type: Boolean, default: false },
            startValueDisabled: { type: Boolean, default: false },
            endValueDisabled: { type: Boolean, default: false },
            formatType: { type: String, default: "number" },
            size: { type: String, default: "" },
            prefix: { type: String, default: "" },
            suffix: { type: String, default: null },
            decimals: { type: Number, default: 2 },
            commas: { type: Boolean, default: true },
            inputGroups: { type: Boolean, default: false },
            prependText: { type: String, default: "" },
            prependIcon: { type: String, default: "" },
            validator: { type: Object, default: null },
            allowNulls: { type: Boolean, default: false },
            maxValue: { type: Number, default: null },
            minValue: { type: Number, default: null },
            maxChars: { type: Number, default: null },
            noPrefix: { type: Boolean, default: false },
            readonly: { type: Boolean, default: false },
            noFormControlClass: { type: Boolean, default: false },
            textAlign: { type: String, default: null },
            tabIndex: { type: Number, default: null },
            negativeRange: { type: Boolean, default: false },
            showClearButtons: { type: Boolean, default: false },
            showClearAllButton: { type: Boolean, default: false },
            delimiterText: { type: String, default: "to" },
            matchValue: { type: String, default: MATCH_VALUE.never },
            matchField: { type: String, default: MATCH_FIELD.both }
        },

        data () {
            const self = this;
            return {
                pauseMatchValue: false,
                matchEmptyValue: self.matchValue !== MATCH_VALUE.never
            };
        },

        computed: {
            automationId() { return this.automation_id || _.uniqueId("txt_number_range_"); },
            elementIdAttr() { return _.isNil(this.id) ? this.automationId : this.id; },
            defaultValue() { return this.allowNulls ? null : 0; },
            startNumberValue: {
                get() { return this.startValue; },
                set(val) { this.$emit("update:startValue", val); }
            },
            endNumberValue: {
                get() { return this.endValue; },
                set(val) { this.$emit("update:endValue", val); }
            },
            hasValue() { return this.hasStartValue || this.hasEndValue; },
            allowMatchValue() { return this.matchValue !== MATCH_VALUE.never; },
            hasStartValue() { return this.startNumberValue !== this.defaultValue; },
            hasEndValue() { return this.endNumberValue !== this.defaultValue; },
            clearButtonVisible() { return !this.disabled && this.hasValue && this.showClearAllButton; }
        },
        watch: {
            startValue() {
                this.$nextTick(() => {
                    this.matchInvalidValue("start");
                });
            },
            endValue() {
                this.$nextTick(() => {
                    this.matchInvalidValue("end");
                });
            }
        },
        mounted() {
            if(this.allowNulls) return;
            //force defaults if "allowNulls" is "false' to prevent initial values from remaining null if untouched
            this.pauseMatchValue = true;
            if(_.isNil(this.startNumberValue)) this.startNumberValue = this.defaultValue;
            if(_.isNil(this.endNumberValue)) this.endNumberValue = this.defaultValue;
            this.$nextTick(() => {
                this.pauseMatchValue = false;
            });
        },
        methods: {
            matchInvalidValue(targetName) {
                if(this.pauseMatchValue || !this.allowMatchValue) return;

                let targetValue = this[`${targetName}NumberValue`];

                if(_.isNil(targetValue) && this.allowNulls) {
                    this.matchEmptyValue = !this.hasValue;
                    return;
                }

                let isLargerValue = (targetName === "start") === this.negativeRange;
                let otherName = targetName === "start" ? "end" : "start";
                let otherValue = this[`${otherName}NumberValue`];
                let otherHasValue = otherValue !== this.defaultValue;

                //check if the new value falls outside the required range
                let isValid = !otherHasValue
                    || (isLargerValue
                        ? targetValue >= otherValue
                        : targetValue <= otherValue);

                let matchEmptyValue = this.matchEmptyValue
                    && this.allowMatchFieldValue(otherName);

                //check if we should match the other value if the new value is invalid or empty
                if(isValid && (otherHasValue || !matchEmptyValue)) return;

                this[`${otherName}NumberValue`] = targetValue;

                if(!matchEmptyValue || !this.matchEmptyValue) return;

                this.matchEmptyValue = false;
            },
            focus() {
                if(this.disabled) return;
                if(!this.startDisabled) {
                    _.invoke(this, "$refs.rqInputNumber1.focus");
                }
                else if(!this.endDisabled) {
                    _.invoke(this, "$refs.rqInputNumber2.focus");
                }
            },
            clear() {
                if(this.disabled || this.readonly || !this.hasValue) return;
                this.pauseMatchValue = true;
                if(!this.startDisabled) this.startNumberValue = this.defaultValue;
                if(!this.endDisabled) this.endNumberValue = this.defaultValue;
                this.focus();
                this.$nextTick(() => {
                    this.pauseMatchValue = false;
                });
            },
            allowMatchFieldValue(targetName) {
                return !this.isFieldDisabled(targetName)
                    && (this.matchField === MATCH_FIELD.both
                        || this.matchField === MATCH_FIELD[targetName]);
            },
            isFieldDisabled(name) {
                return this[`${name}ValueDisabed`];
            }
        }
    }
</script>
