<template>
    <PromptInputFormGroup
        :label="label"
        :label-for="idAttrs.id"
        :is-required="isRequired"
        :show-banner="showBanner"
        :has-error="hasError"
        :validation-messages="validationMessages"
        :alerts="alerts">
        <textarea v-if="multiline"
            :id="idAttrs.id"
            :automation_id="idAttrs.automation_id"
            class="form-control"
            v-auto-resize="{ maxHeight }"
            v-model="inputValue"
            v-focus
            :disabled="disabled">
        </textarea>
        <rq-input-number v-else-if="inputType === 'number'"
            :id="idAttrs.id"
            :automation_id="idAttrs.automation_id"
            v-bind="editorOptions"
            v-model="inputValue"
            @keydown.enter="$emit('ok')"
            v-focus.input
            :disabled="disabled"
        />
        <input v-else
            :id="idAttrs.id"
            :automation_id="idAttrs.automation_id"
            type="text"
            class="form-control"
            v-model="inputValue"
            @keydown.enter="$emit('ok')"
            v-focus
            :disabled="disabled"
        >
    </PromptInputFormGroup>
</template>
<script setup>
    import { computed } from "vue";
    import { usePromptInputProps, usePromptInput } from "./composables";
    import PromptInputFormGroup from "./PromptInputFormGroup.vue";

    const props = defineProps({
        ...usePromptInputProps("txt_prompt_input"),
        multiline: Boolean,
        maxInputHeight: Number,
        inputType: { type: String, default: "text" },
        editorOptions: { type: Object, default: () => ({}) }
    });

    const {
        idAttrs,
        inputValue,
        showBanner,
        hasError,
        validationMessages,
        isValid
    } = usePromptInput(props);

    const maxHeight = computed(() => props.multiline && _.parseNumber(props.maxInputHeight, 0) > 0 ? props.maxInputHeight : null);

    defineExpose({ isValid, inputValue });
</script>