<template>
    <input
        ref="fileInput"
        :class="{
            'form-control': !hidden,
            'd-none': hidden
        }"
        type="file"
        :name="name"
        :accept="accept"
        :multiple="multiple"
        :disabled="disabled"
        @change="onFileInputChange"
    >
</template>

<script setup>
    import { computed, ref, unref, watch } from 'vue';

    const props = defineProps({
        name: { type: String, default: "files" },
        file: { type: File, default: null },
        fileList: { type: FileList, default: null },
        accept: { type: String, default: ".*" },
        multiple: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        hidden: { type: Boolean, default: false },
        clearBeforeChange: { type: Boolean, default: true },
        clearAfterChange: { type: Boolean, default: null }
    });

    const emit = defineEmits(["change", "update:file", "update:fileList"]);

    const fileInput = ref(null);
    const selectedFiles = ref(null);

    const fileProp = computed(() => props.file);
    const fileListProp = computed(() => props.fileList);

    const clearWhenNull = val => {
        if(!_.isEmpty(val) || _.isNil(selectedFiles.value)) return;
        clear();
        emitValues();
    };

    const clearAfterChangeEvent = computed(() => _.parseBool(props.clearAfterChange) || (_.isNil(props.clearAfterChange) && props.hidden));

    watch(fileProp, clearWhenNull);
    watch(fileListProp, clearWhenNull);

    function onFileInputChange(e) {
        let files = e.target?.files;
        selectedFiles.value = files?.length > 0
            ? files
            : null;
        emitValues();
        emit("change", e);
        if(!clearAfterChangeEvent.value) return;
        clear();
    }

    function emitValues() {
        let files = unref(selectedFiles);
        emit("update:fileList", files || null);
        if(props.multiple) return;
        emit("update:file", files?.[0] || null);
    }

    function clear() {
        selectedFiles.value = null;
        emitValues();
        if(!fileInput.value) return;
        fileInput.value.value = "";
    }

    function click() {
        if(props.clearBeforeChange) clear();
        fileInput?.value?.click();
    }

    defineExpose({
        selectedFiles,
        clear,
        click,
    });
</script>