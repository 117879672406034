import * as components from "./components";
import RqError from "./RqError";
import { RQDialogButton, RQDialogDimensions, RQDialogOptions, RQ_DIALOG_EVENTS } from "./components/dialog/models";
import { RQUIExceptionSeverity, RQUIException } from "./RqError/RQUIException";
import GlobalEventNames from "./GlobalEventNames";

const RqUi = {
    install(app) {
        app.config.globalProperties.$rqui = { GlobalEventNames };

        // Register components
        Object.entries(components).forEach(([componentName, component]) => {
            app.component(componentName, component);
        });
    }
};

export {
    RqUi,
    RQ_DIALOG_EVENTS,
    RQDialogOptions,
    RQDialogButton,
    RQDialogDimensions,
    RqError,
    RQUIExceptionSeverity,
    RQUIException
};
