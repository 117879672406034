<template>
    <div :class="tvLayoutClassAttr">
        <div class="rq-layout-tree">
            <slot name="tree"></slot>
        </div>
        <div v-if="handleVisible"
            class="rq-minimize-handle"
            @click="sidebarMinimized = !sidebarMinimized">
            <FontAwesomeIcon :icon="caretIcon" />
        </div>
        <div class="rq-layout-form">
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "RqTreeviewLayout",
        props: {
            flyoutEnabled: { type: Boolean, default: true },
            minimized: { type: Boolean, default: false }
        },
        data () {
            return {
                sidebarMinimized: false,
                handleVisible: true
            };
        },
        computed: {
            tvLayoutClassAttr() {
                return {
                    "rq-tv-layout rq-tv-flyout": true,
                    //"rq-tv-flyout": this.flyout,
                    "rq-tree-minimized": this.sidebarMinimized
                };
            },
            caretIcon() {
                return _.evalCssObject({
                    "fas":true,
                    "fa-caret-right": this.sidebarMinimized,
                    "fa-caret-left": !this.sidebarMinimized
                });
            }
        },
        watch: {
            flyoutEnabled: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.handleVisible) return;
                    this.handleVisible = _.parseBool(newValue);
                },
                immediate: true
            },
            minimized: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.sidebarMinimized) return;
                    this.sidebarMinimized = _.parseBool(newValue);
                },
                immediate: true
            },
            sidebarMinimized(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.minimized) return;
                this.$emit("update:minimized", newValue);
            }
        }
    }
</script>