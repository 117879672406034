﻿<template>
    <div :id="componentId" :class="{ 'invalid-feedback-icon':true, 'invalid-feedback-icon-sm':small, 'no-field-label':noFieldLabel }" :style="styleAttr">
        <span 
            @mouseover="onIconMouseover" 
            @mouseout="onIconMouseout" 
            @click="onIconClick">
            <FontAwesomeIcon 
                icon="far fa-exclamation-circle" 
            />
        </span>
        <DxPopover
            v-if="!iconOnly && showMessages"
            :target="`#${componentId}`"
            :container="popoverContainer"
            :position="placement"
            triggers=""
            :wrapper-attr="{
                class: 'rq-popover rq-popover-error invalid-feedback-popover'
            }"
            :visible="true">
            <slot>
                <div v-for="(msg,idx) in messageList" :key="idx" v-html="msg" />
            </slot>
        </DxPopover>
    </div>
</template>

<script>
    import { DxPopover } from "devextreme-vue";
    export default {
        name: "RqValidationFeedback",
        components: { DxPopover },
        props: {
            message:  { type: String, default: null },
            messages:  { type: [String, Object, Array], default: null },
            container: { type: [String, HTMLElement, Object], default: null },
            boundary: { type: [String, HTMLElement, Object], default: null },
            placement: { type: String, default: "top" },
            iconOnly: { type: Boolean, default: false },
            noFieldLabel: { type: Boolean, default: false },
            offset: { type: Number, default: null },
            small: { type: Boolean, default: false }
        },

        inject: {
            dialogId: {
                from: "dialogId",
                default: null
            }
        },

        data() {
            return {
                componentId: _.uniqueId("rq-validation-feedback-"),
                showMessages: false,
                delayId: null
            }
        },

        computed: {
            messageList() {
                if(_.isEmpty(this.messages)) return _.isEmpty(this.message) ? [] : [this.message];
                if(_.isArray(this.messages)) return this.messages;
                if(_.isObject(this.messages)) return _.keys(_.pickBy(this.messages));
                return [this.messages];
            },
            hasMessages() { return !_.isEmpty(this.messageList); },
            styleAttr() { return _.parseNumber(this.offset,0) !== 0 ? `transform:translateX(${this.offset*-1}px);` : ""; },
            popoverContainer() { return _.isNil(this.container) && !_.isEmpty(this.dialogId) ? `#${this.dialogId}` : this.container; },
            popoverBoundary() { return _.isNil(this.bonundary) && !_.isEmpty(this.dialogId) ? `#${this.dialogId}` : this.boundary; }
        },

        methods: {
            onIconClick(e) { this.setShowMessages(!this.showMessages || _.isNil(this.delayId), true); },
            onIconMouseover(e) {
                if(!this.hasAnyMessage()) return;
                this.setShowMessages(true);
            },
            onIconMouseout(e) {
                if(!this.hasAnyMessage() || !this.showMessages) return;
                this.setShowMessages(false);
            },
            hasAnyMessage() {
                return this.hasMessages
                    || !_.slotIsEmpty(this, "default")
            },
            setShowMessages(val, delayClose=false) {
                const self = this;
                if(!_.isNil(self.delayId)) {
                    clearTimeout(self.delayId);
                }
                self.showMessages = val;
                if(val && delayClose) {
                    self.delayId = _.delay(function() {
                        self.delayId = null;
                        self.showMessages = false;
                    }, 3000);
                }
            },
        }
    }
</script>
