<template>
    <div :class="classAttr">
        <ul :class="navClassAttr">
            <slot name="tabs-start"></slot>
            <li
                v-for="(tabItem, index) in visibleTabs"
                :id="tabItem.clientId"
                :key="tabItem.clientId"
                class="nav-item">
                <RqTooltip
                    v-if="tabItem.tooltip"
                    :target="`#${tabItem.clientId}`"
                    :title="tabItem.tooltip"
                    :disabled="tabItem.popoverVisible">{{tabItem.tooltip}}
                </RqTooltip>
                <button
                    :automation_id="tabItem.automation_id"
                    type="button"
                    class="nav-link rq-tab"
                    :class="{
                        [`tab-alert tab-alert-${tabItem.alertVariant}`]: tabItem.alertCount > 0,
                        'active': index === activeIndex,

                    }"
                    :disabled="tabItem.disabled"
                    @click="onActivateTab(index)">
                    <span class="tab-title">
                        <slot :name="`${tabItem.name}-title`">
                            <span v-html="tabItem.title" />
                        </slot>
                        <RqTabAlertIcon
                            :automation_id="`${tabItem.automation_id}_alert_icon`"
                            :count="tabItem.alertCount"
                            :variant="tabItem.alertVariant"
                            :messages="tabItem.alertMessages"
                            :tab-active="index === activeIndex"
                            v-model:visible="tabItem.popoverVisible"
                        />
                        <FontAwesomeIcon
                            v-if="!tabItem.disabled && tabItem.isLoading"
                            icon="fad fa-loader"
                            class="tab-spinner ms-2"
                            spin-pulse
                        />
                        <span
                            v-else-if="!tabItem.disabled && tabItem.showClose && visibleTabCount > 0"
                            tabindex="-1"
                            class="tab-close ms-2"
                            title="Close Tab"
                            @keyup.enter="onCloseTab(tabItem, $event)"
                            @click="onCloseTab(tabItem, $event)"
                            v-rq-tooltip.top.hover>
                            <FontAwesomeIcon icon="fas fa-times" />
                        </span>
                    </span>
                </button>
            </li>
            <slot name="tabs-end"></slot>
        </ul>
        <div ref="tabContentElement" class="tab-content">
            <template
                v-for="(tabItem, index) in visibleTabs"
                :key="`rq-tab-pane-${index}`">
                <div
                    v-if="index === activeIndex || activatedIndexes.includes(index) || !tabItem.lazy"
                    class="tab-pane"
                    :class="{
                        'active': index === activeIndex,
                        [tabPaneClass]: tabPaneClass,
                        [tabItem.tabClass]:  tabItem.tabClass
                    }">
                    <slot :name="tabItem.name"></slot>
                </div>
            </template>
            <rq-back-to-top-button v-if="scrollable" />
        </div>
    </div>
</template>
<script setup>
    import { computed, nextTick, ref, watch } from 'vue';
    import RqTabAlertIcon from "./RqTabAlertIcon.vue";
    /*
        tabs []
            automation_id?: string, default: "btn_tab_<title:snake_cased>"
            title: string
            name?: string, default: "<title:kebab-case>" (used for tab/title slot name)
            lazy?: bool, default: false
            disabled?: bool, default: false
            visible?: bool, default: true
            alertCount?: number, default: 0
            alertVariant?: string, default: "error"
            alertMessages?: array, default: [],
            tabClass?: string, default: "",
            isLoading?: bool, default: false,
            showClose?: bool, default: false
    */
    const props = defineProps({
        modelValue: { type: Number, default: 0 },
        tabs: { type: Array, default: () => [] },
        tabPaneClass: { type: String, default: "" },
        navClass: { type: String, default: ""},
        flex: { type: Boolean, default: true },
        fill: { type: Boolean, default: false },
        pills: { type: Boolean, default: false },
        scrollable: { type: Boolean, default: false }
    });
    const emit = defineEmits(["before-activate-tab", "activate-tab", "close-tab", "changed", "update:modelValue"]);

    const tabContentElement = ref(null);
    const activeIndex = ref(props.modelValue);
    const activatedIndexes = ref([props.modelValue]);

    const valueProp = computed(() => props.modelValue);

    const classAttr = computed(() => ({
        "tabs rq-tabs": true,
        "rq-tabs-flex": props.flex,
        "rq-tabs-scrollable": props.scrollable,
    }));

    const navClassAttr = computed(() => ({
        "nav": true,
        "nav-tabs rq-nav-tabs": !props.pills,
        "nav-pills": props.pills,
        "nav-fill": props.fill,
        [props.navClass]: props.navClass
    }));

    const tabDefaults = {
        title: "No Title",
        lazy: false,
        visible: true,
        disabled: false,
        alertCount: 0,
        alertVariant: "error",
        alertMessages: [],
        popoverVisible: false,
        tabClass: "",
        isLoading: false,
        showClose: false
    };
    const tabItems = computed(() =>
         _.map(props.tabs, (tab, index) => ({
            clientId: `rq-nav-tab-${index}`,
            automation_id: `btn_tab_${_.toLower(_.snakeCase(tab.title))}`,
            name: `${_.toLower(_.kebabCase(tab.title))}`,
            ...tabDefaults,
            ...tab
        }))
    );
    const visibleTabs = computed(() => _.filter(tabItems.value, "visible"));
    const visibleTabCount = computed(() => visibleTabs.value.length);
    const lastIndex = computed(() => visibleTabCount.value - 1);

    watch(valueProp, (newVal, oldVal) => {
        if(newVal === oldVal || newVal === activeIndex.value) return;
        activeIndex.value = newVal;
    });
    watch(activeIndex, (newVal, oldVal) => {
        if(newVal === oldVal || newVal === valueProp.value) return;
        emit("update:modelValue", newVal);
    });
    watch(tabItems, (newVal, oldVal) => {
        if(newVal?.length === oldVal?.length) return;
        emit("changed", { current: newVal, previous: oldVal });
    });

    function onCloseTab(tab, e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if(activeIndex.value === lastIndex.value) activeIndex.value--;
        emit("close-tab", { tab });
    }

    async function onActivateTab(index) {
        let abort = await beforeActivateTab(index);
        if(abort) return;
        await activateTab(index);
    }

    async function beforeActivateTab(index) {
        let prevIndex = activeIndex.value;
        let abortActivation = false;
        emit("before-activate-tab", {
            index,
            prevIndex,
            tab: { ...tabItems.value[index] },
            abort: () => {
                abortActivation = true;
            }
        });
        await nextTick();
        return abortActivation;
    }

    async function activateTab(index) {
        if(props.scrollable) {
            tabContentElement.value.scrollTop = 0;
        }
        let prevIndex = activeIndex.value;
        activeIndex.value = index;
        await nextTick();
        emit("activate-tab", {
            index,
            prevIndex,
            tab: { ...tabItems.value[index] },
        });
        if(_.includes(activatedIndexes.value, index)) return;
        activatedIndexes.value.push(index);
    }

    function resetLazyLoadStates() {
        activatedIndexes.value = [];
        activateTab(0);
    }

    defineExpose({
        resetLazyLoadStates
    });
</script>