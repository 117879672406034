
class RqAction {
    constructor(options) {
        const opts = options || {};
        this.key = opts.key || _.uniqueId("rq-action-");
        this.automation_id = opts.automation_id || `${this.key}_automation_id`;
        this.id = opts.id || opts.automation_id || this.key;
        this.name = opts.name || "";
        this.icon = opts.icon || "";
        this.text = opts.text || "";
        this.tooltip = opts.tooltip || "";
        this.eventName = opts.eventName || "";
        this.permissionKeys = opts.permissionKeys || [];
        this.accessKey = opts.accessKey || "";
        this.shortcutKeys = opts.shortcutKeys || [];
        this.disableWhenReadOnly = _.parseBool(opts.disableWhenReadOnly);
        this._children = opts.children || [];
    }

    get children() { return _.map(this._children, c => new RqAction(c)); }
}

export default class GridAction extends RqAction {
    constructor(options) {
        super(options);
        this.isToolbarAction = _.parseBool(options.isToolbarAction);
        this.isToolbarSetting = _.parseBool(options.isToolbarAction);
        this.requireMultiSelection = !this.isToolbarAction && _.parseBool(options.requireMultiSelection);
        this.allowMultiSelection = !this.isToolbarAction && (this.requireMultiSelection || _.parseBool(options.allowMultiSelection));
        this.requireSelection = !this.isToolbarAction && (this.allowMultiSelection || _.parseBool(options.requireSelection));
        this.onClick = _.isFunction(options.onClick) ? options.onClick : null;
        this.disabled = _.isNil(options.disabled) ? false : options.disabled;
        this.visible = _.isFunction(options.visible) ? options.visible : _.parseBool(options.visible, true);
        this._defaultDisabledTooltip = options.disabledTooltip || "";
        this._currentDisabledTooltip = this._defaultDisabledTooltip;
        this._isDisabled = _.isBoolean(options.disabled) ? options.disabled : false;
    }

    get disabledTooltip() { return this._isDisabled ? this._currentDisabledTooltip : ""; }
    set disabledTooltip(val) { this._currentDisabledTooltip = this._isDisabled ? val || this._defaultDisabledTooltip : ""; }

    isDisabled(selectedItems=[]) {
        let result = false;
        let tooltip = "";
        if(_.isFunction(this.disabled)) {
            let args = this.createEventPayload(selectedItems);
            let disabledResult = this.disabled(args);
            if(_.isBoolean(disabledResult)) {
                result = disabledResult;
            }
            else if(_.isString(disabledResult) && !_.isEmpty(_.trim(disabledResult))) {
                result = true;
                tooltip = disabledResult;
            }
        }
        else {
            result = _.parseBool(this.disabled);
            if(result) tooltip = this._defaultDisabledTooltip;
        }
        this._currentDisabledTooltip = tooltip;
        this._isDisabled = result;
        return result;
    }

    isVisible(selectedItems=[]) {
        if(_.isFunction(this.visible)) {
            let args = this.createEventPayload(selectedItems);
            return this.visible(args);
        }
        return this.visible;
    }

    getTooltip(selectedItems=[]) {
        let toolTip = "";
        if(_.isFunction(this.tooltip)) {
            let args = this.createEventPayload(selectedItems);
            toolTip = this.tooltip(args);
        }
        else {
            toolTip = this.tooltip;
        }
        return toolTip;
    }

    createEventPayload(selectedItems=[]) {
        let selected = selectedItems.slice();
        return {
            action: _.toPlainObject(this),
            data: this.requireSelection && !this.allowMultiSelection
                ? selected[0]
                : selected
        };
    }

    get children() { return _.map(this._children, c => new GridAction(c)); }

    get childRequiresSelection() { return !_.isEmpty(this.children) && _.some(this.children, c => c.requireSelection || c.childRequiresSelection); }
}