<template>
    <div
        :id="id"
        :class="{
            'rq-img-preview':true,
            'no-preview':!imageExists,
            'rq-hover': showHoverState,
            'invalid-image': invalidImage
        }"
        @mouseover="showHoverState=true"
        @mouseout="showHoverState=false"
        @click="showHoverState=false">

        <div class="rq-img-header">
            <span v-if="showLabel">{{label}}</span>
            <div v-if="showAction" class="rq-img-actions">
                <b-btn :automation_id="loadButtonId" variant="link" class="btn-theme" size="sm" @click="onLoadClick">Load Image</b-btn>
                <b-btn :automation_id="deleteButtonId" variant="link" class="btn-theme" size="sm" @click="onDeleteClick">Remove Image</b-btn>
            </div>
        </div>
        <div class="rq-img-body">
            <form enctype="multipart/form-data">
                <div class="img-preview" v-show="imageExists">
                    <img :src="imageData.dataUrl" title="Logo" :style="imageStyle" />
                </div>
                <div class="upload-guide">
                    <p v-show="!isBusy">Drag an image here <br> or click to browse</p>
                    <p v-show="invalidImage">{{invalidMessage}}</p>
                </div>
                <input
                    ref="imgFileInput"
                    :automation_id="fileInputId"
                    type="file"
                    name="CompanyLogo"
                    :disabled="isBusy"
                    @change="onFileChange($event)"
                    :accept="acceptedAttr"
                    class="input-file"
                />
            </form>
        </div>
    </div>
</template>

<script>
    import { ref, watchEffect } from "vue";
    class ImageDataModel {
        constructor (options) {
            options = options || {};
            this.dataUrl = options.dataUrl || null;
            this.height = options.height || 0;
            this.width = options.width || 0;
            this.imageFormat = options.imageFormat || null;
        }

        toDataObject() { return _.toPlainObject(this); }
    }

    export default {
        name: "RqImagePreview",
        props: {
            id: { type: String, default: "" },
            label: { type: String, default: "" },
            modelValue: { type: Object, default: () => { return {}; } },
            acceptedFormats: { type: Array, default: () => (["gif","png","jpeg"]) },
            maxSize: { type: Number, default: 2 }, //max size in MB
            showAction: { type: Boolean, default: true }
        },
        inject: {
            imageInfoCallback:{
                from: "imageInfoCallback",
                default: () => ({onImageChanged: (message)=>{console.log(message)}})
            }
        },
        setup(props) {
            const imgFileInput = ref(null);
            const loadButtonId = ref("");
            const deleteButtonId = ref("");
            const fileInputId = ref("");
            watchEffect(() => {
                let prefix = _.isEmpty(props.id) ? "rq_image_preview" : props.id;
                loadButtonId.value = `${prefix}_load_image`;
                deleteButtonId.value = `${prefix}_delete_image`;
                fileInputId.value = `${prefix}_file_input`;
            });
            return {
                imgFileInput,
                loadButtonId,
                deleteButtonId,
                fileInputId
            }
        },
        data () {
            return {
                imageData: new ImageDataModel(),
                originalImageData: {},
                currentFormData: null,
                imagePreviewVisible: false,
                imageScale: 1,
                showHoverState: false,
                invalidImage: false,
                invalidMessage: ""
            };
        },
        computed: {
            showLabel() { return !_.isEmpty(this.label); },
            imageStyle() { return `transform:scale(${this.imageScale});`; },
            imageExists () { return !_.isEmpty(this.imageData) && !_.isEmpty(this.imageData.dataUrl); },
            imageChanged() { return !this.compareImageData(this.imageData, this.originalImageData); },
            isBusy() { return this.$rqBusy.isBusy(); },
            acceptedTypes() { return _.map(this.acceptedFormats, f => `image/${f}`); },
            acceptedAttr() { return _.isEmpty(this.acceptedTypes) ? "image/*" : _.join(this.acceptedTypes, ","); }
        },
        watch: {
            modelValue: {
                handler(newVal, oldVal) {
                    const self = this;
                    if(this.compareImageData(newVal, oldVal) || this.compareImageData(newVal, self.imageData)) return;
                    self.imageData = new ImageDataModel(newVal);
                    self.originalImageData = new ImageDataModel(newVal);
                    let fileInput = _.get(self, "$refs.imgFileInput", null);
                    if(!fileInput) return;
                    fileInput.value = "";
                },
                deep: true,
                immediate: true
            },
            imageData: {
                handler(newVal, oldVal) {
                    const self = this;
                    if(this.compareImageData(newVal, oldVal) || this.compareImageData(newVal, this.modelValue)) return;
                    self.$emit("update:modelValue", newVal);
                },
                deep: true
            }
        },
        methods: {
            compareImageData(obj1, obj2) {
                return _.get(obj1, "dataUrl", null) === _.get(obj2, "dataUrl", null);
            },

            onFileChange (e) {
                const self = this;
                let fileInput = e.target;

                //reset everything 
                self.invalidImage = false;
                self.invalidMessage = "";
                self.imageData = new ImageDataModel();

                if(!_.isEmpty(self.acceptedFormats)) {
                    let validFileTypes = _.map(self.acceptedFormats, f => `image/${f}`);
                    if(!_.includes(validFileTypes, fileInput.files[0].type)) {
                        e.preventDefault();
                        self.invalidImage = true;
                        self.invalidMessage = `Accepted image formats are ${_.listWords(self.acceptedFormats)}`;
                        self.imageInfoCallback.onImageChanged({validImage:false, message:self.invalidMessage});
                        return;
                    }
                }

                let totalFileSize = fileInput.files[0].size / 1024 / 1024; //size in MB
                if(totalFileSize > self.maxSize){
                    let msg = `File size exceeds ${self.maxSize}MB`;
                    let callbackMessage = `There is an issue with uploading this file. The file size has exceeded the ${self.maxSize}MB image size limit.`
                    self.invalidImage = true;
                    self.invalidMessage = msg;
                    self.imageInfoCallback.onImageChanged({validImage:false, message:callbackMessage});
                    return;
                }

                let formData = new FormData();
                formData.append(fileInput.name, fileInput.files[0], fileInput.files[0].name);
                self.currentFormData = formData;

                let reader = new FileReader();
                reader.onload = function(e2) {
                    self.imageData = new ImageDataModel({
                        dataUrl: e2.target.result
                    });
                };
                reader.readAsDataURL(fileInput.files[0]);
                self.$emit("change", { data: self.imageData.toDataObject() });
                self.imageInfoCallback.onImageChanged({validImage:true, message:"Image is valid"});
            },

            onLoadClick() {
                let fileInput = _.get(this, "$refs.imgFileInput", null)
                if(!fileInput) return;
                fileInput.click();
            },

            onDeleteClick () {
                const self = this;
                self.$dialog.confirm("Delete Image","Are you sure you want to remove this image?", () => {
                    let fileInput = _.get(this, "$refs.imgFileInput", null)
                    if(!fileInput) return;
                    fileInput.value = "";
                    self.imageData = new ImageDataModel();
                    self.$emit("delete");
                });
            },

            onFailure(error, action){
                const self = this;
                console.error(error);
                self.$toast.error(`Image ${action} failed.`);
            },

            refreshOriginal() {
                this.originalImageData = new ImageDataModel(this.imageData);
            },

            //EXPERIMENTAL - need to determine viability or scenario with which this can be used
            getFileBinary() {
                let fileInputFiles = _.get(this, "$refs.imgFileInput.files", []);
                if (!fileInputFiles || !fileInputFiles[0]) return Promise.resolve([]);
                return new Promise((resolve, reject) => {
                    try{
                        let reader = new FileReader();
                        reader.onload = function() {
                            resolve(reader.result);
                        };
                        reader.readAsArrayBuffer(fileInputFiles[0]);
                    }
                    catch(err) {
                        reject(err);
                    }
                });
            }
        }

    };
</script>