<template>
    <div class="rq-grid-header">
        <slot name="before-toolbar"></slot>
        <!--Grid Toolbar-->
        <nav v-if="showToolbar"
            class="navbar navbar-light navbar-expand rq-grid-toolbar">

            <slot name="toolbar-start"></slot>

            <div :class="titleClassAttr" v-html="title"></div>

            <div ref="defaultSlotContainer" class="rq-toolbar-slot">
                <slot></slot>
            </div>

            <ul class="navbar-nav rq-toolbar-actions">

                <li v-if="showIncludeInactive && hasTargetInactiveColumn"
                    v-show="!hasActions || !hasSelectedRows"
                    class="nav-item rq-nav-action pt-1 me-3">
                    <b-form-checkbox
                        automation_id="grid_include_inactive"
                        v-model="includeInactiveValue"
                    >Include Inactive</b-form-checkbox>
                </li>

                <li v-if="!hideSearch && showSearchField"
                    v-show="!hasActions || !hasSelectedRows"
                    :class="{ 'nav-item':true, 'me-1': !hideDefaultActions }">
                    <div class="form-group mb-0">
                        <rq-search-input-group
                            :id="searchInputId"
                            :automation_id="searchAutomationId"
                            placeholder="Search..."
                            :filterMode="true"
                            size="sm"
                            input-css-class="rq-placeholder-visible"
                            v-model="searchText"
                            @clear="emitClearSearch"
                            @keyup="$emit(searchEvents.SEARCH_KEY_UP, $event)"
                            @search="$emit(searchEvents.SEARCH, { value: searchText })"
                            @blur="onSearchFieldBlur"
                            show-search-button
                            search-on-enter
                        />
                    </div>
                </li>

                <rq-grid-nav-action
                    v-for="action in toolbarActionsList"
                    :key="action.key"
                    :action="action"
                    :selected-items="selectedRows"
                    @action-click="$emit('action', $event)"
                    icon-only
                />

                <rq-grid-nav-action
                    v-if="!hideClearSelection && selectedRows.length > 0"
                    :action="clearSelectionAction"
                    @action-click="$emit('clear-selection')"
                    icon-only
                />

                <template v-if="!hideDefaultActions">
                    <rq-grid-nav-action
                        v-for="action in defaultToolbarActions"
                        :key="action.key"
                        :action="action"
                        @action-click="onDefaultAction"
                        icon-only
                        right
                    />
                </template>
            </ul>
            <slot name="toolbar-end"></slot>
        </nav>
        <DxPopover v-if="!hideSearch && !showSearchField && !hideToolbar"
            :target="`#${searchIconId}`"
            show-event="click"
            :hide-on-outside-click="false"
            v-model:visible="searchPopoverVisible"
            :position="{ my: 'bottom right', at: 'top center' }">
            <div class="form-group mb-0">
                <rq-search-input-group
                    automation_id="grid_search"
                    placeholder="Search..."
                    :filterMode="true"
                    v-model="searchText"
                    @clear="emitClearSearch"
                    @keyup="$emit(searchEvents.SEARCH_KEY_UP, $event)"
                    @search="$emit(searchEvents.SEARCH, { value: searchText })"
                    @blur="onSearchFieldBlur"
                />
            </div>
        </DxPopover>

        <slot name="after-toolbar"></slot>
    </div>
</template>

<script>
    import { computed, ref, inject } from "vue";
    import RqGridNavAction from "./RqGridNavAction.vue";
    import GridAction from "./GridAction.js";

    const DEFAULT_TOOLBAR_ACTIONS = {
        SEARCH: "default-search",
        CLEAR_SELECTION: "default-clear-selection",
        EXPORT: "default-export",
        RESET: "default-reset",
        CLEAR_FILTERS: "default-clear-filters",
        COLUMN_CHOOSER: "default-column-chooser"
    };

    export default {
        name: "RqGridActionHeader",
        components: { RqGridNavAction },
        props: {
            id: { type: String, default: () => _.uniqueId("rq-action-grid-") },
            title: { type: String, default: "" },
            titleSize: { type: String, default: "lg" },
            actions: { type: Array, default: () => [] },
            theme: { type: String, default: "" },
            searchValue: { type: String, default: "" },
            includeInactive: { type: Boolean, default: false},
            showIncludeInactive: { type: Boolean, default: false},
            targetInactiveColumn: { type: String, default: "isInactive" },
            hideSearch: { type: Boolean, default: false },
            hideToolbar: { type: Boolean, default: false },
            hideDefaultActions: { type: Boolean, default: false },
            hideSettings: { type: Boolean, default: false },
            hideShowColumnChooser: { type: Boolean, default: false },
            hideExport: { type: Boolean, default: false },
            hideReset: { type: Boolean, default: false },
            hideClearFilters: { type: Boolean, default: false },
            hideClearSelection: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            searchMode: { type: String, default: "field" },
            minSearchTextLength: { type: Number, default: 3},
            searchInputId: { type: String, default: () => _.uniqueId("txt_grid_search-") },
            searchAutomationId: { type: String, default: "txt_grid_search" },
            selectedRows: { type: Array, default: () => [] },
            toolbarActive: { type: Boolean, default: false },
            canFloat: { type: Boolean, default: false },
            defaultTitleMargin: { type: Boolean, default: false },
            hasActions: { type: Boolean, default: false },
        },
        setup(props, { emit }) {
            const gridAutomationId = inject("gridAutomationId", null);
            const defaultSlotContainer = ref(undefined);
            const defaultSlotEmpty = ref(false);
            const hasTitle = computed(() => !_.isEmpty(_.trim(props.title)));

            function refreshFloatState() {
                defaultSlotEmpty.value = _.isEmpty(defaultSlotContainer.value?.textContent);
                emit("update:canFloat", (!hasTitle.value && defaultSlotEmpty.value));
            }

            return {
                gridAutomationId,
                defaultSlotContainer,
                hasTitle,
                defaultSlotEmpty,
                refreshFloatState
            };
        },
        data() {
            return {
                searchIconId: _.uniqueId("grid-search-icon-"),
                toolbarActionsList: [],
                toolbarSettingsList: [],
                searchEvents: {
                    SEARCH: "search",
                    SEARCH_KEY_UP: "search-key-up",
                    CLEAR_SEARCH: "clear-search"
                },
                defaultToolbarActions: [],
                searchPopoverVisible: false,
                clearSelectionAction: new GridAction({
                    key: DEFAULT_TOOLBAR_ACTIONS.CLEAR_SELECTION,
                    automationId: "grid_clear_selection",
                    name: DEFAULT_TOOLBAR_ACTIONS.CLEAR_SELECTION,
                    icon: "fas fa-ban",
                    tooltip: "Clear Selection"
                })
            };
        },
        computed: {
            hasSelectedRows() { return !_.isEmpty(this.selectedRows); },
            hasToolbarActions() { return !_.isEmpty(this.toolbarActionsList); },
            hasToolbarSettings() { return !_.isEmpty(this.toolbarSettingsList); },
            showToolbar() { return !this.hideToolbar && (!this.defaultSlotEmpty || !this.hideDefaultActions || !this.hideSearch || this.hasToolbarActions || !_.isEmpty(this.title)); },
            currentTheme() { return this.theme || _.get(this, "$route.meta.theme", "") || "default"; },
            titleClassAttr() {
                return {
                    "rq-title navbar-text": this.hasTitle,
                    "rq-title-lg":  this.hasTitle && this.titleSize === "lg",
                    "me-4": this.hasTitle && !this.defaultTitleMargin
                };
            },
            showSearchField() { return this.searchMode === "field"; },
            hasTargetInactiveColumn() { return !_.isEmpty(this.targetInactiveColumn); },
            searchText: {
                get() { return this.searchValue; },
                set(val) { this.$emit("update:searchValue", val); }
            },
            includeInactiveValue: {
                get() { return this.includeInactive; },
                set(val) { this.$emit("update:includeInactive", val); }
            }
        },
        watch: {
            actions:{
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    let mappedActions = _.map(newValue, a => new GridAction(a));
                    this.toolbarActionsList = _.filter(mappedActions, a => a.isToolbarAction);
                    this.toolbarSettingsList = _.filter(mappedActions, a => a.isToolbarSetting);
                },
                immediate: true
            },
            showToolbar: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.$emit("update:toolbarActive", newValue);
                },
                immediate: true
            },
            title() {
                this.refreshFloatState();
            }
        },
        created() {
            this.initDefaultActions();
        },
        mounted() {
            this.$nextTick()
                .then(() => {
                    this.refreshFloatState();
                });
        },
        methods: {
            initDefaultActions() {
                if(this.hideToolbar || this.hideDefaultActions) return;

                if(!this.hideSearch && !this.showSearchField) {
                    this.defaultToolbarActions.push(
                        new GridAction({
                            id: this.searchIconId,
                            key: DEFAULT_TOOLBAR_ACTIONS.SEARCH,
                            automationId: "grid_search_icon",
                            name: DEFAULT_TOOLBAR_ACTIONS.SEARCH,
                            icon: "fas fa-search",
                            tooltip: "Search",
                            toolbarAction: true
                        })
                    );
                }

                // if(!this.hideExport) {
                //     this.defaultToolbarActions.push(
                //         new GridAction({
                //             key: DEFAULT_TOOLBAR_ACTIONS.EXPORT,
                //             automationId: "btn_grid_export",
                //             name: DEFAULT_TOOLBAR_ACTIONS.EXPORT,
                //             icon: "fas fa-file-export offset-icon",
                //             tooltip: "Export to Excel",
                //             toolbarAction: true
                //         })
                //     );
                // }

                if(!this.hideSettings) {
                    let settingsAction = {
                        key: "default-grid-settings",
                        automationId: "btn_grid_settings",
                        icon: "fas fa-cog",
                        tooltip: "Grid Settings",
                        toolbarAction: true,
                        children: []
                    };

                    if(this.hasToolbarSettings) {
                        _.forEach(this.toolbarSettingsList, s => {
                            settingsAction.children.push(s);
                        });
                    }

                    if(!this.hideShowColumnChooser) {
                        settingsAction.children.push(
                            new GridAction({
                                key: DEFAULT_TOOLBAR_ACTIONS.COLUMN_CHOOSER,
                                automationId: "btn_grid_configure",
                                name: DEFAULT_TOOLBAR_ACTIONS.COLUMN_CHOOSER,
                                text: "Show/Hide Columns",
                                toolbarAction: true
                            })
                        );
                    }

                    if(!this.hideClearFilters) {
                        settingsAction.children.push(
                            new GridAction({
                                key: DEFAULT_TOOLBAR_ACTIONS.CLEAR_FILTERS,
                                automationId: "btn_grid_clear_filters",
                                name: DEFAULT_TOOLBAR_ACTIONS.CLEAR_FILTERS,
                                text: "Clear All Filters",
                                toolbarAction: true
                            })
                        );
                    }

                    if(!this.hideReset) {
                        settingsAction.children.push(
                            new GridAction({
                                key: DEFAULT_TOOLBAR_ACTIONS.RESET,
                                automationId: "btn_grid_reset",
                                name: DEFAULT_TOOLBAR_ACTIONS.RESET,
                                text: "Reset to Default",
                                toolbarAction: true
                            })
                        );
                    }

                    this.defaultToolbarActions.push(new GridAction(settingsAction));
                }
            },

            onDefaultAction(action) {
                this.$emit("default-action", action);
                switch(action.name) {
                    // case DEFAULT_TOOLBAR_ACTIONS.EXPORT:
                    //     this.$emit("export");
                    //     break;
                    case DEFAULT_TOOLBAR_ACTIONS.COLUMN_CHOOSER:
                        this.$emit("show-column-chooser");
                        break;
                    case DEFAULT_TOOLBAR_ACTIONS.CLEAR_FILTERS:
                        this.$emit("clear-filters");
                        break;
                    case DEFAULT_TOOLBAR_ACTIONS.RESET:
                        this.$emit("reset");
                        break;
                }
            },

            onSearchFieldBlur(e) {
                this.searchPopoverVisible = false;
            },

            emitClearSearch() {
                this.searchText = "";
                this.$emit(this.searchEvents.CLEAR_SEARCH);
                this.searchPopoverVisible = false;
            },

            emitActionEvent(action) {
                let payload = action.createEventPayload(this.selectedRows);

                if(_.isFunction(action.onClick)) {
                    action.onClick(payload);
                    return;
                }

                if(action.eventName) {
                    this.$emit(action.eventName, payload);
                    return;
                }

                this.$emit("action", payload);
            }
        }
    };

</script>
