<template>
    <input
        :id="id"
        :automation_id="automationId"
        :placeholder="placeholder"
        :class="classAttr"
        v-maska="mask"
        type="text"
        v-model="maskedValue"
        @maska="onMaska"
    >
</template>

<script>

    export default {
        name: "RqMaskedInput",

        props: {
            id: { type: String, default: null },
            automation_id: { type: String, default: null },
            placeholder: { type: String, default: null },
            cssClass: { type: String, default: null },
            isMasked: { type: [Boolean,String], default: false },
            maskType: { type: String, default: "CUSTOM" },
            modelValue: { type: String, default: null },
            customMask: { type: String, default: "X*" },
            size: { type: String, default: null },
            alwaysRefresh: { type: Boolean, default: false },
        },

        data () {
            return {
                maskedValue: null,
                rawValue: null,
            };
        },

        computed: {
            automationId() { return this.automation_id || this.id; },
            isMaskedValue() { return _.parseBool(this.isMasked); },
            classAttr() {
                let result = {
                    "form-control": this.cssClass === "form-control" || !_.includes(this.cssClass, "form-control"),
                    "form-control-sm": this.size === "sm" && !_.includes(this.cssClass, "form-control-sm")
                };
                if(!_.isEmpty(this.cssClass)) result[this.cssClass] = true;
                return result;
            },
            maskOptions() {
                return [
                    { type: "PHONE", mask: "(###) ###-####" },
                    { type: "ZIP", mask: ["#####", "#####-####"] },
                    { type: "CUSTOM", mask: this.customMask }
                ]
            },
            mask() {
                if(_.isEmpty(this.modelValue)) return "X*";
                let match = _.find(this.maskOptions, m => _.toUpper(this.maskType) === m.type);
                return match ? match.mask : "X*";
            },
            boundValue() { return this.isMaskedValue ? this.maskedValue : this.rawValue }
        },

        watch: {
            modelValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.boundValue) return;
                this.maskedValue = newValue;
            },
            maskedValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue || !this.isMaskedValue) return;
                this.$emit("update:modelValue", newValue);
            },
            rawValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue || this.isMaskedValue) return;
                this.$emit("update:modelValue", newValue);
            }
        },

        created() {
            this.maskedValue = this.modelValue;
            this.rawValue = this.modelValue;
        },

        methods: {
            focus() {
                _.invoke(this, "$el.focus");
            },
            onMaska(e) {
                this.rawValue = e?.target?.dataset?.maskRawValue;
            }
        }
    };
</script>