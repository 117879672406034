<template>
    <div :class="classAttr">
        <section class="rq-ls-layout-section">
            <slot name="left-section"></slot>
        </section>
        <rq-list-selection-layout-actions v-if="hasCustomLeftActions"
            :actions="customLeftActions"
            :disabled="allActionsDisabled"
            @action="emitAction"
        />
        <rq-list-selection-layout-actions
            :actions="transferActions"
            :disabled="allActionsDisabled"
            @action="emitAction"
        />
        <section class="rq-ls-layout-section">
            <slot name="right-section"></slot>
        </section>
        <rq-list-selection-layout-actions v-if="hasCustomRightActions"
            :actions="customRightActions"
            :disabled="allActionsDisabled"
            @action="emitAction"
        />
        <rq-list-selection-layout-actions v-else-if="sequenceEnabled"
            :actions="sequenceActions"
            :disabled="allActionsDisabled"
            @action="emitAction"
        />
    </div>
</template>
<script>
    import RqListSelectionLayoutActions from "./RqListSelectionLayoutActions.vue";
    export default {
        name: "RqListSelectionLayout",
        components: { RqListSelectionLayoutActions },
        emits: [
            "transfer-all-right",
            "transfer-right",
            "transfer-left",
            "transfer-all-left",
            "move-up",
            "move-down",
            "reorder",
            "action"
        ],
        props: {
            automationIdAppend: { type: String, default: null },
            actions: { type: Array, default: () => [] },
            sectionWidths: { type: String, default: "" },
            sequenceEnabled: { type: Boolean, default: false },
            allActionsDisabled: { type: Boolean, default: false }
        },
        data() {
            return {
                allActions: []
            };
        },
        computed: {
            classAttr() {
                return {
                    "rq-ls-layout": true,
                    [`rq-ls-layout-${this.sectionWidths}`]: !_.isEmpty(this.sectionWidths),
                    "rq-ls-sequence-enabled": this.sequenceEnabled
                }
            },
            transferActions() { return _.filter(this.allActions, a => _.startsWith(a.name, "transfer")); },
            sequenceActions() { return this.sequenceEnabled ? _.filter(this.allActions, a => _.startsWith(a.name, "move")) : []; },
            customRightActions() { return _.filter(this.allActions, a => a.customActionPosition === "right"); },
            customLeftActions() { return _.filter(this.allActions, a => a.customActionPosition === "left"); },
            hasCustomRightActions() { return !_.isEmpty(this.customRightActions); },
            hasCustomLeftActions() { return !_.isEmpty(this.customLeftActions); }
        },
        watch: {
            actions: {
                handler() {
                    this.parseActions();
                },
                immediate: true
            }
        },
        methods: {
            parseActions() {
                const self = this;
                let defaultActions = [
                    { name: "transfer-all-right", tooltip: () => "Transfer All", tooltipPlacement:"top", iconClass: "fas fa-angle-double-right", disabled: () => false },
                    { name: "transfer-right", tooltip: () => "Transfer Right", tooltipPlacement:"left", iconClass: "fas fa-angle-right", disabled: () => false },
                    { name: "transfer-left", tooltip: () => "Transfer Left", tooltipPlacement:"right", iconClass: "fas fa-angle-left", disabled: () => false },
                    { name: "transfer-all-left", tooltip: () => "Transfer All", tooltipPlacement:"bottom", iconClass: "fas fa-angle-double-left", disabled: () => false },
                    { name: "move-up", tooltip: () => "Move Up", tooltipPlacement:"top", iconClass: "fas fa-angle-up", disabled: () => false },
                    { name: "move-down", tooltip: () => "Move Down", tooltipPlacement:"bottom", iconClass: "fas fa-angle-down", disabled: () => false },
                    { name: "reorder", tooltip: () => "Reorder", tooltipPlacement:"top", label: true , disabled: () => false },
                ];
                self.allActions = _.map(defaultActions, defaultAction => {
                    let mergeAction = _.find(self.actions, { name: defaultAction.name });
                    if(_.isNil(mergeAction)) return defaultAction;
                    let resultAction = _.assign({}, defaultAction, mergeAction);
                    resultAction.key = _.uniqueId(resultAction.name);
                    let autoIdAppend = _.isEmpty(this.automationIdAppend) ? "" : `-${this.automationIdAppend}`;
                    resultAction.automation_id = _.snakeCase(`btn_${resultAction.name}${autoIdAppend}`);
                    return resultAction;
                });
            },
            emitAction(e) {
                this.$emit(e.name);
                this.$emit("action", e);
            }
        }
    }
</script>