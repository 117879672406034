<template>
    <div ref="rootElement" class="rq-shadow-dom-element" :style="styleAttr"></div>
</template>

<script setup>
    import { computed, ref, watchEffect } from "vue";

    const props = defineProps({
        mode: { type: String, default: "closed" },
        height: { default: "auto" },
        minHeight: { default: 0 },
        maxHeight: { default: null },
        htmlContent: { type: String, default: "" },
        customCss: { type: String, default: "" }
    });

    const emit = defineEmits(["content-click"]);

    let shadowRoot;

    const rootElement = ref(null);
    const htmlContentProp = computed(() => props.htmlContent);
    const customCssProp = computed(() => props.customCss);

    const px = val => _.isNil(val) ? null : isNaN(val) ? val : `${val}px`;
    const styleAttr = computed(() => ({
        height: px(props.height),
        minHeight: px(props.minHeight),
        maxHeight: px(props.maxHeight)
    }));

    watchEffect(() => {
        if(_.isNil(rootElement.value)) return;
        if(_.isNil(shadowRoot)) {
            let mode = _.includes(["open","closed"], props.mode) ? props.mode : "closed";
            shadowRoot = rootElement.value.attachShadow({ mode });
            if(mode !== "closed") {
                shadowRoot.addEventListener("click", e => emit("content-click", {
                    event: e,
                    htmlContent: htmlContentProp.value
                }));
            }
        }
        let stylesheets = [];
        if(!_.isEmpty(customCssProp.value)){
            let stylesheet = new CSSStyleSheet();
            stylesheet.replaceSync(customCssProp.value);
            stylesheets.push(stylesheet)
        }
        shadowRoot.adoptedStyleSheets = stylesheets;
        shadowRoot.innerHTML = htmlContentProp.value;
    });

    defineExpose({ rootElement });
</script>

