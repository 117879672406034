<template>
    <div class="rq-message-dialog">
        <div :class="classAttr" v-html="message"></div>
    </div>
</template>
<script setup>
    import { computed } from "vue";
    const props = defineProps({
        message: String,
        messageType: String
    });
    const classAttr = computed(() => ({
        "rq-message-box": props.messageType !== "confirm",
        "rq-confirm": props.messageType === "confirm"
    }));
</script>