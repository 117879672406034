<template>
    <component
        v-if="useWrapper"
        :is="tag"
        :id="id"
        :class="classAttr">
        <slot></slot>
    </component>
    <template v-else>
        <slot></slot>
    </template>
</template>

<script>
    export default {
        inheritAttrs: false
    };
</script>

<script setup>
    defineProps({
        id: { type: String, default: "" },
        tag: { type: String, default: "div" },
        useWrapper: { type: Boolean, default: false },
        classAttr: { type: [Object,Array,String], default: null }
    })
</script>