<template>
    <div :id="scrollContainerId" :class="{
        'rq-scroll-container':true,
        'rq-scroll-vertical':verticalEnabled && !perfectScrollbar,
        'rq-scroll-horizontal':horizontalEnabled && !perfectScrollbar,
        'rq-ps-container':perfectScrollbar,
        'rq-ps-always-visible':perfectScrollbar && alwaysVisible,
        'rq-ps-always-wide':perfectScrollbar && alwaysWide
    }">
        <template v-if="perfectScrollbar">
            <perfect-scrollbar ref="psElement"
                :class="psClassAttr"
                :options="psOptions"
                 @ps-scroll-x="onScrollX"
                 @ps-scroll-y="onScrollY">
                <slot>
                    <!--Scrolling Content-->
                </slot>
            </perfect-scrollbar>
        </template>
        <template v-else>
            <div :id="scrollElementId" ref="nativeScrollElement" class="scroll-container" @scroll="onNativeScroll">
                <slot>
                    <!--Scrolling Content-->
                </slot>
            </div>
        </template>
        <div v-if="backToTopEnabled" v-show="wrapperVisible" :class="backToTopClassAttr" :style="backToTopStyleAttr">
            <button v-rq-tooltip.hover.left="{ title: 'Back to Top' }" class="btn btn-back-to-top" type="button" @click="onBackToTop">
                <FontAwesomeIcon icon="fas fa-arrow-up" />
            </button>
        </div>
        <template v-if="topBottomShadow">
            <div :class="{ 'scroll-shadow scroll-shadow-top':true, 'active': activeShadows.top }"></div>
            <div :class="{ 'scroll-shadow scroll-shadow-bottom':true, 'active': activeShadows.bottom }"></div>
        </template>
        <template v-if="leftRightShadow">
            <div :class="{ 'scroll-shadow scroll-shadow-left':true, 'active': activeShadows.left }"></div>
            <div :class="{ 'scroll-shadow scroll-shadow-right':true, 'active': activeShadows.right }"></div>
        </template>
    </div>
</template>

<script>
    import { DateTime } from "luxon";
    import GlobalEventNames from "../../GlobalEventNames";

    export default {
        name: "RqScrollContainer",
        props:{
            scrollSpyTargetId: { type: String, default: "" },
            hideTopButton: { type: Boolean, default: false },
            scrollMode: { type: String, default: "vertical" },

            perfectScrollbar: { type: Boolean, default: false },
            psOptions: { type: Object, default: () => {} },
            psClassAttr: { type: String, default: "rq-ps-scroll-area" },
            shadowsEnabled: { type: Boolean, default: false },
            noThumbTabFocus: { type: Boolean, default: false },
            psDefaultX: { type: Boolean, default: false },
            psDefaultY: { type: Boolean, default: false },
            alwaysVisible: { type: Boolean, default: false },
            alwaysWide: { type: Boolean, default: false },
            updateOnResize: { type: Boolean, default: false },
        },
        setup(props) {
            const scrollContainerId = _.uniqueId("rq-scroll-container-");
            const lastScrollUpdate = null;
            return {
                scrollContainerId,
                lastScrollUpdate
            };
        },
        data() {
            return {
                scrollX: 0,
                scrollY: 0,
                scrollMaxX: 0,
                scrollMaxY: 0,
                wrapperVisible: false,
                backToTopActive: false,
                lastHash: null,
                defaultPsYOptions:  {
                    maxScrollbarLength: 200,
                    minScrollbarLength: 40,
                    suppressScrollX: true,
                    wheelPropagation: false,
                    interceptRailY: styles => ({ ...styles, height: 0 })
                },
                defaultPsXOptions:  {
                    suppressScrollY: true,
                    interceptRailX: styles => ({ ...styles, width: 0 })
                }
            };
        },
        provide() {
            return {
                scrollContainerId: this.scrollContainerId
            };
        },
        computed:{
            psOptionsValue() {
                return this.psDefaultX
                    ? this.defaultPsXOptions
                    : !_.isEmpty(this.psOptions) && !this.psDefaultY
                        ? this.psOptions
                        : this.defaultPsYOptions;
            },
            scrollElementId() { return this.scrollSpyTargetId ? this.scrollSpyTargetId : _.uniqueId("rq-scroll-container-"); },
            scrollElement() {
                return this.perfectScrollbar
                    ? _.get(this, "$refs.psElement.$el", null)
                    : _.get(this, "$refs.nativeScrollElement", null);
            },
            topBottomShadow() { return this.shadowsEnabled && this.scrollMaxY > 0; },
            leftRightShadow() { return this.shadowsEnabled && this.scrollMaxX > 0; },
            activeShadows() {
                return {
                    top: this.scrollY > 0,
                    bottom:  this.scrollY < this.scrollMaxY,
                    left: this.scrollX > 0,
                    right: this.scrollX < this.scrollMaxX
                };
            },
            backToTopSettings() {
                let routeSettings = _.get(this, "$route.meta.backToTop", {});
                return _.assign({
                    position: "bottom",
                    offset: 0,
                    enabled: true
                }, routeSettings);
            },
            backToTopClassAttr() {
                let position = this.backToTopSettings.position || "bottom";
                return {
                    [`back-to-top-wrapper back-to-top-pos-${position}`]: true,
                    "active": this.backToTopActive
                };
            },
            backToTopStyleAttr() {
                let offset = _.parseNumber(this.backToTopSettings.offset, 0);
                if(offset === 0) return "";
                return `transform:translateY(${offset}px);`;
            },
            backToTopEnabled() {
                return _.parseBool(this.backToTopSettings.enabled)
                    && !this.hideTopButton
                    && !this.perfectScrollbar;
            },
            verticalEnabled() { return this.scrollMode === "vertical" || this.scrollMode === "both"; },
            horizontalEnabled() { return this.scrollMode === "horizontal" || this.scrollMode === "both"; }
        },
        watch: {
            scrollY: {
                //staggering wrapper visibility and active class to allow the css transition to begin/complete
                handler(newValue, oldValue) {
                    const self = this;
                    if(self.hideTopButton) return;
                    if(newValue === 0) {
                        if(!self.wrapperVisible) return;
                        self.backToTopActive = false;
                        _.delay(() => { if(self.scrollY === 0) self.wrapperVisible = false; }, 300);
                    } else if(!self.wrapperVisible) {
                        self.wrapperVisible = true;
                        _.delay(() => { self.backToTopActive = true; }, 100);
                    }
                },
                immediate: true
            }
        },
        mounted(){
            this.disableScrollbarTabIndex();
            this.setScrollValues();
            this.addWindowListeners();
            this.$events.on(GlobalEventNames.RqScrollContainer.scrollToElement, this.onScrollToElement);
        },
        beforeUnmount() {
            this.removeWindowListeners();
            this.$events.off(GlobalEventNames.RqScrollContainer.scrollToElement, this.onScrollToElement);
        },
        methods: {

            onNativeScroll(e) {
                this.$emit("scroll", e);
                this.onNativeScrollDebounced(e);
                this.setLastScrollUpdate();
            },

            onScrollX(e) {
                this.$emit("ps-scroll-x", e);
                this.onScrollXDebounced(e);
            },

            onScrollY(e) {
                this.$emit("ps-scroll-y", e);
                this.onScrollYDebounced(e);
                this.setLastScrollUpdate();
            },

            onNativeScrollDebounced: _.debounce(function(e) {
                this.setScrollValues();
            }, 100),

            onScrollXDebounced: _.debounce(function(e) {
                this.setScrollXValues();
            }, 100),

            onScrollYDebounced: _.debounce(function(e) {
                this.setScrollYValues();
            }, 100),

            onBackToTop(e) {
                this.scrollToTop();
            },

            onWindowResize: _.debounce(function(e) {
                this.update();
            }, 100),

            async onScrollToElement(e) {
                if(e.scrollContainerId !== this.scrollContainerId || _.isEmpty(e.target)) return;
                await _.wait(200);
                this.scrollToElement(e.target);
            },

            setScrollValues(internal=false) {
                this.setScrollXValues();
                this.setScrollYValues();
            },

            setScrollXValues() {
                if(_.isNil(this.scrollElement)) return;
                this.scrollX = this.scrollElement.scrollLeft;
                this.scrollMaxX = this.scrollElement.scrollWidth - this.scrollElement.clientWidth;
            },

            setScrollYValues() {
                if(_.isNil(this.scrollElement)) return;
                this.scrollY = this.scrollElement.scrollTop;
                this.scrollMaxY = this.scrollElement.scrollHeight - this.scrollElement.clientHeight;
            },

            addWindowListeners() {
                if(!this.updateOnResize || !this.perfectScrollbar) return;
                window.addEventListener("resize", this.onWindowResize);
            },

            removeWindowListeners() {
                if(!this.updateOnResize || !this.perfectScrollbar) return;
                window.removeEventListener("resize", this.onWindowResize);
            },

            scrollToStartY(immediate=false) {
                if(_.isNil(this.scrollElement)) return;
                this.scrollTo(0, null, immediate);
            },

            scrollToStartX(immediate=false) {
                if(_.isNil(this.scrollElement)) return;
                this.scrollTo(null, 0, immediate);
            },

            scrollToStart(immediate=false) {
                if(_.isNil(this.scrollElement)) return;
                this.scrollTo(0, 0, immediate);
            },

            scrollToEndY(immediate=false) {
                if(_.isNil(this.scrollElement)) return;
                this.scrollTo(this.scrollMaxY, null, immediate);
            },

            scrollToEndX(immediate=false) {
                if(_.isNil(this.scrollElement)) return;
                this.scrollTo(null, this.scrollMaxX, immediate);
            },

            scrollToEnd(immediate=false) {
                if(_.isNil(this.scrollElement)) return;
                this.scrollTo(this.scrollMaxY, this.scrollMaxX, immediate);
            },

            //Aliases
            scrollToTop(immediate=false) { this.scrollToStartY(immediate); },
            scrollToBottom(immediate=false) { this.scrollToEndY(immediate); },
            scrollToLeft(immediate=false) { this.scrollToStartX(immediate); },
            scrollToRight(immediate=false) { this.scrollToEndX(immediate); },

            scrollTo(top=0, left=0, immediate=false) {
                if(_.isNil(this.scrollElement)) return;
                let scrollToOptions = { behavior:immediate ? "auto" : "smooth" };
                if(!_.isNil(top)) scrollToOptions.top = top;
                if(!_.isNil(left)) scrollToOptions.left = left;
                this.$nextTick()
                    .then(() => {
                        this.update();
                        this.scrollElement.scrollTo(scrollToOptions);
                    });
            },

            scrollToHash(target=null) {
                let hashVal = _.isEmpty(target)
                    ? this.$route.hash
                    : target;
                if(!hashVal) {
                    this.lastHash = null;
                    return;
                }
                this.scrollToElement(hashVal);
            },

            scrollToElement(target) {
                let element = null;

                if(_.isString(target)) {
                    element = this.$el.querySelector(target);
                    if(!element) return;
                    this.lastHash = target;
                }
                else if(target instanceof HTMLElement) {
                    element = target;
                }

                if(!element) return;

                element.scrollIntoView({ behavior: "smooth" });
            },

            disableScrollbarTabIndex() {
                if(!this.perfectScrollbar || !this.noThumbTabFocus) return;
                let scrollbarX = _.get(this, "$refs.psElement.ps.scrollbarX", null);
                let scrollbarY = _.get(this, "$refs.psElement.ps.scrollbarY", null);
                if(scrollbarX) scrollbarX.setAttribute("tabindex", -1);
                if(scrollbarY) scrollbarY.setAttribute("tabindex", -1);
            },

            update() {
                if(!this.perfectScrollbar || !this.$refs.psElement) return;
                this.$refs.psElement.update();
                this.$nextTick(() => {
                    this.$emit("updated", { scrollWidth: this.scrollMaxX, scrollHeight: this.scrollMaxY });
                });
            },

            setLastScrollUpdate: _.debounce(function() {
                let emitScrollStart = true;
                if(!_.isNil(this.lastScrollUpdate) && this.lastScrollUpdate.isValid) {
                    let durFromLastScroll = this.lastScrollUpdate.diffNow();
                    emitScrollStart = (durFromLastScroll.milliseconds * -1) > 1000;
                }
                this.lastScrollUpdate = DateTime.now();
                if(!emitScrollStart) return;
                this.$emit("scroll-start");
                this.$events.emit(GlobalEventNames.RqScrollContainer.scrollStart);
            }, 200, { leading: true, trailing: false })
        }
    }
</script>