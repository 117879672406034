<template>
    <rq-expand-collapse-all
        v-if="isEnabled"
        :all-expanded="allExpanded"
        :all-collapsed="allCollapsed"
        :tooltip-container="tooltipContainer"
        :tooltip-boundary="tooltipBoundary"
        :expand-all-tooltip="expandAllTooltip"
        :collapse-all-tooltip="collapseAllTooltip"
        :inline="inline"
        @expand-all="onExpandAll"
        @collapse-all="onCollapseAll"
    />
</template>

<script>
    import { RQ_SECTION_ACTIONS as ACTIONS } from "./actions";
    import { mapGetters, mapActions } from "vuex";
    const DEFAULT_GROUP = "global";

    export default {
        name: "RqSectionExpandCollapseAll",
        props:{
            sectionGroup: { type:String, default: DEFAULT_GROUP },
            tooltipContainer: { type: [String, HTMLElement, Object], default: null },
            tooltipBoundary: { type: [String, HTMLElement, Object], default: null },
            expandAllTooltip: { type:String, default: "Expand All Sections" },
            collapseAllTooltip: { type:String, default: "Collapse All Sections" },
            inline: { type: Boolean, default: false }
        },
        computed: {
            ...mapGetters([
                "hasMultipleSections",
                "getAllExpanded",
                "getAllCollapsed"
            ]),
            isEnabled() { return this.hasMultipleSections(this.sectionGroup); },
            allExpanded() { return this.getAllExpanded(this.sectionGroup); },
            allCollapsed() { return this.getAllCollapsed(this.sectionGroup); }
        },
        methods: {
            ...mapActions([
                ACTIONS.EXPAND_ALL,
                ACTIONS.COLLAPSE_ALL
            ]),
            onExpandAll() {
                _.invoke(this, ACTIONS.EXPAND_ALL, this.sectionGroup);
                this.$emit("expand-all");
            },
            onCollapseAll() {
                _.invoke(this, ACTIONS.COLLAPSE_ALL, this.sectionGroup);
                this.$emit("collapse-all");
            }
        }
    }
</script>