<template>
    <section :class="classAttr">
        <div
            v-if="renderHeader"
            v-show="showHeader"
            :class="headerClassAttr">
            <div
                v-if="renderTitle"
                ref="titleElement"
                :id="titleActionId"
                :automation_id="titleActionId"
                :class="titleClassAttr"
                :tabindex="titleFocusEnabled ? 0 : -1"
                @click="onTitleAction"
                @keyup.enter="onTitleAction">
                <template v-if="collapsible && !hideCollapseIcon">
                    <span :id="collapseIconId" class="collapse-icon">
                        <FontAwesomeIcon
                            v-if="collapseIcon"
                            :icon="collapseIcon"
                            :rotation="collapseIconRotation"
                        />
                    </span>
                    <b-tooltip
                        v-if="!hideCollapseTooltip"
                        :target="collapseIconId"
                        triggers="hover"
                        v-model:show="collapseTooltipVisible">{{collapseIconTooltip}}
                    </b-tooltip>
                </template>
                <slot name="header-title" :title="title">{{title}}</slot>
            </div>

            <transition v-if="collapsible && hideCollapsedActions" name="simple-fade">
                <div ref="actionsElement" v-show="expandedValue" class="rq-section-actions">
                    <slot name="header-actions" v-bind="{ expanded: expandedValue }"></slot>
                </div>
            </transition>
            <div v-else ref="actionsElement" class="rq-section-actions">
                <slot name="header-actions" v-bind="{ expanded: expandedValue }"></slot>
            </div>

            <transition v-if="collapsible && hideCollapsedSecondary" name="simple-fade">
                <div ref="secondaryElement" class="rq-header-secondary">
                    <div v-if="description" class="rq-content-description">{{description}}</div>
                    <slot name="header-secondary" v-bind="{ expanded: expandedValue }"></slot>
                </div>
            </transition>
            <div v-else ref="secondaryElement" class="rq-header-secondary">
                <div v-if="description" class="rq-content-description">{{description}}</div>
                <slot name="header-secondary" v-bind="{ expanded: expandedValue }"></slot>
            </div>
        </div>
        <template v-if="!headerOnly">
            <template v-if="collapsible">
                <b-collapse
                    :id="collapseId"
                    class="rq-collapse-container"
                    :visible="expandedValue"
                    @show="bubbleEvent('show', $event)"
                    @shown="bubbleEvent('shown', $event)"
                    @hide="bubbleEvent('hide', $event)"
                    @hidden="bubbleEvent('hidden', $event)">
                    <div :class="bodyClassAttr">
                        <slot></slot>
                        <rq-no-data v-if="noDataText" v-show="noData" :text="noDataText" />
                    </div>
                </b-collapse>
            </template>

            <template v-else-if="scrollable">
                <rq-scroll-container :hide-top-button="hideTopButton">
                    <div :class="bodyClassAttr">
                        <slot></slot>
                        <rq-no-data v-if="noDataText" v-show="noData" :text="noDataText" />
                    </div>
                </rq-scroll-container>
            </template>

            <div v-else :class="bodyClassAttr">
                <slot></slot>
                <rq-no-data v-if="noDataText" v-show="noData" :text="noDataText" />
            </div>
        </template>
    </section>
</template>

<script>
    import { computed, onMounted, ref } from "vue";
import RqSectionMixin from "./RqSectionMixin";
    const ICON_ALIGNMENT = { Right: "right", Left: "left" };
    export default {
        name: "RqPageSection",
        mixins: [RqSectionMixin],
        props:{
            description: { default: null },
            theme: { default: null },
            headerSize: { default: "default" },
            noDataText: { type: String, default: null },
            headerClass: { type: String, default: null },
            titleClass: { type: String, default: null },
            bodyClass: { type: String, default: null },
            noData: { type: Boolean, default: false },
            showHeader: { type: Boolean, default: true },
            headerOnly: { type: Boolean, default: false },
            borderless: { type: Boolean, default: false },
            scrollable: { type: Boolean, default: false },
            hideTopButton: { type: Boolean, default: false },
            flexFull: { type: Boolean, default: false },
            flexBody: { type: Boolean, default: false },
            hideCollapsedActions: { type: Boolean, default: false },
            hideCollapsedSecondary: { type: Boolean, default: false },
            headerBorder: { type: Boolean, default: false },
            customTitle: { type: Boolean, default: false }
        },
        setup(props) {
            const defaultIconAlignment = ref(ICON_ALIGNMENT.Left);

            const titleElement = ref(null);
            const actionsElement = ref(null);
            const secondaryElement = ref(null);

            const headerTitleEmpty = ref(false);
            const headerActionsEmpty = ref(false);
            const headerSecondaryEmpty = ref(false);

            const renderTitle = computed(() => props.customTitle
                || !_.isEmpty(props.title)
                || !headerTitleEmpty.value
                || (props.collapsible && props.hideCollapseIcon));

            const renderHeader = computed(() => renderTitle.value
                || !headerActionsEmpty.value
                || !headerSecondaryEmpty.value);

            const elRefIsEmpty = el => _.isEmpty(el.value?.textContent);

            onMounted(() => {
                headerTitleEmpty.value = elRefIsEmpty(titleElement);
                headerActionsEmpty.value = elRefIsEmpty(actionsElement);
                headerSecondaryEmpty.value = elRefIsEmpty(secondaryElement);
            })

            return {
                defaultIconAlignment,
                titleElement,
                actionsElement,
                secondaryElement,
                headerTitleEmpty,
                headerActionsEmpty,
                headerSecondaryEmpty,
                renderTitle,
                renderHeader
            };
        },
        computed:{
            classAttr() {
                let routeTheme = this.theme || _.get(this, "$route.meta.theme", "default") || "default";
                return {
                    "rq-section": true,
                    "rq-section-flex-full": this.flexFull || this.noData,
                    "rq-section-flex-body": this.flexBody || this.noData,
                    [`theme-${routeTheme}`]: true,
                    "rq-no-border": this.borderless,
                    "rq-no-header": !this.showHeader || !this.renderHeader,
                    "rq-header-only": this.headerOnly,
                    "rq-scrollable": this.scrollable,
                    ...this.collapsibleClasses
                };
            },
            headerClassAttr() {
                return {
                    "rq-header rq-section-header":true,
                    "rq-header-bordered": this.headerBorder,
                    [`rq-section-header-${this.headerSize.toLowerCase()}`]: _.isString(this.headerSize) && !_.isEmpty(this.headerSize),
                    [this.headerClass]: !_.isEmpty(this.headerClass)
                };
            },
            titleClassAttr() {
                return {
                    "rq-title rq-section-title":true,
                    [this.titleClass]: !_.isEmpty(this.titleClass)
                };
            },
            bodyClassAttr() {
                return {
                    "rq-section-body": true,
                    [this.bodyClass]: !_.isEmpty(this.bodyClass)
                };
            }
        }
    }
</script>
