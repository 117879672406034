<template>
    <ul v-if="totalItems > 1" class="nav jump-to-nav">
        <li class="navbar-text">Jump to</li>
        <li class="nav-item form-item">
            <rqSelectBox
                :items="jumpToItems"
                :automation_id="automation_id"
                cssClass="form-control"
                @change="onJumpToChange"
                v-model="selectedValue" />
        </li>
        <li class="nav-item" v-show="displayNavArrows">
            <button class="btn btn-link" :disabled="goBackDisabled" @click="onGoBack">
                <FontAwesomeIcon icon="fas fa-angle-left" />
            </button>
        </li>
        <li class="navbar-text" v-show="displayNavArrows">{{jumpToDescription}}</li>
        <li class="nav-item" v-show="displayNavArrows">
            <button class="btn btn-link" :disabled="goForwardDisabled" @click="onGoForward">
                <FontAwesomeIcon icon="fas fa-angle-right" />
            </button>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "JumpToNav",
        props: {
            modelValue: { default: 0 },
            totalItems: { default: 0 },
            displayNavArrows: { type: Boolean, default: false},
            automation_id: {
                type: String,
                default: ""
            }
        },

        data() {
            const self = this;
            return {
                selectedIndex: 0,
                lastIndex: 0
            };
        },

        computed: {
            selectedValue: {
                get: function () {
                    return this.selectedIndex + 1;
                },
                set: function (newValue) {
                    this.selectedIndex = _.parseNumber(newValue, 1) - 1;
                }
            },
            jumpToItems() {
                let items = [];
                for(let n=1; n <= this.totalItems; n++) {
                    items.push({ id: n, name: `${n} of ${this.totalItems}` });
                }
                return items;
            },
            jumpToDescription() {
                if(this.totalItems === 0) return "";
                return `${this.selectedIndex + 1} of ${this.totalItems}`;
            },
            goBackDisabled() {
                return this.selectedIndex === 0;
            },
            goForwardDisabled() {
                return this.selectedIndex === (this.totalItems - 1);
            }
        },

        watch:{
            modelValue: {
                handler(newVal, oldVal) {
                    if(newVal === oldVal || newVal === this.selectedIndex) return;
                    this.lastIndex = oldVal;
                    this.selectedIndex = _.parseNumber(newVal, 0);
                },
                immediate:true
            }
        },

        created() {
            this.selectedIndex = _.parseNumber(this.modelValue, 0);
        },

        methods: {
            onGoBack(){
                if(this.selectedIndex - 1 < 0) return;
                let newIndex = this.selectedIndex - 1;
                this.emitVal(newIndex);
            },
            onGoForward(){
                if(this.selectedIndex + 1 >= this.totalItems) return;
                let newIndex = this.selectedIndex + 1;
                this.emitVal(newIndex);
            },
            onJumpToChange(e) {
                let newIndex = e.selectedValue - 1;
                let previousIndex = e.previousValue - 1;
                this.emitVal(newIndex, previousIndex);
            },
            emitVal(newIndex, previousIndex) {
                this.$emit("change", { index: newIndex, lastIndex: previousIndex });
                this.$emit("update:modelValue", newIndex);
            },
            undoIndexChange() {
                this.selectedIndex = this.lastIndex;
            }
        }

    };

</script>
