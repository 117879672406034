<template>
    <div class="rq-file-input-button">
        <RqFileInput
            ref="fileInputComponent"
            :automation_id="automationIds.input"
            :name="name"
            :accept="accept"
            :multiple="multiple"
            :clear-after-change="!clearAfterChange"
            :disabled="disabled"
            v-model:file="selectedFile"
            v-model:file-list="selectedFiles"
            @change="onFileInputChange"
            hidden
        />
        <RqButton
            :automation_id="automationIds.button"
            :variant="variant"
            :size="size"
            :disabled="disabled"
            @click="onButtonClick">
            <slot>
                <span v-html="label" />
            </slot>
        </RqButton>
    </div>
</template>

<script setup>
    import { computed, ref, unref, watch } from 'vue';

    const props = defineProps({
        automation_id: { type: String, default: null },
        buttonAutomationId: { type: String, default: null },
        inputAutomationId: { type: String, default: null },
        variant: { type: String, default: "theme" },
        size: { type: String, default: null },
        label: { type: String, default: "Select a file..." },
        name: { type: String, default: "files" },
        file: { type: File, default: null },
        fileList: { type: FileList, default: null },
        accept: { type: String, default: ".*" },
        multiple: { type: Boolean, default: false },
        hidden: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        clearAfterChange: { type: Boolean, default: false }
    });

    const emit = defineEmits(["change", "update:file", "update:fileList"]);

    const fileInputComponent = ref(null);

    const defaultAutomationId = computed(() => props.automation_id || _.uniqueId("rq-file-input-button-"));
    const automationIds = computed(() => ({
        input: props.inputAutomationId || `file-${defaultAutomationId.value}`,
        button: props.buttonAutomationId || `btn-${defaultAutomationId.value}`
    }));

    const selectedFile = computed({ get: () => props.file, set: val => emit("update:file", val) });
    const selectedFiles = computed({ get: () => props.fileList, set: val => emit("update:fileList", val) });

    function click() { fileInputComponent.value.click(); }
    function clear() { fileInputComponent.value.clear(); }
    function onButtonClick() { click(); }
    function onFileInputChange(e) { emit("change", e); }

    defineExpose({ clear, click });
</script>