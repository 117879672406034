const RQUIExceptionSeverity = {
    minor: 0,
    major: 1,
    fatal: 999
};
class RQUIException {
    constructor(options) {
        options = options || {};
        this.componentName = options.componentName || "NOT PROVIDED";
        this.route = options.route || {};
        this.error = options.error || {};
        this.info = options.info || "";
        this.message = options.message || "";
        this.severity = options.severity || RQUIExceptionSeverity.minor;
    }
}

export { RQUIExceptionSeverity, RQUIException };