<template>
    <div :class="classAttr">
        <slot name="prepend-slot"></slot>
        <input ref="inputField"
            :id="idValues.input"
            :automation_id="automationIdValues.input"
            type="text"
            :placeholder="placeholder"
            :class="inputClassAttr"
            :disabled="disabled"
            :tabindex="tabindex"
            v-model="inputValue"
            @keyup="onKeyUp"
            @blur="onBlur"
            @keyup.enter="onEnter"
        >
        <b-btn
            v-if="showClearValueIcon && inputHasValue"
            v-rq-tooltip.hover.top
            :id="idValues.clearButton"
            variant="icon"
            class="btn-clear-value"
            title="Clear Value"
            @click="onClear">
            <FontAwesomeIcon icon="fas fa-times-circle" />
        </b-btn>
        <b-btn
            v-if="showSearchButton"
            v-rq-tooltip.hover.top
            :id="idValues.searchButton"
            :automation_id="automationIdValues.searchButton"
            class="btn-search"
            title="Search"
            :disabled="disabled || invalidSearchTextLength"
            @click="onSearch">
            <FontAwesomeIcon icon="fas fa-search" />
        </b-btn>
        <span v-else class="input-group-text">
            <FontAwesomeIcon icon="fas fa-search" :class="{ 'invisible': inputHasValue }" />
        </span>
        <slot name="append-slot"></slot>
    </div>
</template>
<script>
    export default {
        props: {
            id: { type: String, default:null },
            automation_id: { type: String, default: "txt_search" },
            componentKey: { default: () => _.uniqueId("txt_search") },
            placeholder: { type: String, default: "" },
            modelValue: { type: String, default: null },
            tabindex: { type: [String,Number], default: "0" },
            filterMode: { type: Boolean, default: false },
            inputCssClass: { type: String, default: "" },
            showClearValueIcon: { type:Boolean, default: true },
            showSearchButton: { type:Boolean, default: false },
            searchOnEnter: { type:Boolean, default: false },
            disabled: { type: Boolean, default: false },
            size: { type: String, default: null },
            minSearchTextLength: { type: Number, default: 0 }
        },

        data () { return { inputValue:"" }; },

        computed: {
            idValues() {
                let id = this.id || _.uniqueId(this.automation_id || "txt_search");
                return this.getIdValues(id);
            },
            automationIdValues() {
                let id = this.automation_id || this.id || "txt_search";
                return this.getIdValues(id);
            },
            classAttr() {
                return {
                    "search-input-group input-group": true,
                    "search-button-enabled": this.showSearchButton,
                    "input-group-sm": this.size === "sm"
                };
            },
            inputHasValue() { return !_.isNil(this.inputValue) && !_.isEmpty(this.inputValue); },
            inputClassAttr(){
                let classes = ["form-control"];
                if(this.size === "sm" && !_.includes(this.inputCssClass, "form-control-sm"))
                    classes.push("form-control-sm");
                if(!_.isEmpty(this.inputCssClass))
                    classes.push(this.inputCssClass);
                return classes;
            },
            invalidSearchTextLength() {
                return this.inputHasValue
                    && this.minSearchTextLength > 0
                    && this.inputValue.length < this.minSearchTextLength;
            }
        },

        watch:{
            inputValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            },
            modelValue:{
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.inputValue) return;
                    this.inputValue = newValue;
                },
                immediate: true
            }
        },

        methods: {
            onSearch(e) {
                this.$emit("search", { value: this.inputValue });
            },
            onClear() {
                this.inputValue = "";
                this.$emit("clear");
                this.focus();
            },
            onKeyUp(e) {
                this.$emit("keyup", { value: this.inputValue, event: e });
            },
            onEnter(e) {
                this.$emit("enter-key", { value: this.inputValue, event: e });
                if(!this.searchOnEnter || this.invalidSearchTextLength) return;
                this.$emit("search", { value: this.inputValue });
            },
            onBlur(e) {
                this.$emit("blur", { value: this.inputValue, event: e });
            },
            focus() {
                _.invoke(this, "$refs.inputField.focus");
            },
            getIdValues(id) {
                let searchButton = _.startsWith(id, "txt_") ? _.replace(id, "txt_", "btn_") : `btn_${id}`;
                let clearButton = _.startsWith(id, "txt_") ? _.replace(id, "txt_", "btn_clear_") : `btn_clear_${id}`;
                return { input: id, clearButton, searchButton };
            }
        }
    }
</script>
