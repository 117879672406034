<template>
    <div :class="classAttr">
        <div
            v-if="renderHeader"
            v-show="showHeader"
            :id="titleActionId"
            :automation_id="titleActionId"
            class="card-header"
            @click="onTitleAction">
            <div
                v-show="title || !headerTitleSlotEmpty"
                :class="titleClassAttr"
                :tabindex="titleFocusEnabled ? 0 : -1"
                @keydown.enter="onTitleAction">
                <slot name="card-title">{{title}}</slot>
            </div>
            <div class="rq-card-actions">
                <slot name="card-actions"></slot>
            </div>
            <template v-if="collapsible && !hideCollapseIcon">
                <span :id="collapseIconId" class="collapse-icon">
                    <FontAwesomeIcon
                        v-if="collapseIcon"
                        :icon="collapseIcon"
                        :rotation="collapseIconRotation"
                    />
                </span>
                <b-tooltip
                    v-if="!hideCollapseTooltip"
                    :target="collapseIconId"
                    triggers="hover"
                    v-model:show="collapseTooltipVisible">{{collapseIconTooltip}}
                </b-tooltip>
            </template>
        </div>
        <template v-if="collapsible">
            <b-collapse
                :id="collapseId"
                class="rq-collapse-container"
                :visible="expandedValue"
                @show="bubbleEvent('collapse-show', $event)"
                @shown="bubbleEvent('collapse-shown', $event)"
                @hide="bubbleEvent('collapse-hide', $event)"
                @hidden="bubbleEvent('collapse-hidden', $event)">
                <div :class="bodyClassAttr">
                    <slot></slot>
                    <rq-no-data v-if="noDataText" v-show="noData" :text="noDataText" />
                </div>
            </b-collapse>
        </template>
        <div v-else :class="bodyClassAttr">
            <slot></slot>
            <rq-no-data
                v-if="noDataText"
                v-show="noData"
                :text="noDataText"
            />
        </div>
    </div>
</template>

<script>
    import RqSectionMixin from "./RqSectionMixin";
    const ICON_ALIGNMENT = { Right: "right", Left: "left" };
    const TITLE_SIZE = { Small: "sm", Medium: "md", Large: "lg" };

    export default {
        name: "RqSectionCard",
        mixins: [RqSectionMixin],
        props:{
            theme: { default: null },
            noDataText: { type: String, default: null },
            noData: { type: Boolean, default: false },
            titleSize: { type: String, default: TITLE_SIZE.Large },
            noWrapTitle: { type: Boolean, default: false },
            showHeader: { type: Boolean, default: true },
            bodyClass: { type: String, default: "" }
        },
        data() {
            return {
                headerTitleSlotEmpty: false,
                defaultIconAlignment: ICON_ALIGNMENT.Right
            };
        },
        computed:{
            classAttr() {
                let cardTheme = this.theme || _.get(this, "$route.meta.theme", "default") || "default";
                return {
                    "card rq-section-card": true,
                    [`theme-${cardTheme}`]: true,
                    ...this.collapsibleClasses
                };
            },
            titleClassAttr() {
                return {
                    [`card-title card-title-${this.titleSize} me-auto`]:true,
                    "text-truncate": this.noWrapTitle
                };
            },
            bodyClassAttr() {
                return {
                    "card-body": true,
                    "rq-card-no-data": this.noData,
                    [this.bodyClass]: !_.isEmpty(this.bodyClass)
                };
            },
            renderHeader() { return !_.isEmpty(this.title) || !this.headerTitleSlotEmpty; }
        },
        mounted() {
            this.headerTitleSlotEmpty = this.slotIsEmpty("card-title");
        }
    }
</script>
