<template>
    <span
        v-if="isLoading || isVisible"
        :class="containerClass"
        v-rq-tooltip.hover="{
            placement: errorTooltipPlacement,
            variant: 'danger',
            container: 'body',
            boundary: 'body',
            title: hasError ? statusValue : ''
        }"
        @click="$emit('action-click')">
        <FontAwesomeIcon
            :icon="iconClass"
            :rotation="iconRotation"
        />
    </span>
</template>

<script>
    export default {
        name: "RqTreeViewItemAction",
        props: {
            item: { type: Object, default: () => ({}) },
            itemStatus: { type: String, default: null },
            caretIcon: { type: String, default: "" },
            caretRotate: { type: String, default: "fa-rotate-90" },
            caretRotateOn: { type: String, default: "expand" },
            errorTooltipPlacement: { type: String, default: "top" },
        },
        computed: {
            statusValue() {
                return _.has(this.item, "status")
                    ? (this.item.status || this.itemStatus)
                    : this.itemStatus;
            },
            isLoading() { return this.statusValue === "loading"; },
            hasError() { return !_.isEmpty(this.statusValue) && this.statusValue !== "loading"; },
            isVisible() { return this.item.hasChildren; },
            containerClass() {
                return this.isLoading
                    ? "rq-tv-item-spinner"
                    : this.hasError
                        ? "rq-tv-item-error"
                        : "rq-tv-item-toggle";
            },
            iconClass() {
                if(this.isLoading) return "fad fa-spinner"
                if(this.hasError) return "fas fa-circle-exclamation"
                return this.caretIcon;
            },
            iconRotation() {
                let showCaret = !this.isLoading && !this.hasError;
                if(!showCaret || (this.caretRotateOn === "expand" && !this.item.isExpanded) || (this.caretRotateOn === "start" && this.item.isExpanded)) return 0;
                return _.parseNumber(_.replace(this.caretRotate, "fa-rotate-", ""));
            }
        }
    }
</script>
