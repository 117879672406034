<template>
    <div>
        <date-range-filter
            v-if="isCustomRange"
            ref="dateRangeEditor"
            :id="`${id}_rq_date_range`"
            :automation_id="automation_id"
            v-model:start-date="startDateValue"
            v-model:end-date="endDateValue"
            :disabled="disabled"
            @apply="onApply"
            @cancel="emit('cancel')"
            borderless
        />
        <b-list-group v-else class="rq-date-range-list">
            <b-list-group-item
                v-for="r in ranges"
                ref="dateRangeListItems"
                :automation_id="`btn_${automation_id}_custom_range_${r.id}`"
                :key="r.id"
                :active="value === r.id"
                tabindex="0"
                :disabled="disabled"
                @keyup="onRangeKeyUp($event, r)">
                <div
                    class="item-label"
                    @click="setSelectedRange(r)">{{r.description}}
                </div>
                <div v-if="modelValue === r.id"
                    class="clear-icon"
                    @click="onClearSelection">
                    <FontAwesomeIcon icon="fas fa-times" />
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script setup>
    import { ref, computed, nextTick } from "vue";
    import { DateTime } from "luxon";
    import { useVModel } from "@vueuse/core";
    import { LUXON_ISO_FORMAT, useDateHelpers } from "../DateUtil";

    import DateRangeFilter from "./DateRangeFilter.vue";

    const props = defineProps({
        id: { type: String, default:null },
        automation_id: { type: String, default: "" },
        startDate: { type: String, default: DateTime.now().toFormat("MM/dd/yyyy") },
        endDate: { type: String, default: DateTime.now().plus({ weeks: 1 }).toFormat("MM/dd/yyyy") },
        format: { type: String, default: LUXON_ISO_FORMAT },
        modelValue: { type: Number, default: null },
        disabled: { type: Boolean, default: false }
    });
    const emit = defineEmits(["update:modelValue", "custom-range-selected", "apply", "remove", "cancel"]);

    const selectedValue = useVModel(props, "modelValue", emit);
    const {
        startDateValue,
        endDateValue
    } = useDateHelpers(props, emit);

    const dateRangeEditor = ref(null);
    const dateRangeListItems = ref(null);

    const ranges = [
        { id: 1, description: "Last Week", days: 7 },
        { id: 2, description: "Last 30 days", days: 30 },
        { id: 3, description: "Last 60 days", days: 60 },
        { id: 4, description: "Last 90 days", days: 90 },
        { id: 5, description: "Last 180 days", days: 180 },
        { id: 6, description: "Custom date range", days: null }
    ];

    const isCustomRange = computed(() => selectedValue.value === 6);

    function setSelectedRange(range=null) {
        if(range?.id === selectedValue.value) return;
        selectedValue.value = range?.id;
        nextTick(focus);
        if(range?.id === 6) {
            emit("custom-range-selected");
            return;
        }
        let days = _.parseNumber(range?.days, 0);
        startDateValue.value = days > 0 ? DateTime.now().minus({ days }).toFormat(LUXON_ISO_FORMAT) : null;
        endDateValue.value = DateTime.now().toFormat(LUXON_ISO_FORMAT);
        emit("apply");
    }

    function focus() {
        if(isCustomRange.value)
            dateRangeEditor.value?.focus?.();
        else
            dateRangeListItems.value?.[0]?.focus?.();
    }

    function onRangeKeyUp(e, range) {
        if(e.key !== "Enter") return;
        e.preventDefault();
        e.stopPropagation();
        setSelectedRange(range);
    }

    function onClearSelection() {
        emit("remove");
    }

    function onApply() {
        if(_.isNil(startDateValue.value) && _.isNil(endDateValue.value))
            selectedValue.value = null;
        emit("apply");
    }

    defineExpose({ focus });

</script>