<template>
    <div v-show="showBanner" :class="classAttr">
        <span v-if="icon" class="rq-banner-icon"><FontAwesomeIcon :icon="icon" /></span>
        <slot name="title-content">
            <span v-if="title" class="rq-banner-title" v-html="title"></span>
        </slot>
        <slot name="message-content">
            <span v-if="message" class="rq-banner-message" v-html="message"></span>
        </slot>
        <button v-if="dismissable" v-rq-tooltip.hover.left title="Dismiss" class="btn-dismiss" type="button" @click="onDismiss">
            <FontAwesomeIcon icon="fas fa-times" />
        </button>
    </div>
</template>

<script>
    export default {
        name: "RqBanner",
        props:{
            icon: { default: "" },
            title: { default: "" },
            message: { default: "" },
            variant: { default: "default" },
            dismissable: { type: Boolean, default: false },
            visible: { type: Boolean, default: false },
            sticky: { type: Boolean, default: false }
        },
        data() {
            return {
                dismissed: false
            };
        },
        computed:{
            classAttr() {
                let classes = ["rq-banner", `rq-banner-${this.variant}`];
                if(this.sticky)
                    classes.push("rq-banner-sticky");
                if(this.dismissable)
                    classes.push("rq-banner-dismissable");
                return classes;
            },
            showBanner() { return this.visible && !this.dismissed; }
        },
        watch: {
            visible: {
                handler(newVal, oldVal) {
                    if(newVal === oldVal) return;
                    this.dismissed = false;
                },
                immediate: true
            }
        },
        methods: {
            onDismiss() {
                this.dismissed = true;
                this.$emit("dismiss");
            }
        }
    }
</script>
