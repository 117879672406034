<template>
    <transition name="grid-navbar-fade">
        <nav
            v-if="hasActions && showActions"
            class="navbar navbar-light navbar-expand rq-grid-selection-navbar">
            <ul class="navbar-nav">
                <rq-grid-nav-action
                    v-for="action in visibleActions"
                    :key="action.key"
                    :action="action"
                    :selected-items="selectedItems"
                    :read-only="readOnly"
                    :check-action-permission="checkActionPermission"
                    @action-click="$emit('action-click', $event)"
                />
            </ul>

            <!-- If we need a Hide/Close button: -->
            <!-- <rq-icon-button
                automation_id="btn_hide_actions"
                icon="fas fa-times"
                tooltip-text="Hide"
                class="ms-auto"
                btn-class="close-icon-button"
                @click="showActions=false"
            /> -->
        </nav>
    </transition>
</template>

<script>
    import GridAction from "./GridAction.js";
    import RqGridNavAction from "./RqGridNavAction.vue";

    export default {
        components: {
            RqGridNavAction
        },
        props: {
            actions: { type: Array, default: () => [] },
            selectedItems: { type: Array, default: () => [] },
            checkActionPermission: { type: Function, default(){ return () => true; } },
            readOnly: { type: Boolean, default: false },
            isActive: { type: Boolean, default: false }
        },

        computed: {
            mappedActions() {
                return _.every(this.actions, a => a instanceof GridAction)
                    ? this.actions
                    : _.map(this.actions, a => new GridAction(a));
            },
            visibleActions() { return _.filter(this.mappedActions, a => a.isVisible(this.selectedItems)); },
            hasActions() { return !_.isEmpty(this.visibleActions); },
            showActions() { return !_.isEmpty(this.selectedItems); }
        },

        methods: {
            emitActionEvent(action) {
                let payload = action.createEventPayload(this.selectedRows);

                if(_.isFunction(action.onClick)) {
                    action.onClick(payload);
                    return;
                }

                if(action.eventName) {
                    this.$emit(action.eventName, payload);
                    return;
                }

                this.$emit("action", payload);
            }
        }
    };
</script>