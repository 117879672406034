<template>
    <button
        ref="buttonElement"
        :id="id"
        :automation_id="automationIdAttr"
        type="button"
        :class="classAttr"
        :disabled="disabled"
        v-bind="$attrs"
        @click="onClick">
        <FontAwesomeIcon v-if="hasIcon"
            :icon="icon"
            :rotation="props.rotate"
        />
        <slot></slot>
    </button>
</template>

<script setup>
    import { computed, ref } from "vue";
    import { useRouter } from "vue-router";

    const props = defineProps({
        id: { type: String, default: null },
        automation_id: { type: String, default: null },
        variant: { type: String, default: "secondary" },
        size: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        icon: { type: String, default: null },
        rotate: { type: Number, default: 0 },
        to: { type: [String,Object], default: null }
    });
    const emit = defineEmits(["click"]);
    const router = useRouter();

    const buttonElement = ref(null);

    const componentId = _.uniqueId("rq_icon_button");
    const idAttr = computed(() => { return props.id || componentId.value; });
    const automationIdAttr = computed(() => { return props.automation_id || idAttr.value; });
    const hasIcon = computed(() => !_.isEmpty(props.icon));
    const variantClass = computed(() => _.isEmpty(props.variant) ? hasIcon.value ? "btn-icon" : "btn-secondary" : `btn-${props.variant}`);
    const sizeClass = computed(() => _.includes(["sm","md","lg","xl"], props.size) ? `btn-${props.size}` : "");
    const classAttr = computed(() => ["btn", variantClass.value, sizeClass.value]);

    function onClick(e) {
        if(_.isNil(props.to)) {
            emit("click", e);
            return;
        }
        router.push(props.to);
    }
    function focus() {
        if(!buttonElement.value) return;
        buttonElement.value.focus();
    }
    defineExpose({ focus, focusToggler: focus });
</script>