<template>    
    <PromptInputFormGroup
        :label="label"
        :label-for="idAttrs.id"
        :is-required="isRequired"
        :show-banner="showBanner"
        :has-error="hasError"
        :validation-messages="validationMessages"
        :alerts="alerts">
        <dx-tag-box
            :input-attr="idAttrs"
            ref="drp_prompMultiSelect"
            class="form-control"
            :data-source="items"
            :display-expr="displayExpr"
            :value-expr="valueExpr"
            :show-selection-controls="true"
            :show-clear-button="true"
            :max-displayed-tags="0"
            :show-drop-down-button="true"
            apply-value-mode="useButtons"
            v-model="inputValue"
            @enterKey="$emit('ok')"
            v-focus.input="{ delay: 100 }"
        />
    </PromptInputFormGroup>
</template>
<script setup>
    import { computed } from "vue";
    import { usePromptInputProps, usePromptInput } from "./composables";
    import PromptInputFormGroup from "./PromptInputFormGroup.vue";

    const props = defineProps({
        ...usePromptInputProps("drp_prompt_multi_select"),
        valueExpr: String,
        displayExpr: String,
        items: Array
    });

    const {
        idAttrs,
        inputValue,
        showBanner,
        hasError,
        validationMessages,
        isValid
    } = usePromptInput(props);

    const selectedValues = computed(() => inputValue.value);
    const selectedItems = computed(() => _.filter(props.items, item => _.includes(inputValue.value, _.get(item, props.valueExpr, null))));

    defineExpose({ isValid, selectedValues, selectedItems });
</script>
