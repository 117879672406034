<template>
    <textarea :id="id"
        :automation_id="id"
        class="form-control"
        :disabled="disabled"
        :autocomplete="autocomplete ? 'on' : 'new-user-phone'"
        v-model="boundValue">
    </textarea>
</template>

<script>
    import autosize from "autosize";
    const AutosizeEvent = {
        RESIZED: "autosize:resize",
        UPDATE: "autosize:update",
        DESTROY: "autosize:destroy"
    };
    export default {
        name: "RqTextarea",
        props: {
            id: { default:null },
            automation_id: { type: String, default: "" },
            componentKey: { default: _.uniqueId() },
            disabled: { type: Boolean, default: false },
            autocomplete: { type: Boolean, default: false },
            maxHeight: { type: Number, default: null },
            autoResize: { type: Boolean, default: false },
            modelValue: { default: null }
        },
        emits: ["update:modelValue"],
        data() {
            const self = this;
            return {
                elementIdAttr: _.isNil(self.id) ? `rq-input-${self.componentKey}` : self.id,
                boundValue: this.modelValue
            }
        },
        computed: {
            automationId() { return this.automation_id || this.elementIdAttr; }
        },
        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.boundValue) return;
                    this.boundValue = newValue;
                    this.$nextTick(() => {
                        this.updateAutosize();
                    });
                },
                immediate: true
            },
            boundValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            }
        },
        mounted() {
            if(!this.autoResize) return;
            this.initAutosize();
        },
        beforeUnmount(){
            const self = this;
            if(!self.autoResize) return;
            self.$el.removeEventListener(AutosizeEvent.RESIZED, self.onResized);
            autosize.destroy(self.$el);
        },
        methods: {
            onResized(e) {
                if(!this.maxHeight) return;

                if(this.$el.height >= this.maxHeight) {
                    this.$el.style.overflow = "auto";
                }
                else if(this.$el.style.overflow !== "hidden") {
                    this.$el.style.overflow = "hidden";
                }
            },
            initAutosize(){
                if(this.maxHeight) {
                    this.$el.style.maxHeight = `${this.maxHeight}px`;
                }
                autosize(this.$el);
                this.$el.addEventListener(AutosizeEvent.RESIZED, this.onResized);
            },
            updateAutosize() {
                if(!this.autoResize) {
                    console.warn("autoResize has not been enabled for this textarea.  Add the prop 'auto-resize' to your component to enable.");
                }
                if(!this.$el) return;
                autosize.update(this.$el);
            }
        }
    }
</script>