<template>
    <div></div>
</template>

<script>

   /*
    *  Visit the following URL for a complete list of possible dxDropDownBox configuration options:
    *  https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDropDownBox/Configuration/
    */

    import 'devextreme/ui/drop_down_box';

    export default {

        props: {
            dataSource: { default: function () { return []; } },
            config: { default: function() { return {};} },
            disabled: { default: false },
            modelValue: null
        },

        data(){
            return {
                dxValue: null,
                isLoaded: false
            };
        },

        computed:{
            instance(){
                return $(this.$el).dxDropDownBox("instance");
            }
        },

        mounted() {
            var self = this;
            self.config.dataSource = self.dataSource || [];
            self.loadWidget();
        },

        watch: {

            modelValue(newValue, oldValue){
                if(!this.isLoaded || newValue === oldValue) return;
                this.instance.option("value", newValue);
                //this.dxValue = newValue;
            },

            // dxValue(newValue, oldValue){
            //     if(!this.isLoaded || newValue === oldValue || this.modelValue === newValue) return;
            //     this.$emit("update:modelValue", newValue);
            // },

            disabled: function (val) {
                this.config.disabled = val;
                if (!this.isLoaded) return;
                this.instance.option('disabled', val);
            },

            dataSource: function (ds) {
                this.config.dataSource = ds;
                if(this.isLoaded){
                    this.instance.option("dataSource", ds);
                }
                else{
                    this.loadWidget();
                }
            }
        },

        methods: {

            loadWidget: function () {
                if(!_.isNil(this.modelValue) && this.config.value != this.modelValue)
                    this.config.value = this.modelValue;
                $(this.$el).dxDropDownBox(this.config);
                this.isLoaded = true;
            },

            onValueChanged(e) {
                const self = this;
                if (self.config && self.config.onValueChanged && _.isFunction(self.config.onValueChanged))
                    self.config.onValueChanged(e);
                else
                    self.$emit("valueChanged", e);
                self.dxValue = e.value;
            }
        }
    }
</script>