<template>
    <div
        :class="classAttr"
        tabindex="-1"
        @focus.self="onComponentFocus"
        @keydown.enter="$emit('enter-keydown', $event)"
        @keypress.enter="$emit('enter-keypress', $event)">
        <slot name="prepend">
            <div v-if="inputGroup && (prependText || prependIcon)" class="input-group-prepend">
                <span class="input-group-text">
                    <FontAwesomeIcon v-if="prependIcon" :icon="prependIcon" />
                    <template v-else>{{prependText}}</template>
                </span>
            </div>
        </slot>
        <select ref="selectElement"
            :id="elementIdAttr"
            :automation_id="automationId"
            :class="selectClassAttr"
            :disabled="isDisabled"
            :tabindex="tabindex"
            v-model="currentValue"
            @change="onSelectChange"
        >
            <option v-if="defaultSelectOption.enabled && defaultSelectOption.visible" :value="defaultSelectOption.value" :disabled="defaultSelectOption.disabled">{{defaultSelectOption.text}}</option>
            <option v-if="eventItem.enabled">{{eventItem.text}}</option>
            <option v-for="(item, index) in selectItems" :key="item.key" :value="item.value" :hidden="!item.visible">
                {{ formatDisplay(item, index) }}
            </option>
        </select>
        <slot name="append">
            <div v-if="inputGroup && (appendText || appendIcon)" class="input-group-append">
                <span class="input-group-text">
                    <FontAwesomeIcon v-if="appendIcon" :icon="appendIcon" />
                    <template v-else>{{appendText}}</template>
                </span>
            </div>
        </slot>
        <span class="rq-clear-value-icon dx-icon dx-icon-clear" v-show="clearValueIconVisible" @click="onClearValueIconClicked"></span>
    </div>
</template>
<script>
    class RQSelectOption {
        constructor(options) {
            let opts = _.isString(options) || _.isNumber(options)
                ? { text: options.toString(), value: options }
                : options || {};
            this.data = _.isUndefined(opts.data) ? null : opts.data;
            this.text = _.isString(opts.text) ? opts.text : "Select...";
            this.value = _.isUndefined(opts.value) ? null : opts.value;
            this.enabled = _.parseBool(opts.enabled, true);    //pertains mostly to the default or event item/value configuration, enabling/disabling feature
            this.disabled = _.parseBool(opts.disabled);  //bound to an item (default or otherwise) option's "disabled" attribute
            this.visible = _.parseBool(opts.visible, true);
            this.onSelected = opts.onSelected || function () { };
            this.key = _.uniqueId();
        }

        static isValidNumberValue(value){
            return _.isNumber(value) || (!_.isNil(value) && !isNaN(value) && !_.eq(value, "") && !_.isBoolean(value));
        }

        static createWithNumber(item, textField, valueField, selectedHandler=null){
            if(_.isString(item) || _.isNumber(item))
                return RQSelectOption.createBasic(item, selectedHandler);

            let itemData = _.cloneDeep(item);
            let bindingItem = _.cloneDeep(item);
            let itemValue = itemData[valueField];
            return new RQSelectOption({
                data: itemData,
                text: itemData[textField],
                value: _.parseNumber(itemValue),
                onSelected: selectedHandler
            });
        }

        static createBasic(item, selectedHandler=null) {
            return new RQSelectOption({ data: item, text: item.toString(), value: item, onSelected: selectedHandler });
        }

        static create(item, textField, valueField, selectedHandler=null){
            if(_.isString(item) || _.isNumber(item))
                return RQSelectOption.createBasic(item, selectedHandler);

            return new RQSelectOption({
                data: _.cloneDeep(item),
                text: item[textField],
                value: item[valueField],
                onSelected: selectedHandler
            });
        }
    }

    import RqInputMixin from "./RqInputMixin.js";

    export default {
        mixins: [RqInputMixin],
        inheritAttrs: false,
        props: {
            modelValue: { default: null },
            displayValue: { default: null },
            cssClass: { type: String, default: "form-control" },
            items: { type: Array, default: function () { return []; } },
            defaultValue: { default: null },
            defaultItem: { default: function () { return new RQSelectOption({ enabled: true }); } },
            defaultItemEnabled: { type: Boolean, default: true },
            defaultItemDisabled: { default: null },
            defaultItemVisible: { default: null },
            eventItem: { default: function () { return new RQSelectOption({ enabled: false }); } },
            dataValueField: { type: String, default: "id" },
            dataTextField: { type: String, default: "name" },
            displayTemplate: { type: Function, default: null },
            showClearValueIcon: { type: Boolean, default: false },
            ignoreUserAccess: { type: Boolean, default: false }, //set to true if the field isn't rqUtilsized to edit data affected by readonly user access (e.g. search, filters, etc.)
            focusOnMount: { type: Boolean, default: false },
            tabindex: { default: "0" },
            size: { type: String, default: null }
        },

        data: function () {
            const self = this;
            return {
                originalValue: null,
                currentValue: null,
                previousValue: null,
                defaultSelectOption: new RQSelectOption({ enabled: true }),
                eventSelectOption: new RQSelectOption({ enabled: false }),
                selectItems: [],
                databound: false,
                valuebound: false,
                allNumberValues: false,
                allBasicItems: false,
                setFocusOnMounted: false
            }
        },

        computed: {
            classAttr() {
                let classes = ["rq-selectbox-container", this.defaultSelectedClass];
                if(this.size === "sm")
                    classes.push("rq-selectbox-sm");
                if(this.inputGroup) {
                    classes.push("input-group");
                    if(this.size === "sm")
                        classes.push("input-group-sm");
                }
                return classes;
            },
            selectClassAttr() {
                let classes = [];
                if(!_.isEmpty(this.cssClass))
                    classes.push(this.cssClass);
                if(!_.includes(this.cssClass, "form-control"))
                    classes.push("form-control");
                if(this.size === "sm" && !_.includes(this.cssClass, "form-control-sm"))
                    classes.push("form-control-sm");
                return classes;
            },
            isDirty() { return this.valuebound && this.originalValue !== this.currentValue; },
            isDisabled() { return this.disabled || (!this.ignoreUserAccess && this.userAccess.readOnly); },
            isDefaultSelected() {
                let defVal = this.getDefaultValue();
                return this.defaultItemEnabled
                    && (_.isNil(this.modelValue)
                        || this.modelValue === defVal
                        || (_.isString(this.modelValue)
                            && _.isEmpty(this.modelValue))
                        || (defVal !== null
                            && !isNaN(this.modelValue)
                            && _.parseNumber(this.modelValue, 0) === _.parseNumber(defVal, 0)));
            },
            defaultSelectedClass() { return this.isDefaultSelected ? "rq-default-selected" : "rq-default-not-selected"; },
            clearValueIconVisible() {
                let currentDefaultValue = this.getDefaultValue();
                return !this.disabled && this.showClearValueIcon && this.databound && (this.currentValue !== currentDefaultValue) && (this.ignoreUserAccess || !this.userAccess.readOnly);
            },
            selectedIndex() {
                return this.currentValue === this.getDefaultValue() && this.defaultItemEnabled ? 0
                    : _.isNil(this.currentValue) ? -1
                        : _.findIndex(this.selectItems, i => i === this.currentValue || i.value === this.currentValue || i[this.dataValueField] === this.currentValue);
            },
            selectedItem() { return _.gte(this.selectedIndex, 0) ? this.selectItems[this.selectedIndex] : null; }
        },

        created: function () { this.init(); },

        mounted: function () {
            const self = this;
            if(self.items.length > 0)
                self.dataBind();
            self.$nextTick(() => {
                if(self.focusOnMount) self.setFocus();
                if(!self.databound) {
                    let validValue = self.verifyValueIntegrity(self.modelValue);
                    if (self.modelValue !== validValue || self.currentValue !== validValue)
                        self.setValue(validValue);
                }
            });
        },

        watch: {
            items: function (newVal, oldVal) {
                if (newVal === oldVal) return;
                this.databound = false;
                this.currentValue = this.getDefaultValue();
                this.dataBind();
            },
            modelValue: function (newVal, oldVal) {
                if (newVal === oldVal || newVal === this.currentValue) return;
                this.setValue(newVal);
            },
            currentValue: function (newVal, oldVal) {
                const self = this;

                let currentDefaultValue = self.getDefaultValue();
                if (self.eventSelectOption.enabled && self.eventSelectOption.text === newVal) {
                    self.eventSelectOption.onSelected();
                    self.currentValue = currentDefaultValue;
                }

                if(!self.databound || newVal === oldVal) return;

                self.previousValue = oldVal;

                let validValue = self.verifyValueIntegrity(newVal);

                if (validValue !== self.modelValue) {
                    self.$emit("update:modelValue", validValue);
                }

            },
            defaultItem: {
                handler: function (newVal, oldVal) {
                    if (!_.isEqual(newVal, oldVal)) {
                        this.defaultSelectOption = new RQSelectOption(this.defaultItem);
                    }
                },
                deep:true
            }
        },

        methods: {

            init() {
                const self = this;
                self.userAccess = self.userAccess || { none: false, readOnly: false, full: true };
                let defaultItem = _.cloneDeep(self.defaultItem);
                if(_.isBoolean(self.defaultItemEnabled)) defaultItem.enabled = self.defaultItemEnabled;
                if(_.isBoolean(self.defaultItemDisabled)) defaultItem.disabled = self.defaultItemDisabled;
                if(_.isBoolean(self.defaultItemVisible)) defaultItem.visible = self.defaultItemVisible;
                if(!_.isNil(self.defaultValue)) defaultItem.value = self.defaultValue;
                self.defaultSelectOption = new RQSelectOption(defaultItem);
                self.eventSelectOption = new RQSelectOption(self.eventItem);
            },

            dataBind() {
                const self = this;
                let dataItems = self.getValidItemData();
                if (_.isEmpty(dataItems)) {
                    if(self.selectItems.length > 0){
                        self.selectItems = [];
                        self.setValue(null);
                    }
                    self.databound = false;
                    return;
                }

                self.allBasicValues = _.every(dataItems, item => _.isString(item) || _.isNumber(item));

                if(self.allBasicValues) {
                    self.selectItems = _.map(dataItems, item => RQSelectOption.createBasic(item));
                }
                else {
                    self.allNumberValues = _.every(dataItems, item => RQSelectOption.isValidNumberValue(item[self.dataValueField]));

                    if (self.allNumberValues)
                        self.selectItems = _.map(dataItems, item => RQSelectOption.createWithNumber(item, self.dataTextField, self.dataValueField));
                    else
                        self.selectItems = _.map(dataItems, item => RQSelectOption.create(item, self.dataTextField, self.dataValueField));
                }
                self.databound = (dataItems.length > 0);
                let validValue = self.verifyValueIntegrity(self.modelValue);
                if (self.modelValue !== validValue || self.currentValue !== validValue)
                    self.setValue(validValue);
            },

            getValidItemData() {
                if(_.isArray(this.items))
                    return this.items;
                if(!_.isNil(this.items))
                    console.warn(`rqSelectBox :: ${this.automation_id} -- "items" prop is an invalid type -- Make sure you're using "v-bind:items" or ":items" when assigning this prop's value.`)
                return [];
            },

            verifyValueIntegrity(val) {
                const self = this;
                let currentDefaultValue = self.getDefaultValue();
                return (val !== currentDefaultValue && self.databound && !self.itemValueExists(val))
                    ? currentDefaultValue
                    : val;
            },

            getDefaultValue(){
                const self = this;
                if(self.databound) {
                    let firstOption = self.selectItems[0];
                    let firstOptionValue = firstOption ? firstOption.value : null;
                    return self.defaultSelectOption.enabled ? self.defaultSelectOption.value : firstOptionValue;
                }
                return self.defaultSelectOption.enabled ? self.defaultSelectOption.value : null;
            },

            setValue(val) {
                const self = this;

                if (!self.databound) return;

                let currentDefaultValue = self.getDefaultValue();
                let selectValue = val;
                if (val === "" || val === null || val === "null" || typeof val === "undefined")
                    selectValue = currentDefaultValue;
                else if (!isNaN(val) && self.allNumberValues)
                    selectValue = Number(val);

                if(!self.valuebound){
                    self.originalValue = selectValue;
                    self.valuebound = true;
                }
                self.currentValue = selectValue;
            },

            setFocus(){
                let selectElement = _.get(this, "$refs.selectElement", null);
                if(!selectElement) return;
                selectElement.focus();
            },

            emitChanged(newVal) {
                const self = this;
                let selectedIndex = this.getItemIndex(newVal);
                let selectedItem = self.selectItems[selectedIndex];
                self.$emit("change", {
                    componentKey: self.componentKey,
                    originalValue: self.originalValue,
                    previousValue: self.previousValue,
                    selectedValue: _.isUndefined(newVal) ? self.currentValue : newVal,
                    selectedItem,
                    selectedIndex,
                    isDirty: self.isDirty
                });
                let newDisplayVal = _.get(selectedItem, `data.${self.dataTextField}`, "");
                self.$emit("update:displayValue", _.isNil(newVal) ? null : newDisplayVal);
            },

            onSelectChange(e) {
                this.$nextTick(() => {
                    this.emitChanged();
                });
            },

            onClearValueIconClicked() {
                this.setValue(null);
            },

            onComponentFocus() {
                this.setFocus();
            },

            updateOriginalValue(){
                this.originalValue = this.currentValue;
            },

            revertToOriginal() {
                this.currentValue = this.originalValue;
            },

            itemValueExists(val) {
                return _.some(this.selectItems, item => this.itemMatchesValue(item, val));
            },

            getItemIndex(val) {
                return _.findIndex(this.selectItems, item => this.itemMatchesValue(item, val));
            },

            itemMatchesValue(item, val) {
                let itemValue = _.get(item, "value", null);
                let dataValue = _.get(item, `data.${this.dataValueField}`, null) || _.get(item, this.dataValueField, null);
                return item === val || itemValue === val || dataValue === val;
            },

            formatDisplay(item, index) {
                if(this.displayTemplate && _.isFunction(this.displayTemplate)) {
                    return this.displayTemplate(item.data, index);
                }
                return item.text;
            }
        }
    }
</script>