<template>
    <div
        :class="{
            'btn-group': buttons,
            'btn-group-sm': buttons && size === 'sm',
            'btn-group-lg': buttons && size === 'lg',
            'form-group': !buttons
        }" role="group">

        <ConditionalParent
            v-for="(rdo, index) in radios"
            :key="`radio_${index}`"
            :use-wrapper="(!buttons || hasTooltips)"
            :id="`${rdo.id}_container`"
            :classAttr="{
                'btn-group': buttons && hasTooltips,
                'form-check': !buttons,
                'form-check-inline': !buttons && inline
            }">
            <input
                ref="radioInputs"
                :id="rdo.id"
                :automation_id="rdo.automation_id"
                :name="name"
                type="radio"
                :class="{
                    'btn-check': buttons,
                    'form-check-input': !buttons
                }"
                autocomplete="off"
                :disabled="(disabled || rdo.disabled)"
                :value="rdo.value"
                v-model="boundValue">
            <label
                :id="`${rdo.id}_label`"
                :class="{
                    [`btn btn-${buttonVariant}`]: buttons,
                    'btn-sm': buttons && size === 'sm',
                    'btn-lg': buttons && size === 'lg',
                    'form-check-label': !buttons,
                    'active': rdo.value === boundValue
                }"
                :for="rdo.id">
                <slot name="option-label" :option="rdo">
                    <span v-html="rdo.text" />
                </slot>
            </label>
        </ConditionalParent>
        <b-tooltip
            v-for="(tip, index) in tooltips"
            :key="`tooltip_${index}`"
            :target="tip.target">
            <span v-html="tip.text" />
        </b-tooltip>
    </div>
</template>
<script setup>
    import { ref, computed, onMounted, nextTick } from "vue";
    import ConditionalParent from "./ConditionalParent.vue";

    const props = defineProps({
        name: { type: String, default: () => _.uniqueId("rq-radio-group-") },
        options: { type: Array, default: () => [] },
        buttonVariant: { type: String, default: "secondary" },
        size: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        buttons: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
        ignoreTooltipValues: { type: Boolean, default: false },
        autoFocus: { type: Boolean, default: false },
        modelValue: { default: null }
    });

    const emit = defineEmits([
        "input",
        "change",
        "update:modelValue"
    ]);

    const boundValue = computed({
        get() { return props.modelValue; },
        set(val) {
            emit("input", val);
            emit("change", val);
            emit("update:modelValue", val);
        }
    });

    const radios = computed(() => _.map(props.options, o => ({
        id: _.uniqueId("rq-radio-"),
        ...o
    })));

    const tooltips = computed(() => {
        if(props.ignoreTooltipValues) return [];
        let withTooltips = _.filter(radios.value, r => !_.isEmpty(r.tooltip));
        return _.map(withTooltips, r => ({ target: `#${r.id}_container`, text: r.tooltip }));
    });

    const hasTooltips = computed(() => tooltips.value?.length > 0);

    const radioInputs = ref([]);

    if(props.autoFocus && !props.disabled) {
        onMounted(async () => {
            await nextTick();
            radioInputs.value?.[0]?.focus();
        });
    }
</script>