<template>
    <PromptInputFormGroup
        :label="label"
        :label-for="idAttrs.id"
        :is-required="isRequired"
        :show-banner="showBanner"
        :has-error="hasError"
        :validation-messages="validationMessages"
        :alerts="alerts">
        <rqdx-date-box
            :input-attr="idAttrs"
            :type="type"
            v-bind="editorOptions"
            v-model="inputValue"
        />
    </PromptInputFormGroup>
</template>
<script setup>
    import { usePromptInputProps, usePromptInput } from "./composables";
import PromptInputFormGroup from "./PromptInputFormGroup.vue";

    const props = defineProps({
        ...usePromptInputProps("dt_prompt_date_input"),
        type: { type: String, default: "date" },
        editorOptions: { type: Object, default: () => ({}) }
    });

    const {
        idAttrs,
        inputValue,
        showBanner,
        hasError,
        validationMessages,
        isValid
    } = usePromptInput(props);

    defineExpose({ isValid, inputValue });
</script>