<template>
    <div class="rq-error-container p-3">
        <div class="rq-error-message mb-3 lead">{{message}}</div>
        <div v-if="showDetails" class="card">
            <div class="card-header">
                <span>Error Details</span>
                <button type="button" class="btn-icon me-1 pull-right" @click="onToggleDetailsClick">
                    <FontAwesomeIcon :icon="detailsVisible ? 'fas fa-minus':'fas fa-plus'" />
                </button>
            </div>
            <b-collapse id="error-detail-collapse" v-model="detailsVisible">
                <div class="card-block">
                    <dl class="row">
                        <dt class="col-sm-3">Info</dt>
                        <dd class="col-sm-9">{{info}}</dd>

                        <template v-if="errorMessage">
                            <dt class="col-sm-3">Error Message</dt>
                            <dd class="col-sm-9">{{errorMessage}}</dd>
                        </template>
                    </dl>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RQErrorDialog',
    props: {
        args: {}
    },
    data() {
        const self = this;
        return {
            title: "Error",
            message: "",
            info: "",
            error: null,
            dismissable: true,
            reloadable: false,
            showDetails: false,
            detailsVisible: false,
            errorMessage: ""
        }
    },

    mounted() {
        if(!this.args) return;
        this.message = this.args.message || "An error occurred preventing the current action from completing.  Click the button below to reload the application. ";
        this.info = this.args.info || "";
        this.error = this.args.error || null;
        this.dismissable = _.isBoolean(this.args.dismissable) ? this.args.dismissable : true;
        this.reloadable = _.isBoolean(this.args.reloadable) ? this.args.reloadable : false;
        this.showDetails = _.isBoolean(this.args.showDetails) ? this.args.showDetails : false;

        if(this.error){
            this.errorMessage = this.error.message || this.error.errorMessage;
        }

        this.initFooter();
    },

    methods: {
        initFooter(){
            const self = this;
            if(!this.dismissable && !this.reloadable) return;
            self.$emit('use-custom-footer', {
                component: {
                    name: 'error-footer',
                    template: `
                        <div class="w-100">
                            <div class="footer-buttons pull-right">
                                <button v-show="okVisible" type="button" class="btn btn-sm btn-primary" @click="onOkClick">Ok</button>
                                <button v-show="reloadVisible" type="button" class="btn btn-sm btn-primary" @click="onReloadClick">Reload</button>
                            </div>
                        </div>`,
                    computed: {
                        okVisible () { return self.saveComplete; },
                        reloadVisible () { return self.reloadable; }
                    },
                    methods: {
                        onOkClick(){ self.dismiss(); },
                        onCloseClick(){ self.dismiss(); },
                        onToggleDetailsClick(){ self.detailsVisible = !self.detailsVisible; },
                        onReloadClick(){ self.reload(); }
                    }
                }
            });

        },
        dismiss() { this.$emit("close-dialog"); },
        reload(){ window.document.location.reload(); }
    },
}
</script>