<template>

    <div class="rq-container">
        <rq-banner
            message="Please correct the highlighted error to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && hasError"
            dismissable
        />
        <PromptAlerts :alerts="beforeAlerts" />
        <div class="form-group mt-3" :class="{ 'form-required': isRequired, 'has-error': hasError }">
            <label v-if="label" :for="labelFor">{{label}}</label>
            <slot></slot>
            <rq-validation-feedback :messages="validationMessages" />
        </div>
        <PromptAlerts :alerts="afterAlerts" />
    </div>
</template>

<script setup>
    import { computed } from "vue";
    import PromptAlerts from "./PromptAlerts.vue";

    const props = defineProps({
        showBanner: { type: Boolean, default: false },
        hasError: { type: Boolean, default: false },
        isRequired: { type: Boolean, default: false },
        label: { type: String, default: "" },
        labelFor: { type: String, default: "" },
        alerts: { type: Array, default: () => [] },
        validationMessages: { type: Object, default: () => ({}) }
    });

    const beforeAlerts = computed(() => _.filter(props.alerts, a => _.toLower(a.position) !== "after"));
    const afterAlerts = computed(() => _.filter(props.alerts, a => _.toLower(a.position) === "after"));
</script>