<template>
    <div :class="classAttr">
        <rq-step-progress-item
            v-for="(step, index) in steps"
            :key="index"
            :index="index"
            :step="step"
            :current-index="stepIndex"
            :max-complete-index="maxCompleteIndex"
            :hide-connector="index === lastIndex"
            @item-click="onItemClick"
        />
    </div>
</template>

<script>
    import { STEP_STATUS } from "../models";

    const isStatusComplete = s => s.status === STEP_STATUS.Complete;
    const isStatusError = s => s.status === STEP_STATUS.Error;

    export default {
        name: "RqStepProgress",
        props: {
            steps: { type: Array, default: () => [] },
            stepIndex: { type: Number, default: 0 }
        },
        computed: {
            lastIndex() { return this.steps.length - 1; },
            maxCompleteIndex() { return _.findLastIndex(this.steps, isStatusComplete); },
            hasErrorStatus() { return _.some(this.steps, isStatusError)},
            classAttr() {
                return {
                    "rq-step-progress": true,
                    "rq-step-has-error": this.hasErrorStatus
                };
            }
        },
        methods: {
            onItemClick(e) {
                this.$emit("item-click", e);
            }
        }
    }
</script>