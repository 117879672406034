export default {
    props: {
        dataSource: { type: Object, default: () => ({ load: () => Promise.resolve([]) }) },
        caretIcon: { type: String, default: "fas fa-caret-right" },
        caretRotate: { type: String, default: "fa-rotate-90" },
        caretRotateOn: { type: String, default: "expand" },
        expandSelected: { type: Boolean, default: false },
        expandedKeys: { type: Array, default: null },
        errorTooltipPlacement: { type: String, default: "top" },
    },
    data() {
        return {
            expandedKeysValue: []
        };
    },
    watch: {
        expandedKeys: {
            handler(newValue=null) {
                if(!_.differs(newValue, this.expandedKeysValue)) return;
                this.expandedKeysValue = _.isNil(newValue) ? [] : newValue;
                this.onExpandedKeysUpdate(newValue);
            },
            immediate: true
        },
        expandedKeysValue(newValue=null) {
            if(!_.isNil(this.expandedKeys) && !_.differs(newValue, this.expandedKeys)) return;
            this.$emit("update:expandedKeys", newValue);
            this.onExpandedKeysValueUpdate(newValue);
        },
    },
    methods: {
        loadFromDataSource(item) {
            const self = this;
            const compName = self.$options.name || "RqTreeView";
            if(!_.isFunction(self.dataSource.load)) {
                self.notify(`Invalid load function in ${compName} dataSource.`);
                return Promise.resolve(self.item.children);
            }
            let loadResult = self.dataSource.load(item) || [];
            if(_.isArray(loadResult)) return Promise.resolve(loadResult);
            if(_.isObject(loadResult) && _.isFunction(loadResult.then)) return loadResult;
            self.notify(`Invalid load function return value in ${compName} dataSource.  The load function must return an Array or a Promise that resolves an Array.`);
            return Promise.resolve([]);
        },

        onExpandedKeysUpdate(keys) {},

        onExpandedKeysValueUpdate(keys) {},

        notify(message, exception){
            if(!exception) {
                console.warn(message);
                return;
            }
            console.error(message, exception);
            this.$toast.error(message);
        }
    }
};