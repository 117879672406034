<template>
    <b-dropdown
        :size="bsSize"
        :variant="bsVariant"
        :disabled="disabled">
        <template #button-content>{{displayValue}}</template>
        <b-dropdown-item-button
            v-for="(item,index) in items"
            :key="getValue(item)"
            @click="updateValue(item,index)">
            {{getDisplay(item, index, displayExpr, displayTemplate)}}
        </b-dropdown-item-button>
    </b-dropdown>
</template>

<script>
    export default {
        props: {
            modelValue: { default: null },
            items: { type: Array, default: () => [] },
            buttonText: { type: String, default: "Select..." },
            valueExpr: { type: String, default: "id" },
            displayExpr: { type: String, default: "name" },
            buttonTextExpr: { type: String, default: "name" },
            displayTemplate: { type: Function, default: null },
            buttonTextTemplate: { type: Function, default: null },
            bsSize: { type: String, default: null },
            bsVariant: { type: String, default: "secondary" },
            disabled: { type: Boolean, default: false },

            /*
                Set behavior="select" to act like a select-box.  By default it will behave
                like a bs dropdown and simply emit the selected value via "select" event
            */
            behavior: { type: String, default: "default" }
        },
        data() {
            return {
                selectedItem: null,
                selectedIndex: 0,
                boundValue: null
            }
        },
        computed: {
            showSelectBoxBehavior() { return this.behavior === "select"; },
            displayValue() { return this.showSelectBoxBehavior ? this.getSelectedDisplay() : this.buttonText; }
        },
        watch:{
            modelValue: {
                handler(newValue, oldValue) {
                    this.selectedItem = this.getItemByValue(newValue);
                    if(newValue === oldValue || newValue === this.boundValue) return;
                    this.boundValue = newValue;
                },
                immediate: true
            },
            boundValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;
                this.$emit("update:modelValue", newValue);
            }
        },
        methods: {
            updateValue(item, index) {
                this.boundValue = this.getValue(item);
                this.selectedIndex = index;
                this.$nextTick(() => {
                    this.emitItemEvent("change", item, index);
                });
            },
            getDefaultDisplay(item, expr=null) {
                let displayExpr = expr || this.displayExpr;
                return !_.isNil(item)
                    ? this.getParamValue(item, displayExpr)
                    : this.buttonText;
            },
            getSelectedDisplay() {
                return this.getDisplay(
                    this.selectedItem,
                    this.selectedIndex,
                    this.buttonTextExpr,
                    this.buttonTextTemplate
                );
            },
            getDisplay(item, index, expr=null, templateFunc=null) {
                return _.isNil(templateFunc) || !_.isFunction(templateFunc)
                    ? this.getDefaultDisplay(item, expr)
                    : templateFunc({
                        selectedItem: _.toPlainObject(item),
                        selectedIndex: index,
                        items: this.items.slice()
                    });
            },
            getValue(item) { return this.getParamValue(item, this.valueExpr); },
            getSelectedValue() { return this.getValue(this.selectedItem); },
            getParamValue(item, prop) { return _.isObject(item) ? item[prop] : item; },
            getItemByValue(val) { return _.find(this.items, item => val === this.getValue(item)); },
            emitItemEvent(eventName, selectedItem, selectedIndex) { this.$emit(eventName, { selectedItem, selectedIndex }); },
            onItemClick(item, index) {
                if(this.showSelectBoxBehavior)
                    this.updateValue(item, index);
                else
                    this.emitItemEvent("select", item, index);
            }
        }
    }
</script>