<template>
    <div :class="classAttr" :style="styleAttr">
        <div :class="spinnerClassAttr">
            <div v-for="n in spinnerPartCount" :key="n" :class="getPartClass(n)"></div>
        </div>
        <div v-if="message && !forInput" class="rq-loading-message">
            <span v-html="message"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RqLoadingIndicator",
        props: {
            visible: { type: Boolean, default: false },
            type: { type: String, default: "bars" },
            theme: { type: String, default: null },
            inRqBusy: { type: Boolean, default: false },
            forInput: { type: Boolean, default: false },
            offset: { type: Number, default: null },
            message: { type: String, default: null }
        },

        data() {
            return {
                spinnerTypes: ["bars", "circle"],
            }
        },

        computed: {
            isVisible() { return this.inRqBusy ? this.visible : (!this.$rqBusy.isBusy() && this.visible); },
            currentTheme() { return this.theme || _.get(this, "$route.meta.theme", "") || "default"; },
            classAttr() {
                return {
                    [`theme-${this.currentTheme}`]: true,
                    "rq-loading-indicator": !this.forInput,
                    "input-spinner": this.forInput,
                    //input-spinner is shown/hidden via "form-loading" class on "form-group"
                    "d-none": !this.forInput && !this.isVisible
                };
            },
            spinnerType() { return this.forInput ? "circle" : _.includes(this.spinnerTypes, this.type) ? this.type : "bars"; },
            spinnerPartCount() { return this.spinnerType === "circle" ? 12 : 5; },
            spinnerClassAttr() {
                return {
                    "sk-spinner": true,
                    "sk-fading-circle": this.spinnerType === "circle",
                    "sk-stretch-bars": this.spinnerType !== "circle"
                };
            },
            styleAttr() { return _.parseNumber(this.offset,0) > 0 ? `transform:translateX(-${this.offset}px);` : ""; }
        },

        methods: {
            getPartClass(n) {
                const self = this;
                switch(self.spinnerType) {
                    case "circle":
                        return `sk-circle${n} sk-circle`;
                    case "bars":
                        return `rect${n}`;
                }
            }
        }
    }
</script>