<template>
    <span
        v-if="count > 0"
        :id="elementId"
        :automation_id="automation_id"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave">
        <span class="fa-stack tab-number">
            <FontAwesomeIcon icon="fas fa-circle" class="fa-stack-2x tab-icon" />
            <strong class="fa-stack-1x tab-count">{{count}}</strong>
        </span>
        <DxPopover
            v-if="popoverEnabled"
            :target="`#${elementId}`"
            position="top"
            :wrapper-attr="{ class: popoverClassAttr }"
            :visible="popoverVisible">
            <div v-for="(msg, index) in messages"
                :key="index"
                class="rq-alert-message">{{msg}}</div>
        </DxPopover>
    </span>
</template>
<script setup>
    import { ref, computed, watch } from "vue";
    const props = defineProps({
        automation_id: { type: String, default: "" },
        count: { type: Number, default: 0 },
        variant: { type: String, default: "error" },
        messages: { type: Array, default: () => [] },
        tabActive: { type: Boolean, default: false },
        visible: { type: Boolean, default: false }
    });
    const emit = defineEmits(["update:visible"]);

    const popoverVisible = ref(false);
    const elementId = ref(_.uniqueId("alert-icon-"));

    const popoverEnabled = computed(() => !props.tabActive && !_.isEmpty(props.messages));
    const popoverClassAttr = computed(() => `rq-popover rq-popover-${props.variant} rate-alert-popover`);

    watch(popoverVisible, newVal => {
        emit("update:visible", newVal);
    });

    const onMouseEnter = () => popoverVisible.value = true;
    const onMouseLeave = () => popoverVisible.value = false;
</script>
