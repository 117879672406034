<template>
    <div :class="classAttr">
        <div
            class="rq-step-display"
            @click="onDisplayClick">
            <div class="rq-step-icon">
                <div class="rq-step-number">
                    <FontAwesomeIcon icon="far fa-circle" />
                    {{stepNumber}}
                </div>
                <FontAwesomeIcon icon="fas fa-circle-check" class="rq-step-check" />
                <div
                    v-if="hasError"
                    class="rq-step-error"
                    v-rq-tooltip="{
                        boundary: 'body',
                        container: 'body',
                        triggers: 'hover',
                        placement: 'topright',
                        variant: 'danger',
                        title: errorMessage
                    }">
                    <FontAwesomeIcon icon="fas fa-exclamation-circle" />
                </div>
            </div>
            <div class="rq-step-label">{{label}}</div>
        </div>
        <!-- <div v-if="errorMessage" class="rq-step-error-message">{{errorMessage}}</div> -->
        <div v-if="!hideConnector" class="rq-step-connector"></div>
    </div>
</template>

<script>
    import { STEP_STATUS } from "../models";

    export default {
        name: "RqStepProgressItem",
        props: {
            index: { type: Number, default: 0 },
            step: { type: Object, default: () => ({}) },
            currentIndex: { type: Number, default: 0 },
            maxCompleteIndex: { type: Number, default: 0 },
            hideConnector: { type: Boolean, default: false },
            allowClickAhead: { type: Boolean, default: false },
        },
        computed: {
            label() { return _.get(this, "step.label", null) || "(No Label Provided)"; },
            status() { return _.get(this, "step.status", null) || STEP_STATUS.Incomplete; },
            hasError() { return this.status === STEP_STATUS.Error; },
            errorMessage: {
                get() { return _.get(this, "step.errorMessage", null); },
                set(val) { this.step.errorMessage = val; }
            },
            stepNumber() { return this.index + 1; },
            isActive() { return this.currentIndex === this.index; },
            classAttr() {
                return {
                    "rq-step": true,
                    "rq-step-active": this.isActive,
                    "rq-step-connected": this.maxCompleteIndex > this.index || this.currentIndex > this.index,
                    "rq-step-complete": this.status === STEP_STATUS.Complete && !this.isActive,
                    "rq-step-alert": this.status === STEP_STATUS.Alert,
                    "rq-step-error": this.status === STEP_STATUS.Error,
                };
            }
        },
        watch: {
            status(newVal, oldVal) {
                if(newVal === oldVal || newVal === STEP_STATUS.Error) return;
                this.errorMessage = "";
            }
        },
        methods: {
            onDisplayClick() {
                if(this.currentIndex <= this.index && !this.allowClickAhead) return;
                this.$emit("item-click", { index: this.index, step: this.step });
            }
        }
    }
</script>
