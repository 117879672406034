<template>
    <div :class="inputGroupClassAttr">
        <slot name="prepend">
            <span v-if="prependInputGroup" class="input-group-text">
                <FontAwesomeIcon v-if="prependIcon" :icon="prependIcon" />
                <template v-else>{{prependText}}</template>
            </span>
        </slot>
        <span v-if="showClearButton && prependInputGroup" class="rq-clear-value-icon dx-icon dx-icon-clear" v-show="clearButtonVisible" @click="onClearValue"></span>
        <input
            ref="inputElement"
            :id="id"
            :automation_id="automationId"
            type="text"
            :class="inputClassAttr"
            :disabled="disabled"
            :readonly="readonly"
            :autocomplete="autocomplete ? 'on' : 'chrome-off'"
            :maxlength="maxChars"
            :tabindex="tabIndex"
            v-model="boundValue"
            @keyup="onKeyUp"
            @keydown="onKeyDown"
            @keypress="onKeyPress"
            @focus="onFocus"
            @blur="onBlur"
            @drop="onDrop"
            @change="onChange"
        />
        <span v-if="showClearButton && (!inputGroup || appendInputGroup)" class="rq-clear-value-icon dx-icon dx-icon-clear" v-show="clearButtonVisible" @click="onClearValue"></span>
        <slot name="append">
            <span v-if="appendInputGroup" class="input-group-text">
                <FontAwesomeIcon v-if="appendIcon" :icon="appendIcon" />
                <template v-else>{{appendText}}</template>
            </span>
        </slot>
    </div>
</template>

<script>
    import RqInputMixin from "./RqInputMixin.js"; //contains common props for input elements

    export default {
        mixins: [RqInputMixin],
        props: {
            formatType: { type: String, default: "number" }, //other possible values: 'money', 'percentage"
            defaultValue: { default: null },
            modelValue: { default: null },
            decimals: { default: null },
            prefix: { type: String, default: null },
            suffix: { type: String, default: null },
            validator: { type: Object, default: null },
            allowNull: { type: Boolean, default: false },
            maxValue: { default: null },
            minValue: { default: null },
            maxChars: { default: null },
            valueEvent: { type: String, default: "blur" },
            noPrefix: { type: Boolean, default: false },
            readonly: { type: Boolean, default: false },
            commas: { type: Boolean, default: true },
            noFormControlClass: { type: Boolean, default: false },
            textAlign: { type: String, default: null },
            tabIndex: { default: null },
            showClearButton: { type: Boolean, default: false }
        },
        emits: ["input", "blur", "change", "keyup", "keydown", "keypress", "focus", "clear-value", "update:modelValue"],
        data() {
            return {
                valueInternal: this.modelValue,
                focused: false
            };
        },
        computed: {
            boundValue: {
                get(){ return this.focused ? this.valueInternal : this.format(this.valueInternal); },
                set(val) { this.valueInternal = this.numberValue(val); }
            },
            instance(){ return this.$refs.inputElement; },
            minValueInternal() { return _.isNil(this.minValue) ? null : Number(this.minValue); },
            maxValueInternal() { return _.isNil(this.maxValue) ? null : Number(this.maxValue); },
            emitValueOnBlur() { return this.valueEvent === "blur"; },
            emitValueOnInput() { return this.valueEvent === "input"; },
            prependInputGroup() { return this.inputGroup && (!_.isEmpty(this.prependText) || !_.isEmpty(this.prependIcon)); },
            appendInputGroup() { return this.inputGroup && (!_.isEmpty(this.appendText) || !_.isEmpty(this.appendIcon)); },
            clearButtonVisible() { return this.showClearButton && !this.disabled && !this.readonly && this.boundValue !== this.defaultValue; },
            inputGroupClassAttr() {
                return {
                    "rq-input-number": true,
                    "rq-input-number-clear-visible": this.clearButtonVisible,
                    "input-group": this.inputGroup,
                    "input-group-sm": this.inputGroup && this.size === "sm"
                };
            },
            inputClassAttr() {
                let defaultClasses = {
                    "text-right": this.textAlign === "right",
                    "text-center": this.textAlign === "center"
                };
                if(this.noFormControlClass) return defaultClasses;
                return _.assign({}, {
                    [this.cssClass]: true,
                    "form-control": true,
                    "form-control-sm": this.size === "sm" && !_.includes(this.cssClass, "form-control-sm")
                }, defaultClasses);
            }
        },
        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.valueInternal) return;
                    this.valueInternal = this.numberValue(newValue);
                },
                immediate: true
            },
            valueInternal(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.modelValue) return;

                if(!_.isNil(this.minValueInternal) && !isNaN(this.minValueInternal) && newValue < this.minValueInternal) {
                    this.valueInternal = this.minValueInternal;
                    return;
                }

                if(!_.isNil(this.maxValueInternal) && !isNaN(this.maxValueInternal) && newValue > this.maxValueInternal) {
                    this.valueInternal = this.maxValueInternal;
                    return;
                }

                if(!this.emitValueOnInput) return;

                this.emitEvent("input");
            }
        },
        mounted() {
            this.valueInternal = this.numberValue(this.modelValue);
        },
        methods: {
            onFocus(e) {
                this.focused = !this.readonly;
                this.emitEvent("focus", e);
                if(this.readonly) return;
                this.$nextTick(() => {
                    e.target.select();
                });
            },
            onBlur(e) {
                if(this.validator !== null) { this.validator.$touch(); }
                this.focused = false;
                this.emitEvent("blur", e);
                if(this.emitValueOnBlur)
                    this.emitEvent("input");
            },
            onDrop(e) {
                let dragValue = e.dataTransfer.getData("text");
                if(_.tryParseNumber(dragValue)) return;
                e.preventDefault();
            },
            onKeyDown(e) {
                this.emitEvent("keydown", e);
            },
            onKeyUp(e) {
                this.emitEvent("keyup", e);
            },
            onKeyPress(e) {
                let currentValue = e.target.value;
                let allowDecimals = _.parseNumber(this.decimals, 0) > 0;
                let validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Tab", "Backspace", "Delete", "Control", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", " "];
                let isValid = _.includes(validKeys, e.key)
                    || (e.key === "-"
                        && !_.includes(currentValue, "-")
                        && e.target.selectionStart === 0
                    )
                    || (allowDecimals
                        && e.key === "."
                        && !_.includes(currentValue, ".")
                    );

                if(isValid){
                    this.$emit("keypress", { value:this.valueInternal, nativeEvent:e });
                    return;
                }

                e.preventDefault();
            },
            onClearValue(e) {
                this.emitEvent("clear-value", e);
                this.boundValue = this.defaultValue;
                this.$nextTick(() => {
                    this.focus();
                });
            },
            format(n) {
                if(this.allowNull && _.isNil(n)) return null;
                let decimals = accounting.parse(this.decimals);
                let numVal = this.toFixedNumber(n, decimals);

                let moneyDecimals = _.parseNumber(this.decimals, 2);

                let moneyFormat = {
                    pos: this.noPrefix ? "%v" : "%s%v",
                    neg: this.noPrefix ? "(%v)" : "(%s%v)",
                    zero: this.noPrefix ? "%v" : "%s%v",
                };
                switch(this.formatType) {
                    case "money": return accounting.formatMoney(numVal, { format: moneyFormat, precision: moneyDecimals });
                    case "percentage": return `${accounting.formatNumber(n, decimals)}%`;
                    case "basic": return numVal;
                    default: {
                        let prefix = _.isNil(this.prefix) ? "" : this.prefix;
                        let suffix = _.isNil(this.suffix) ? "" : this.suffix;
                        return `${prefix}${accounting.formatNumber(n, decimals, this.commas ? "," : "", ".")}${suffix}`;
                    }
                }
            },
            emitEvent(eventName, nativeEvent=null) {
                if(eventName === "input")
                    this.$emit("update:modelValue", this.valueInternal);
                else
                    this.$emit(eventName, { value:this.valueInternal, nativeEvent });
            },
            numberValue(val) {
                if(val === "-") return val;
                let decimals = accounting.parse(this.decimals);
                if(_.isNil(val) || _.isEmpty(val.toString()))
                    return this.allowNull ? null : this.toFixedNumber(this.defaultValue, decimals);
                return this.toFixedNumber(val, decimals);
            },
            toFixedNumber(val, dec) { return Number(accounting.toFixed(accounting.parse(val), dec)); },
            onChange(e) {
                if(this.emitValueOnBlur)
                    this.$emit("change", e);
                else
                    this.$emit("change", { value:this.valueInternal, nativeEvent:e });
            },
            focus() {
                this.instance.focus();
            },
            select() {
                this.instance.select();
            },
            focusAndSelect() {
                const self = this;
                self.focus();
                self.$nextTick()
                    .then(() => {
                        self.select();
                    });
            }
        }
    };
</script>