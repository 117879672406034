<template>
    <div id="rq-dialog-container">
        <rq-dialog v-for="options in dialogs"
            :key="options.clientKey"
            ref="dialogComponents"
            :dialog-options="options"
            @closed="onDialogClosed">
        </rq-dialog>
    </div>
</template>

<script>
    import {
        OPEN_DIALOG_EVENT,
        CLOSE_DIALOG_EVENT,
        CLOSE_ALL_DIALOGS_EVENT,
        RELOAD_DIALOG_EVENT,
        PROMPT_CONFIRM_EVENT,
        PROMPT_MESSAGE_EVENT,
        PROMPT_INPUT_EVENT,
        PROMPT_DATE_INPUT_EVENT,
        PROMPT_SELECT_EVENT,
        PROMPT_MULTISELECT_EVENT,
        RQDialogOptions
    } from "./models";
    import PromptMultiSelect from "./PromptMultiSelect.vue";
    import PromptSelect from "./PromptSelect.vue";
    import PromptTextInput from "./PromptTextInput.vue";
    import PromptDateInput from "./PromptDateInput.vue";
    import PromptMessage from "./PromptMessage.vue";

    const OMIT_MERGE_FIELDS = ["dialogId", "title", "component", "okOnly", "onOk", "onCancel"];

    export default {
        name: "rqDialogManager",
        data() {
            return {
                dialogs:[]
            }
        },
        created() {
            this.processEvents("on");
        },
        beforeUnmount() {
            this.processEvents("off");
        },
        methods: {
            processEvents(method) {
                const self = this;
                self.$events[method](OPEN_DIALOG_EVENT, self.addDialog);
                self.$events[method](CLOSE_DIALOG_EVENT, self.closeDialog);
                self.$events[method](CLOSE_ALL_DIALOGS_EVENT, self.closeAll);
                self.$events[method](RELOAD_DIALOG_EVENT, self.reloadDialog);
                self.$events[method](PROMPT_CONFIRM_EVENT, self.addConfirm);
                self.$events[method](PROMPT_MESSAGE_EVENT, self.addMessageBox);
                self.$events[method](PROMPT_INPUT_EVENT, self.addPromptInput);
                self.$events[method](PROMPT_DATE_INPUT_EVENT, self.addPromptDateInput);
                self.$events[method](PROMPT_SELECT_EVENT, self.addPromptSelect);
                self.$events[method](PROMPT_MULTISELECT_EVENT, self.addPromptMultiSelect);
            },

            addDialog(options){
                let dialogOptions = new RQDialogOptions(options);
                this.dialogs.push(dialogOptions);
                return dialogOptions.dialogId;
            },

            addMessageBox({
                dialogId,
                title,
                message,
                onOk = (e => true),
                theme = null,
                dlgOptions={}
            }) {
                let options = _.merge({}, {
                    width: (message.length > 230) ? 660 : 400,
                    adaptive: true,
                    scrollable: false,
                    okOnly: true,
                    autoFocusOkButton: true,
                    dialogTheme: theme,
                }, _.omit(dlgOptions, OMIT_MERGE_FIELDS));

                return this.addDialog({
                    ...options,
                    dialogId,
                    title,
                    component: PromptMessage,
                    props: { message },
                    onOk
                });
            },

            addConfirm({
                dialogId,
                title,
                message,
                onOk,
                onCancel=(e => true),
                dlgOptions={}
            }) {
                let options = _.merge({}, {
                    height: "auto",
                    width: 400,
                    autoFocusOkButton: true,
                    cancelTitle: "Cancel",
                    okTitle: "OK"
                }, _.omit(dlgOptions, OMIT_MERGE_FIELDS));

                return this.addDialog({
                    ...options,
                    dialogId,
                    title,
                    component: PromptMessage,
                    props: {
                        message,
                        messageType: "confirm"
                    },
                    onOk,
                    onCancel
                });
            },

            addPromptInput({
                dialogId,
                inputId,
                title="Enter a Value",
                label=null,
                value=null,
                isRequired=true,
                disabled=false,
                requiredMessage=null,
                multiline=false,
                validators={},
                width=525,
                maxInputHeight=400,
                inputType="text",
                editorOptions={},
                okTitle="OK",
                cancelTitle="Cancel",
                onOk=() => true,
                onCancel=() => true
            }) {
                return this.addDialog({
                    dialogId,
                    title,
                    width,
                    okTitle,
                    cancelTitle,
                    adaptive: true,
                    autoFocusOkButton: false,
                    props: {
                        inputId,
                        label,
                        value,
                        isRequired,
                        requiredMessage,
                        validators,
                        multiline,
                        maxInputHeight,
                        inputType,
                        editorOptions,
                        disabled
                    },
                    component: PromptTextInput,
                    onOk(e){
                        if(!e.component.isValid()) return false;
                        return onOk({ ...e, value: e.component.inputValue });
                    },
                    onCancel
                });
            },

            addPromptDateInput({
                dialogId,
                inputId,
                title="Enter a Value",
                label=null,
                value=null,
                isRequired=true,
                requiredMessage=null,
                validators={},
                width=525,
                type="date",
                editorOptions={},
                alerts=[],
                okTitle="OK",
                cancelTitle="Cancel",
                onOk=() => true,
                onCancel=() => true
            }) {
                return this.addDialog({
                    dialogId,
                    title,
                    width,
                    okTitle,
                    cancelTitle,
                    adaptive: true,
                    autoFocusOkButton: false,
                    props: {
                        inputId,
                        label,
                        value,
                        isRequired,
                        requiredMessage,
                        validators,
                        type,
                        editorOptions,
                        alerts
                    },
                    component: PromptDateInput,
                    onOk(e){
                        if(!e.component.isValid()) return false;
                        return onOk({ ...e, value: e.component.inputValue });
                    },
                    onCancel
                });
            },

            addPromptSelect({
                dialogId,
                inputId,
                title="Select a Value",
                label=null,
                items=[],
                valueExpr="id",
                displayExpr="name",
                value=null,
                isRequired=true,
                requiredMessage=null,
                validators={},
                width=525,
                alerts=[],
                okTitle="OK",
                cancelTitle="Cancel",
                onOk=() => true,
                onCancel=() => true
            }) {
                return this.addDialog({
                    dialogId,
                    title,
                    width,
                    okTitle,
                    cancelTitle,
                    adaptive: true,
                    autoFocusOkButton: false,
                    props: {
                        inputId,
                        label,
                        value,
                        valueExpr,
                        displayExpr,
                        items,
                        isRequired,
                        requiredMessage,
                        validators,
                        alerts
                    },
                    component: PromptSelect,
                    onOk(e){
                        if(!e.component.isValid()) return false;
                        return onOk({
                            ...e,
                            selectedValue: e.component.selectedValue,
                            selectedItem: _.toPlainObject(e.component.selectedItem)
                        });
                    },
                    onCancel
                });
            },

            addPromptMultiSelect({
                dialogId,
                inputId,
                title="Select One or More Values",
                label=null,
                items=[],
                valueExpr="id",
                displayExpr="name",
                value=null,
                isRequired=true,
                requiredMessage=null,
                validators={},
                width=525,
                alerts=[],
                okTitle="OK",
                cancelTitle="Cancel",
                onOk=() => true,
                onCancel=() => true
            }) {
                return this.addDialog({
                    dialogId,
                    title,
                    width,
                    okTitle,
                    cancelTitle,
                    adaptive: true,
                    autoFocusOkButton: false,
                    props: {
                        inputId,
                        label,
                        value,
                        valueExpr,
                        displayExpr,
                        items,
                        isRequired,
                        requiredMessage,
                        validators,
                        alerts
                    },
                    component: PromptMultiSelect,
                    onOk(e){
                        if(!e.component.isValid()) return false;
                        return onOk({
                            ...e,
                            selectedValues: e.component.selectedValues,
                            selectedItems: _.toPlainObject(e.component.selectedItems)
                        });
                    },
                    onCancel
                });
            },

            getDialog(dialogId) {
                return _.find(this.$refs.dialogComponents, d => d.dialogId === dialogId);
            },

            getLastOpened() {
                return _.last(this.$refs.dialogComponents, d => d.isActive);
            },

            getByContentComponentId(uid){
                let dlg = _.find(this.$refs.dialogComponents, d => d.contentComponentId === uid);
                return dlg || null;
            },

            closeDialog(dialogId) {
                this.invokeDialogMethod("close", dialogId);
            },

            removeDialog(dialogId) {
                let idx = _.findIndex(this.dialogs, d => d.dialogId === dialogId);
                if(idx < 0) return;
                this.dialogs.splice(idx, 1);
            },

            closeAll() {
                if(_.isEmpty(this.dialogs)) return;
                _.forEach(this.$refs.dialogComponents, dg => { _.invoke(dg, "close"); });
                this.dialogs = [];
            },

            reloadDialog({ dialogId=null, props }) {
                this.invokeDialogMethod("reloadComponent", dialogId, props);
            },

            invokeDialogMethod(method, dialogId=null, ...params) {
                if(_.isEmpty(this.dialogs)) {
                    console.warn("No dialog instances available.")
                    return;
                }
                let dialogComponent = _.isNil(dialogId) ? this.getLastOpened() : this.getDialog(dialogId);
                if (_.isNil(dialogComponent)) {
                    console.warn(`Unable to locate dialog component with dialogId, "${dialogId}".`);
                    return;
                }
                _.invoke(dialogComponent, method, ...params);
            },

            onDialogClosed(dialogId){
                _.delay(() => { this.removeDialog(dialogId); }, 500);
            }
        },
    }
</script>
