<template>
    <input
        :id="id"
        :automation_id="automationId"
        :placeholder="placeholder"
        :class="classAttr"
        type="text"
        v-model="boundValue"
        v-maska="{ mask: maskValue, tokens: customTokens }"
        @focus="onFocus"
        @blur="onBlur"
    />
</template>

<script>
    export default {
        name: "RqMaskedSsnInput",
        props: {
            id: { type: String, default: null },
            automation_id: { type: String, default: null },
            placeholder: { type: String, default: null },
            cssClass: { type: String, default: null },
            modelValue: { type: String, default: null },
            mask: { type: String, default: null },
            //deprecated
            alwaysRefresh: { type: Boolean, default: false },
        },
        data() {
            return {
                boundValue: null,
                inputValue: null,
                isFocused: false,
                customTokens: {
                    "#" : { pattern: /\d/ },
                    "*" : { pattern: /[*\d]/, transform: () => "*" },
                    C: { pattern: /[0-9a-zA-Z]/ },
                    X: { pattern: /[*0-9a-zA-Z]/, transform: () => "*" }
                }
            };
        },
        computed: {
            automationId() { return this.automation_id || this.id; },
            classAttr() {
                let result = {
                    "form-control": this.cssClass === "form-control" || !_.includes(this.cssClass, "form-control"),
                    "form-control-sm": this.size === "sm" && !_.includes(this.cssClass, "form-control-sm")
                };
                if(!_.isEmpty(this.cssClass)) result[this.cssClass] = true;
                return result;
            },
            maskValue() {
                return this.isFocused
                    ? _.replaceAll(_.replaceAll(this.mask, "*", "#"), "X", "C")
                    : _.isEmpty(this.modelValue) ? "************" : this.mask;
            }
        },
        watch: {
            modelValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.inputValue) return;
                this.inputValue = newValue;
            },
            boundValue(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.inputValue || !this.isFocused) return;
                this.inputValue = newValue;
            },
            inputValue(newValue, oldValue) {
                if(newValue === oldValue || _.includes(newValue, "*")) return;
                if(newValue !== this.modelValue && this.isFocused)
                    this.$emit("update:modelValue", newValue);
                if(newValue !== this.boundValue && !this.isFocused)
                    this.boundValue = newValue;
            }
        },

        methods: {
            focus() {
                _.invoke(this, "$el.focus");
            },
            onFocus() {
                this.isFocused = true;
                this.boundValue = this.inputValue;
            },
            onBlur() {
                this.isFocused = false;
            }
        }
    };
</script>
