<template>
    <div
        v-show="isVisible"
        :class="classAttr"
        :style="styleAttr">
        <rq-tooltip
            v-if="isActive"
            :target="componentId"
            triggers="hover"
            placement="left"
            v-model:show="tooltipVisible">Back To Top</rq-tooltip>
        <transition name="back-to-top" @after-leave="isVisible=false">
            <b-btn v-show="isActive"
                :id="componentId"
                variant="back-to-top"
                @click="onBackToTop">
                <FontAwesomeIcon icon="fas fa-arrow-up" />
            </b-btn>
        </transition>
    </div>
</template>

<script>
    export default {
        props:{
            target: { type: [HTMLElement, String], default: null },
            position: { type: String, default: "bottom" },
            offset: { type: [String,Number], default: 0 }
        },
        data() {
            return {
                componentId: _.uniqueId("btn-back-to-top-"),
                scrollY: 0,
                tooltipVisible: false,
                isActive: false,
                isVisible: false
            };
        },
        computed:{
            classAttr() { return `back-to-top-wrapper back-to-top-pos-${this.position}`; },
            styleAttr() { return _.parseNumber(this.offset, 0) === 0 ? "" : `transform:translateY(${this.offset}px);`; }
        },
        mounted(){
            this.init();
        },
        beforeUnmount() {
            let scrollElement = this.getScrollElement();
            if(_.isNil(scrollElement)) return;
            scrollElement.removeEventListener("scroll", this.onParentScroll);
        },
        methods: {
            init() {
                let scrollElement = this.getScrollElement();
                if(_.isNil(scrollElement)) {
                    console.warn("RqBackToTopButton -- Could not resolve target element.");
                    return;
                }
                scrollElement.addEventListener("scroll", this.onParentScroll);
            },

            onBackToTop(e) {
                this.tooltipVisible = false;
                let scrollElement = this.getScrollElement();
                if(_.isNil(scrollElement)) return;
                this.$nextTick(() => {
                    scrollElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                });
            },

            getScrollElement() {
                if(_.isNil(this.target)) return this.$el.parentElement;
                let element = _.isString(this.target) ? document.querySelector(this.target) : this.target;
                return element;
            },

            onParentScroll: _.debounce(function(e) {
                if(e.target.scrollTop === 0) {
                    this.isActive = false;
                }
                else {
                    this.isVisible = true;
                    this.$nextTick(() => { this.isActive = true; });
                }
            }, 100)
        }
    }
</script>