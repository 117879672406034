<template>
    <span :id="componentId" class="rq-report-button">
        <b-tooltip
            v-if="tooltipEnabled"
            :target="componentId"
            placement="top"
            triggers="hover"
            container="body"
            boundary="window"
            v-model:show="tooltipVisible">
            <span v-html="tooltipText"></span>
            <span v-if="reportAccessDenied()">(Access Restricted)</span>
        </b-tooltip>

        <div v-if="isDropdown"
            class="btn-group">
            <button
                :automation_id="automation_id || componentId"
                :class="{
                    'btn dropdown-toggle': true,
                    'btn-icon': isIcon,
                    'btn-link': isLink,
                    'btn-theme': !isIcon,
                    'dropdown-toggle-no-caret': isIcon && !showIconCaret,
                    'btn-sm': size === 'sm',
                    'btn-lg': size === 'lg'
                }"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :disabled="isDisabled">
                <FontAwesomeIcon v-if="hasIcon" :icon="icon" class="me-1" />
                <span v-if="hasText" :class="{ 'button-text':!isIcon, 'sr-only':isIcon }">{{text}}</span>
            </button>
            <ul :class="{
                'dropdown-menu': true,
                'dropdown-menu-end': right
            }">
                <li
                    v-for="item in reportItems"
                    :key="item.key">
                    <button
                        :id="item.id"
                        :automation_id="item.automation_id"
                        type="button"
                        class="dropdown-item"
                        :disabled="item.isDisabled"
                        @click="onButtonClick(item)">
                        <span>{{item.text}}</span>
                        <span v-if="reportAccessDenied(item)" class="text-muted font-italic">(Access Restricted)</span>
                    </button>
                </li>
            </ul>
        </div>

        <b-btn v-else
            :automation_id="automation_id || componentId"
            :size="size"
            :variant="variant"
            :class="{ 'btn-theme': isLink }"
            :disabled="isDisabled"
            @click="onButtonClick()">
            <slot name="button-content">
                <FontAwesomeIcon v-if="hasIcon" :icon="icon" class="me-1" />
                <span v-if="hasText" :class="{ 'button-text': !isIcon, 'sr-only': isIcon }">{{text}}</span>
            </slot>
        </b-btn>
    </span>
</template>

<script>
    class ReportButtonItem {
        constructor(obj) {
            this.id = _.uniqueId("btn-report-item-");
            this.automation_id = obj.automation_id || this.id;
            this.name = obj.name || null;
            this.path = obj.path || null;
            this.options = obj.options || null;
            this.text = obj.text || null;
            this.disabled = _.isNil(obj.disabled) ? false : obj.disabled;
        }
        get itemInfo() { return _.pick(this, ["name", "path", "text"]); }
        get isDisabled() {
            return _.isFunction(this.disabled)
                ? this.disabled(this.itemInfo)
                : _.parseBool(this.disabled);
        }
    }

    export default {
        name: "RqReportButton",
        props: {
            automation_id: { type: String, default: null },
            name: { type: String, default: null },
            path: { type: String, default: null },
            text: { type: String, default: null },
            icon: { type: String, default: null },
            size: { type: String, default: null },
            parameters: { type: Object, default: () => null },
            tooltip: { type: String, default: null },
            buttonType: { type: String, default: "theme" },
            reports: { type: Array, default: ()=>[] },
            disabled: { type: Boolean, default: false },
            showIconCaret: { type: Boolean, default: false },
            showViewer: { type: Boolean, default: true },
            reportOptions: { type: Object, default: () => ({}) },
            immediate: { type: Boolean, default: true },
        },

        data() {
            return {
                reportItems: [],
                tooltipVisible: false
            };
        },

        computed: {
            componentId() { return _.uniqueId("rq-report-button-"); },
            hasIcon() { return (/(fa)(s|l|r|d)\s(fa-)/).test(this.icon); },
            hasText() { return !_.isEmpty(this.text); },
            isDropdown() { return !_.isEmpty(this.reports); },
            isIcon() { return this.buttonType === "icon" && this.hasIcon; },
            isLink() { return this.buttonType === "link" },
            variant() { return this.isIcon ? "icon" : this.isLink ? "link" : "theme"; },
            isDisabled() {
                 return (!this.isDropdown && _.isEmpty(this.path) && _.isEmpty(this.reportOptions))
                    || (this.isDropdown && _.every(this.reportItems, this.getReportDisabled))
                    || this.getReportDisabled();
            },
            tooltipText() { return this.isIcon ? this.tooltip || this.text : this.tooltip; },
            tooltipEnabled() { return !_.isEmpty(this.tooltipText) || (this.isIcon && this.reportAccessDenied()); },
        },

        watch: {
            reports: {
                handler(items) {
                    this.reportItems = _.isEmpty(items) ? [] : _.map(items, item => new ReportButtonItem(item));
                },
                immediate: true
            }
        },

        methods: {
            onButtonClick(item=null) {
                this.tooltipVisible = false;

                let eventPayload = this.createEventPayload(item);
                this.$emit("click", eventPayload);

                if(!this.showViewer) return;

                _.invoke(this, "$rq.showReport", eventPayload.reportPath, eventPayload.reportOptions);
            },

            createEventPayload(item=null) {
                let reportOptions = this.getReportOptions(item);
                return {
                    reportName: reportOptions.reportName,
                    reportPath: reportOptions.reportPath,
                    reportOptions,
                    buttonText: _.isNil(item) ? this.text : item.text
                };
            },

            getReportDisabled(item=null) {
                return (_.isNil(item) ? this.disabled : item.disabled)
                    || this.reportAccessDenied(item);
            },

            //placeholder in case we need to handle any report viewing permission here
            reportAccessDenied(item=null) { return false; },

            getReportOptions(item=null) {
                let result = {
                    reportName: this.getOptionValue("name", item),
                    reportPath: this.getOptionValue("path", item),
                    parameters: this.getOptionValue("parameters", item, false),
                    immediate: this.getOptionValue("immediate", item, true),
                    preExecuteHook: this.getOptionValue("preExecuteHook", item, false),
                };
                if(!_.isNil(result.parameters) && _.isNil(result.immediate))
                    result.immediate = !this.showFilters;
                return result;
            },

            getOptionValue(optName, item=null, usePrefix=true) {
                if(_.isNil(item) && !_.isEmpty(this.reportItems)) return null;
                let prefixedOptName = usePrefix ? _.camelCase(`report-${optName}`) : optName;
                let result = _.isNil(item)
                    ? this[optName]
                    : _.isNil(item[optName])
                        ? _.get(item, `options.${prefixedOptName}`, null)
                        : item[optName];
                return _.isNil(result) ? _.get(this, `reportOptions.${prefixedOptName}`, null) : result;
            },
        }
    }
</script>
