<template>
    <li :class="classAttr">
        <div class="rq-tv-item-label" @click="onItemClick">
            <slot v-bind:childItem="item">{{item.displayName}}</slot>
        </div>
        <rq-tree-view-item-action
            :item="item"
            :item-status="itemStatus"
            :caret-icon="caretIcon"
            :caret-rotate="caretRotate"
            :caret-rotate-on="caretRotateOn"
            :error-tooltip-placement="errorTooltipPlacement"
            @action-click="onExpandToggle"
        />
        <b-collapse v-if="item.children.length > 0" :id="collapseId" :visible="item.isExpanded">
            <ul class="rq-tv-list">
                <rq-tree-view-item
                    v-for="listItem in item.children"
                    ref="childItems"
                    :key="listItem.key"
                    :item="listItem"
                    :data-source="dataSource"
                    :root-key="rootKey"
                    :expand-selected="expandSelected"
                    :caret-icon="caretIcon"
                    :caret-rotate="caretRotate"
                    :caret-rotate-on="caretRotateOn"
                    v-model:expanded-keys="expandedKeysValue"
                    #default="{ childItem }">
                    <slot :childItem="childItem"></slot>
                </rq-tree-view-item>
            </ul>
        </b-collapse>
    </li>
</template>

<script>
    import RqTreeViewItemAction from "./RqTreeViewItemAction.vue";
    import RqTreeViewMixin from "./RqTreeViewMixin";
    export default {
        name:"RqTreeViewItem",
        mixins: [RqTreeViewMixin],
        components: { RqTreeViewItemAction },
        props: {
            rootKey: { type: String, required: true },
            item: { type: Object, default: () => ({}) },
        },
        data() {
            return {
                collapseId: _.uniqueId("rq-tree-view-collapse-"),
                itemStatus: null
            };
        },
        computed: {
            eventNames() {
                const self = this;
                return {
                    select: `${self.rootKey}::select`,
                    selected: `${self.rootKey}::selected`,
                    clearSelection: `${self.rootKey}::clear-selection`,
                    collapse: `${self.rootKey}::collapse`,
                    expand: `${self.rootKey}::expand`,
                };
            },
            classAttr() {
                return {
                    "rq-tv-list-item": true,
                    "rq-tv-item-selected": this.item.isSelected
                };
            },
            isLoading: {
                get() { return this.itemStatus === "loading"; },
                set(val) { this.itemStatus = val ? "loading" : null; }
            }
        },
        created() {
            this.$events.on(this.eventNames.select, this.onSelectAction);
            this.$events.on(this.eventNames.selected, this.onSelectedEvent);
            this.$events.on(this.eventNames.clearSelection, this.onClearSelection);
            this.$events.on(this.eventNames.collapse, this.onCollapseAction);
            this.$events.on(this.eventNames.expand, this.onExpandAction);
        },
        beforeUnmount() {
            this.$events.off(this.eventNames.select);
            this.$events.off(this.eventNames.selected);
            this.$events.off(this.eventNames.clearSelection);
            this.$events.off(this.eventNames.collapse);
            this.$events.off(this.eventNames.expand);
        },
        methods: {
            loadChildren(expandOnLoad=true) {
                const self = this;
                self.isLoading = true;
                self.loadFromDataSource(self.item)
                    .then(items => {
                        self.item.children = items;
                        self.isLoading = false;
                        if(!expandOnLoad) return;
                        self.$nextTick().then(() => {
                            self.setIsExpanded(true);
                        });
                    })
                    .catch(err => {
                        self.itemStatus = "Error while loading children";
                        self.notify("An issue occurred loading the selected node children.", err);
                    });
            },

            collapse() {
                this.setIsExpanded(false);
            },

            expand() {
                this.setIsExpanded(true);
            },

            setIsExpanded(val) {
                const self = this;
                if(val && !self.item.isExpanded && self.item.hasChildren && _.isEmpty(self.item.children))
                    self.loadChildren();
                else {
                    self.item.isExpanded = val;
                    self.updateExpandedKeys(val);
                }
            },

            setIsExpandedByKey(val, key) {
                if(this.item.key !== key && key !== "all") return;
                this.setIsExpanded(val);
            },

            setSelected() {
                if(this.item.selectable) {
                    this.item.isSelected = true;
                    this.$events.emit(this.eventNames.selected, this.item);
                }
                if (this.expandSelected && (this.item.hasChildren || !_.isEmpty(this.item.children)))
                    this.setIsExpanded(true);
            },

            updateExpandedKeys(expanded) {
                let keys = this.expandedKeysValue.slice();
                let key = this.item.key;

                if(_.includes(keys, key) === expanded) return;

                if(expanded)
                    keys.push(key);
                else if(keys.length > 1)
                    _.pull(keys, key);
                else
                    keys = [];

                this.expandedKeysValue = _.uniq(keys);
            },

            onItemClick() {
                this.setSelected();
            },

            onExpandToggle(e) {
                const self = this;
                if(self.isLoading) return;
                self.setIsExpanded(!self.item.isExpanded);
            },

            onExpandedKeysUpdate(keys) {
                this.setIsExpanded(_.includes(keys, this.item.key));
            },

            onSelectedEvent(selectedItem) {
                this.item.isSelected = this.item.key === selectedItem.key;
            },

            onSelectAction(key) {
                if(this.item.key !== key) return;
                this.setSelected();
            },

            onClearSelection() {
                this.item.isSelected = false;
            },

            onExpandAction(key) {
                this.setIsExpandedByKey(true, key);
            },

            onCollapseAction(key) {
                this.setIsExpandedByKey(false, key);
            }
        }
    };
</script>