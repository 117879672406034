<template>
    <div :class="classAttr">
        <div class="rq-wizard-progress">

            <slot name="progress-start"></slot>

            <rq-step-progress
                :steps="visibleSteps"
                :step-index="currentIndex"
                @item-click="onItemClick"
            />

            <slot name="progress-end"></slot>

        </div>
        <div class="rq-wizard-view">

            <div class="navbar rq-wizard-view-header">
                <div
                    v-if="!onFirstStep && !allStepsComplete"
                    class="nav navbar-nav">
                    <div class="nav-item">
                        <b-btn
                            variant="link"
                            class="btn-prev"
                            @click="onPreviousClick">
                            <FontAwesomeIcon icon="fas fa-angle-left" />
                            <span>Back to Step {{currentIndex}}</span>
                        </b-btn>
                    </div>
                </div>

                <slot name="header" :step-info="currentStep"></slot>

            </div>

            <div v-if="allStepsComplete"
                class="rq-wizard-view-body">
                <slot name="complete"></slot>
            </div>

            <div v-else
                class="rq-wizard-view-body">
                <slot :step-info="currentStep"></slot>
            </div>

            <div class="navbar rq-wizard-view-footer">

                <slot name="footer" :step-info="currentStep"></slot>

                <div
                    v-if="!allStepsComplete"
                    class="nav navbar-nav ms-auto">
                    <div class="nav-item">
                        <b-btn
                            v-if="onLastStep"
                            variant="purple"
                            class="btn-finish"
                            @click="onFinishClick">
                            <span>{{finishButtonLabel}}</span>
                        </b-btn>
                        <b-btn
                            v-else
                            variant="purple"
                            class="btn-next"
                            @click="onNextClick">
                            <span>{{nextButtonLabel}}</span>
                            <FontAwesomeIcon icon="fas fa-caret-right" class="ms-2" />
                        </b-btn>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import { STEP_STATUS, RqStepModel } from "../models";

    const isStatusComplete = s => s.status === STEP_STATUS.Complete;
    const isStatusError = s => s.status === STEP_STATUS.Error;

    export default {
        name: "RqWizardLayout",
        props: {
            steps: { type: Array, default: () => [] },
            modelValue: { type: Number, default: 0 },
            nextButtonLabel: { type: String, default: "Next" },
            finishButtonLabel: { type: String, default: "Finish" },
            autoCompleteSteps: { type: Boolean, default: false },
            autoAdvanceSteps: { type: Boolean, default: false }
        },
        data () {
            return {
                visibleSteps: []
            };
        },
        computed: {
            currentIndex: {
                get() { return this.modelValue; },
                set(val) { this.$emit("update:modelValue", val); }
            },
            currentStep() { return this.currentIndex >= 0 && this.currentIndex <= this.lastIndex ? this.visibleSteps[this.currentIndex] : {}; },
            lastIndex() { return this.visibleSteps.length - 1; },
            maxCompleteIndex() { return _.findLastIndex(this.visibleSteps, isStatusComplete); },
            onFirstStep() { return this.currentIndex === 0; },
            onLastStep() { return this.currentIndex === this.lastIndex; },
            allStepsComplete() { return _.every(this.visibleSteps, isStatusComplete); },
            hasErrorStatus() { return _.some(this.visibleSteps, isStatusError)},
            classAttr() {
                return {
                    "rq-wizard rq-wizard-layout": true
                };
            }
        },
        watch: {
            currentIndex(newVal, oldVal) {
                if(!this.autoCompleteSteps || newVal <= oldVal || this.visibleSteps[oldVal].status !== STEP_STATUS.Incomplete) return;
                this.setComplete(oldVal);
            }
        },
        created() {
            this.loadSteps();
        },
        methods: {
            loadSteps() {
                this.visibleSteps = _.filter(_.map(this.steps, (s,i) => new RqStepModel(s, i)), s => s.isVisible);
            },
            setStatus(status, index=null) {
                let targetIndex = _.isNil(index) ? this.currentIndex : index;
                let targetStep = this.visibleSteps[targetIndex];
                targetStep.status = status;
                if(status !== STEP_STATUS.Error) targetStep.errorMessage = "";
                if(this.allStepsComplete) this.currentIndex = -1;
                if(!this.autoAdvanceSteps || this.currentIndex !== targetIndex || status !== STEP_STATUS.Complete) return;
                this.currentIndex++;
            },
            setComplete(index=null) {
                let targetIndex = _.isNil(index) ? this.currentIndex : index;
                this.setStatus(STEP_STATUS.Complete, targetIndex);
            },
            setError(msg, index=null) {
                let errIndex = _.parseNumber(index, this.currentIndex);
                this.visibleSteps[errIndex].status = STEP_STATUS.Error;
                this.visibleSteps[errIndex].errorMessage = msg;
            },
            setLastStatus(status) {
                this.setStatus(status, this.lastIndex);
            },
            setLastComplete() {
                this.setLastStatus(STEP_STATUS.Complete);
            },
            reset() {
                this.loadSteps();
                this.currentIndex = 0;
            },
            onPreviousClick(e) {
                if(this.hasErrorStatus || this.currentIndex === 0) return;
                this.emitEvent("previous");
            },
            onNextClick(e) {
                if(this.hasErrorStatus) return;
                this.emitEvent("next");
            },
            onFinishClick(e) {
                if(this.hasErrorStatus || this.currentIndex < this.lastIndex) return;
                this.emitEvent("finish");
            },
            onItemClick(e) {
                if(this.hasErrorStatus) return;
                this.emitEvent("direct", e);
            },
            emitEvent(name, data=null) {
                const self = this;
                const setComplete = () => self.setComplete();
                const setError = msg => self.setError(msg);
                let payload = data || {
                    index: this.currentIndex,
                    step: this.currentStep,
                    isFirst: this.onFirstStep,
                    isLast: this.onLastStep,
                    setComplete,
                    setError
                };
                this.$emit(name, payload);
            }
        }
    }
</script>