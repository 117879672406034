<template>
    <section class="rq-filter-section">
        <div class="row">
            <div class="col-auto me-3">
                <div class="title">Display</div>
                <button type="button" class="btn btn-link btn-theme" :disabled="!filterCollection.hasSelected" @click="onClearFilterSelection">Deselect All</button>
            </div>
            <div :class="filterColClassAttr">
                <div class="row">
                    <!-- <div v-for="item in filterCollection.items" :key="item.id" :class="{ 'col-1': item.name.length <= 20, 'col-2': item.name.length > 20 }"> -->
                    <template v-for="(item, index) in filterCollection.items" :key="item.id">
                        <div :class="chkColClassAttr">
                            <b-form-checkbox
                                :id="`chk_filter_${item.id}`"
                                :automation_id="`chk_${item.automationId}`"
                                class="chk-nowrap"
                                :disabled="item.disabled"
                                v-model="item.selected"
                                @change="onFilterChange">{{item.name}}</b-form-checkbox>
                        </div>
                        <div v-if="breakRow(index)" class="w-100" :key="`w-100-${item.id}`"></div>
                    </template>
                    <div v-if="inverseItemEnabled" class="col p-0">
                        <b-form-checkbox  id="chk_filter_inverse"
                            automation_id="chk_filter_inverse"
                            class="chk-nowrap"
                            :disabled="inverseFilterItem.disabled"
                            v-model="inverseFilterItem.selected"
                            @change="onInverseFilterChange">{{inverseFilterItem.name}}</b-form-checkbox>
                    </div>
                    <div v-if="appendEmptyCol" class="col p-0">&nbsp;</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    import { FilterItem, FilterCollection } from "../models";

    export default {
        props:{
            items: { default: () => [] },
            propName: { default: "" },
            fixedColumns: { type:Boolean, default: false },
            rowCount: { type:Number, default: 2 },
            chkColClass: { type:String, default: null },
            filterColClass: { type:String, default: null },
            inverseOptionEnabled: { type:Boolean, default: false },
            inverseOption: { type:Object, default: null }
        },
        data() {
            return {
                inverseFilterItem: null,
                inverseItemEnabled: false,
                filterCollection: new FilterCollection()
            };
        },
        computed:{
            filterColClassAttr() { return this.filterColClass ? this.filterColClass : "col"; },
            chkColClassAttr() {
                if(this.chkColClass) return this.chkColClass;
                return this.fixedColumns ? "col p-0" : "col-auto"
            },
            appendEmptyCol() {
                let modResult = (this.filterCollection.items.length + (this.inverseItemEnabled ? 1 : 0)) % 2;
                return modResult > 0;
            }
        },
        watch: {
            items: {
                handler() { this.initFilterCollection(); },
                immediate: true,
                deep: true
            },
            inverseOptionEnabled() { this.initInverseFilterItem(); },
            inverseOption() { this.initInverseFilterItem(); },
            propName(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.filterCollection.propName = newValue;
            }
        },
        methods: {
            initFilterCollection() {
                this.filterCollection = new FilterCollection(this.items, this.propName);

                this.filterCollection.items = _.map(this.filterCollection.items, (item) => {
                    item.automationId = item.name.replace(' ', '_').toLowerCase();
                    return item;
                });

                this.initInverseFilterItem();
            },

            initInverseFilterItem() {
                if(_.isNil(this.inverseOption) && !this.inverseOptionEnabled) {
                    this.inverseFilterItem = null;
                    this.inverseItemEnabled = false;
                    return;
                }

                const DEFAULT_INVERSE_CFG = { id:-999, name: "Other",  };
                let propInverseCfg = this.inverseOption || {};

                this.inverseItemEnabled = true;
                this.inverseFilterItem = new FilterItem(_.assign({}, DEFAULT_INVERSE_CFG, propInverseCfg));
            },

            breakRow(index) { return this.fixedColumns && (index + 1 === Math.round(((this.filterCollection.items.length + (this.inverseItemEnabled ? 1 : 0)) / this.rowCount))); },

            onInverseFilterChange(e) {
                this.$nextTick().then(() => {
                    if (this.inverseFilterItem.selected) {
                        this.filterCollection.clearSelected();
                        this.emitChange({
                            selectedFilters: [_.clone(this.inverseFilterItem)],
                            dxFilter: this.filterCollection.getInverseDXFilter(),
                            inverse: true
                        });
                    }
                    else {
                        this.$emit("update:modelValue", []);
                        this.$emit("clear");
                    }
                });
            },

            onFilterChange(e) {
                this.$nextTick().then(() => {
                    if (this.filterCollection.hasSelected) {
                        this.inverseFilterItem.selected = false;
                        this.emitChange({
                            selectedFilters: this.filterCollection.selectedFilters,
                            dxFilter: this.filterCollection.dxFilter,
                            inverse: false
                        });
                    }
                    else {
                        this.clearSelection();
                    }
                });
            },

            onClearFilterSelection() {
                this.clearSelection();
            },

            clearSelection() {
                this.filterCollection.clearSelected();
                this.$emit("update:modelValue", []);
                this.$emit("clear");
            },

            emitChange({ selectedFilters, dxFilter, inverse }) {
                this.$emit("update:modelValue", _.map(selectedFilters, f => _.parseNumber(f.id)));
                this.$emit("change", { selectedFilters, dxFilter, inverse });
            }
        }
    }
</script>
